import { ReactNode, createContext, useContext } from "react";

const fallbackLocale = window.navigator.language;

const LocaleContext = createContext<string>(fallbackLocale);

export const useLocale = () => {
	return useContext(LocaleContext);
};

interface LocaleProviderProps {
	locale?: string;
	children: ReactNode;
}

export const LocaleProvider = ({ locale, children }: LocaleProviderProps) => {
	const fallback = useLocale();

	return <LocaleContext.Provider value={locale || fallback}>{children}</LocaleContext.Provider>;
};

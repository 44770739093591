import { Tag as AntdTag, TagProps as AntdTagsProps, Col, Progress, Row, Typography } from "antd";
import styled, { css } from "styled-components";

import { SignatoryResponse } from "@teylor-tools/Api";

import { useTranslations } from "../translations/translations";

const { Text } = Typography;

interface TagProps extends AntdTagsProps {
	$isPrimary: boolean;
}

const Tag = styled(AntdTag)<TagProps>`
	border-radius: 2px;
	text-transform: uppercase;
	border: none;
	text-align: center;
	${({ $isPrimary, theme }) =>
		$isPrimary &&
		css`
			color: ${theme.color_cta_text};
			background-color: ${theme.color_primary};
		`}
`;

const PercentageInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.ant-progress-circle-path {
		stroke: ${({ theme }) => theme.color_primary} !important;
	}
`;

const ContentWrapper = styled.div`
	display: grid;
	justify-content: start;
	gap: 6px;
`;

interface RepresentativeOwnershipDetailsProps {
	representative: SignatoryResponse;
}

const RepresentativeOwnershipDetails = ({
	representative,
	...props
}: RepresentativeOwnershipDetailsProps) => {
	const t = useTranslations();

	const { is_beneficial_owner, is_sole_signatory, is_contract_signatory, is_legal_signatory } =
		representative;

	return (
		<Row gutter={16} align="middle" {...props}>
			<Col flex="200px">
				<ContentWrapper>
					<Text type="secondary">{t.representativeCard.beneficialOwner}</Text>
					<Text type="secondary">{t.representativeCard.legalSignatory}</Text>
					<Text type="secondary">{t.representativeCard.willingToSign}</Text>
					<Text type="secondary">{t.representativeCard.canSignAlone}</Text>
				</ContentWrapper>
			</Col>
			<Col flex="1">
				<Row gutter={[4, 4]}>
					<Col>
						<ContentWrapper>
							<Tag $isPrimary={is_beneficial_owner}>{is_beneficial_owner ? t.yes : t.no}</Tag>
							<Tag $isPrimary={is_legal_signatory}>{is_legal_signatory ? t.yes : t.no}</Tag>
							<Tag $isPrimary={is_contract_signatory}>{is_contract_signatory ? t.yes : t.no}</Tag>
							<Tag $isPrimary={is_sole_signatory}>{is_sole_signatory ? t.yes : t.no}</Tag>
						</ContentWrapper>
					</Col>
					<Col flex="1">
						<PercentageInfo>
							<Progress
								type="dashboard"
								percent={Number.parseFloat(representative.percentage_owned)}
								width={65}
								format={(percent) => (percent !== undefined ? `${percent}%` : "N/A")}
								success={{ percent: 0 }}
							/>
							<div>
								<Text type="secondary">{t.representativeCard.ownership}</Text>
							</div>
						</PercentageInfo>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default RepresentativeOwnershipDetails;

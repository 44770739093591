import { FC } from "react";

import { Form, Input, InputProps } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";

import { useTranslations } from "../../../translations/translations";

interface TelephoneFormInputProps extends FormItemProps {
	inputProps?: InputProps;
}

const TelephoneFormItem: FC<TelephoneFormInputProps> = ({
	inputProps,
	...props
}: TelephoneFormInputProps) => {
	const t = useTranslations();

	const phoneNumberFiledRule = {
		pattern: /^\+[\d\s()-]{8,}$/,
		message: t.formErrors.invalidPhoneNumber,
	};

	return (
		<Form.Item {...props} rules={[phoneNumberFiledRule, ...(props.rules || [])]}>
			<Input type="tel" maxLength={15} {...inputProps} data-cy="telephone-number" />
		</Form.Item>
	);
};

export default TelephoneFormItem;

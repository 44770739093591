import { FC } from "react";

import {
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Typography,
} from "antd";
import { Dayjs } from "dayjs";
import { NamePath } from "rc-field-form/es/interface";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";

import {
	FinanceObjectGetResponse,
	ObjectFinancingType,
	ObjectRentalState,
} from "@teylor-tools/Api";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";
import { CountriesList } from "@teylor-tools/utils/getCountries";

import CityFormItem from "../form/form-items/city-form-item/CityFormItem";
import CountrySelectFormItem from "../form/form-items/country-select-form-item/CountrySelectFormItem";
import HouseNumberFormItem from "../form/form-items/house-number-form-item/HouseNumberFormItem";
import PostcodeFormItem from "../form/form-items/postcode-form-item/PostcodeFormItem";
import CurrencyInput from "../form/inputs/currency-input/CurrencyInput";
import { useTranslations } from "../translations/translations";

const { TextArea } = Input;
const { Option } = Select;

export const MAX_NUMBER_OF_OBJECTS: number = 6;

type ObjectFieldName = keyof FinanceObjectGetResponse;

interface Props {
	index?: number;
	countries: CountriesList[];
}

export interface FinancingObjectForm
	extends Omit<FinanceObjectGetResponse, "built_at" | "expected_delivery_date"> {
	built_at?: number;
	expected_delivery_date?: Dayjs;
}

const ObjectForm: FC<Props> = ({ index, countries }: Props) => {
	const t = useTranslations();

	const getFieldName = (fieldName: ObjectFieldName): NamePath =>
		index !== undefined ? [index, fieldName] : fieldName;

	const requiredRule = {
		required: true,
		message: t.object.errors.required,
	};

	const validateNumberBetween = (
		rule: RuleObject,
		value: StoreValue,
		min: number,
		max: number,
		error: string
	): Promise<void | string> => {
		if (!value && value !== 0) {
			return Promise.resolve();
		}

		if (value < min || value > max) {
			return Promise.reject(error);
		}

		return Promise.resolve();
	};

	const validateYearBuilt = (rule: RuleObject, value: StoreValue): Promise<void | string> => {
		const minYear = 1900;
		const maxYear = 2050;
		const errorMsg = `${t.formErrors.fieldMustBeBetweenMinMax(minYear, maxYear)}`;

		return validateNumberBetween(rule, value, minYear, maxYear, errorMsg);
	};

	const validateObjectsCount = (rule: RuleObject, value: StoreValue): Promise<void | string> => {
		const min = 1;
		const max = 20;

		return validateNumberBetween(
			rule,
			value,
			min,
			max,
			t.formErrors.fieldMustBeBetweenMinMax(min, max)
		);
	};

	return (
		<>
			<Form.Item
				name={getFieldName("manufacturer")}
				label={t.object.manufacturer}
				style={{ marginTop: 24 }}
				rules={[requiredRule]}
			>
				<Input placeholder={t.object.placeholders.manufacturer} />
			</Form.Item>
			<Row gutter={24}>
				<Col span={12}>
					<Form.Item name={getFieldName("type")} label={t.object.type} rules={[requiredRule]}>
						<Input placeholder={t.object.placeholders.type} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={getFieldName("built_at")}
						label={t.object.yearBuilt}
						rules={[
							{
								validator: validateYearBuilt,
							},
						]}
					>
						<InputNumber
							controls={false}
							placeholder={t.object.placeholders.yearBuilt}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item name={getFieldName("detail_desc")} label={t.object.objectDescription}>
				<TextArea
					placeholder={t.object.placeholders.objectDescription}
					style={{ minHeight: 100 }}
				/>
			</Form.Item>

			<Row gutter={24}>
				<Col span={12}>
					<Form.Item
						name={getFieldName("net_purchase_price")}
						label={t.object.netPurchasePrice}
						rules={[requiredRule]}
					>
						<CurrencyInput style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={getFieldName("down_payment")}
						label={t.object.downPayment}
						rules={[requiredRule]}
					>
						<CurrencyInput style={{ width: "100%" }} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={12}>
					<Form.Item
						name={getFieldName("remaining_value")}
						label={t.object.remainingValue}
						rules={[requiredRule]}
					>
						<CurrencyInput style={{ width: "100%" }} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={getFieldName("object_count")}
						label={t.object.numberOfObjects}
						rules={[
							requiredRule,
							{
								validator: validateObjectsCount,
							},
						]}
					>
						<InputNumber controls={false} style={{ width: "100%" }} prefix="#" />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				name={getFieldName("financing_type")}
				label={t.object.financingType}
				rules={[requiredRule]}
			>
				<Select placeholder={t.select}>
					{Object.values(ObjectFinancingType).map((key) => (
						<Option key={key} value={key}>
							{t.object.options.financingTypes[key]}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				name={getFieldName("rental_state")}
				label={t.object.rentalState}
				rules={[requiredRule]}
			>
				<Select placeholder={t.select}>
					{Object.values(ObjectRentalState).map((key) => (
						<Option key={key} value={key}>
							{t.object.options.rentalStates[key]}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Row>
				<Col span={12}>
					<Form.Item
						name={getFieldName("is_fixed_interest")}
						label={t.object.isFixedInterest}
						rules={[requiredRule]}
					>
						<Radio.Group
							options={[
								{ label: t.object.options.isFixedInterest.yes, value: true },
								{ label: t.object.options.isFixedInterest.no, value: false },
							]}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={getFieldName("expected_delivery_date")}
						label={t.object.expectedDeliveryDate}
					>
						<DatePicker
							style={{ width: "100%" }}
							placeholder={dayMonthYear}
							format={dayMonthYear}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Divider orientation="left">
				<Typography.Text type="secondary" style={{ fontSize: 14 }}>
					{t.object.sections.retailer}
				</Typography.Text>
			</Divider>
			<Form.Item
				name={getFieldName("merchant_name")}
				label={t.object.companyName}
				rules={[requiredRule]}
			>
				<Input placeholder={t.object.placeholders.objectCompanyName} />
			</Form.Item>
			<Row gutter={24}>
				<Col span={16}>
					<Form.Item
						name={getFieldName("merchant_street")}
						label={t.object.street}
						rules={[requiredRule]}
					>
						<Input placeholder={t.object.placeholders.street} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<HouseNumberFormItem
						name={getFieldName("merchant_house_num")}
						rules={[requiredRule]}
						inputPlaceholder={t.object.placeholders.houseNumber}
					/>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={16}>
					<CityFormItem
						name={getFieldName("merchant_city")}
						label={t.object.city}
						required
						inputProps={{ placeholder: t.object.placeholders.city }}
					/>
				</Col>
				<Col span={8}>
					<PostcodeFormItem name={getFieldName("merchant_postcode")} required />
				</Col>
			</Row>
			<CountrySelectFormItem
				name={getFieldName("merchant_country")}
				label={t.object.country}
				rules={[requiredRule]}
				countries={countries}
				select={{ placeholder: t.select }}
			/>

			<Form.Item
				name={getFieldName("merchant_company_id")}
				label={t.object.retailerCompanyId}
				rules={[requiredRule]}
			>
				<Input placeholder={t.object.placeholders.retailerCompanyId} />
			</Form.Item>
		</>
	);
};

export default ObjectForm;

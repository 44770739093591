import { FC } from "react";

import { Form, FormItemProps, Input, InputProps } from "antd";

import { useTranslations } from "../../../translations/translations";

// https://emailregex.com/
const EMAIL_REGEX =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line no-control-regex

interface Props extends FormItemProps {
	inputProps?: InputProps;
}

const EmailFormItem: FC<Props> = ({ inputProps = {}, ...props }: Props) => {
	const t = useTranslations();

	return (
		<Form.Item
			name="email"
			label={t.emailInputLabel}
			colon={false}
			labelAlign="left"
			{...props}
			validateFirst
			rules={[
				{
					type: "string",
					pattern: EMAIL_REGEX,
					message: t.emailInputErrorMessage,
				},
				...(props.rules || []),
			]}
		>
			<Input
				type="email"
				maxLength={250}
				placeholder={t.emailInputPlaceholder}
				{...inputProps}
				data-cy="email-address"
			/>
		</Form.Item>
	);
};

export default EmailFormItem;

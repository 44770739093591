import { Card, Col, ColProps, Divider, Row, Typography } from "antd";
import styled from "styled-components";

const DataCard = styled(Card)`
	margin-bottom: 24px;
	min-width: 420px;
	max-width: 720px;
`;

const DataCardDivider = styled(Divider)`
	margin: 24px 0;
	left: -24px;
	position: relative;
	width: calc(100% + 48px);
`;

const CardRow = styled(Row)`
	min-height: 32px;
	margin-bottom: 4px;
	align-items: flex-start;
	display: flex;
`;

interface DataCardItemProps extends ColProps {
	label: string;
	value: string | number | JSX.Element | undefined;
	alignItems?: "start" | "center" | "end";
	copyable?: boolean;
}

const DataCardItem = ({
	label,
	value,
	alignItems = "start",
	copyable = false,
	...props
}: DataCardItemProps) => {
	const isValueValid = !!(value || value === 0);

	return (
		<CardRow wrap={false} style={{ alignItems }} gutter={16} {...props}>
			<Col flex="200px">
				<Typography.Text type="secondary">{label}</Typography.Text>
			</Col>
			<Col flex={1}>
				<Typography.Text copyable={isValueValid && copyable}>
					{isValueValid ? value : "-"}
				</Typography.Text>
			</Col>
		</CardRow>
	);
};

export { CardRow, DataCard, DataCardDivider, DataCardItem };

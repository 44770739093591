import { DeleteOutlined, EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Space, Tooltip } from "antd";
import Text from "antd/es/typography/Text";
import dayjs from "dayjs";
import styled from "styled-components";

import { CountryCode, Currency, FinanceObjectGetResponse } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { dayMonthYear } from "@teylor-tools/utils/dateFormats";

import { useTranslations } from "../translations/translations";

const VerticalDataRow = styled.div`
	margin: 4px 0;
`;

const HorizontalDataRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 4px 0;
`;

interface Props {
	object: FinanceObjectGetResponse;
	index: number;
	isEditable: boolean;
	onEdit: (obj: FinanceObjectGetResponse) => void;
	onDelete: (obj: FinanceObjectGetResponse) => void;
	currency: Currency;
	handleCreateOrderForm?: (object: FinanceObjectGetResponse) => void;
}

const ObjectCard = ({
	object,
	index,
	isEditable,
	onEdit,
	onDelete,
	currency,
	handleCreateOrderForm,
}: Props) => {
	const t = useTranslations();
	const { currency: formatCurrency } = useFormatter();

	return (
		<Card
			title={`${t.object.object} ${index + 1}`}
			extra={
				isEditable && (
					<Space>
						{handleCreateOrderForm && (
							<Tooltip title={t.object.createOrderForm}>
								<Button
									type="link"
									icon={<FileAddOutlined />}
									onClick={() => handleCreateOrderForm(object)}
								/>
							</Tooltip>
						)}
						<Tooltip title={t.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => onEdit(object)}
								data-cy="edit-object-button"
							/>
						</Tooltip>
						<Tooltip title={t.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => onDelete(object)}
								data-cy="delete-object-button"
							/>
						</Tooltip>
					</Space>
				)
			}
		>
			<VerticalDataRow>
				<Text type="secondary">{t.object.manufacturer}:</Text>
				<br />
				<Text>{object.manufacturer}</Text>
			</VerticalDataRow>
			<VerticalDataRow>
				<Text type="secondary">{t.object.type}:</Text>
				<br />
				<Text>{object.type}</Text>
			</VerticalDataRow>
			<VerticalDataRow>
				<Text type="secondary">{t.object.yearBuilt}:</Text>
				<br />
				<Text>{object.built_at ? dayjs(object.built_at).get("year") : "-"}</Text>
			</VerticalDataRow>

			<Divider />

			<Text type={"secondary"}>{t.object.objectDescription}:</Text>
			<br />
			<Text>{object.detail_desc}</Text>

			<Divider />

			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.netPurchasePrice}:</Text>
				<Text>{formatCurrency(object.net_purchase_price || "-", { currency })}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.downPayment}:</Text>
				<Text>{formatCurrency(object.down_payment || "-", { currency })}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.remainingValue}:</Text>
				<Text>{formatCurrency(object.remaining_value || "-", { currency })}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.numberOfObjects}:</Text>
				<Text>{object.object_count}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.financingType}:</Text>
				<Text>{t.object.options.financingTypes[object.financing_type]}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.rentalState}:</Text>
				<Text>{t.object.options.rentalStates[object.rental_state]}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.isFixedInterest}:</Text>
				<Text>{t.object.options.isFixedInterest[object.is_fixed_interest ? "yes" : "no"]}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.expectedDeliveryDate}:</Text>
				<Text>
					{object.expected_delivery_date
						? dayjs(object.expected_delivery_date).format(dayMonthYear)
						: "-"}
				</Text>
			</HorizontalDataRow>

			<Divider />

			<Text strong>{t.object.sections.retailer}</Text>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.retailerName}:</Text>
				<Text>{object.merchant_name}</Text>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.retailerAddress}:</Text>
				<div style={{ textAlign: "right" }}>
					<Text>{`${object.merchant_street}, ${object.merchant_house_num}`}</Text>
					<br />
					<Text>{`${object.merchant_postcode}, ${object.merchant_city}`}</Text>
					<br />
					<Text>{t.countries[object.merchant_country as CountryCode]}</Text>
				</div>
			</HorizontalDataRow>
			<HorizontalDataRow>
				<Text type={"secondary"}>{t.object.retailerCompanyId}:</Text>
				<Text>{object.merchant_company_id}</Text>
			</HorizontalDataRow>
		</Card>
	);
};

export default ObjectCard;

import { Form, Input, InputProps } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";

import { useTranslations } from "../../../translations/translations";

interface Props extends FormItemProps {
	inputProps?: InputProps;
	inputPlaceholder?: string;
}

const HouseNumberFormItem = ({ inputProps, inputPlaceholder, ...props }: Props) => {
	const t = useTranslations();
	const placeholder = inputPlaceholder ? inputPlaceholder : t.houseNumberPlaceholder;

	return (
		<Form.Item
			label={t.houseNumber}
			{...props}
			rules={[
				{
					type: "string",
					max: 10,
					pattern: /^[A-Z\d.\-\/\\]*$/i,
					message: t.formErrors.fieldInvalid,
				},
				...(props.required
					? [
							{
								required: true,
								message: t.formErrors.fieldRequired,
							},
					  ]
					: []),
				...(props.rules || []),
			]}
			validateFirst
		>
			<Input maxLength={10} placeholder={placeholder} {...inputProps} />
		</Form.Item>
	);
};

export default HouseNumberFormItem;

import { Currency } from "../Api";
import { useLocale } from "../utils/LocaleProvider";

export enum CurrencyDisplay {
	SYMBOL = "symbol",
}

interface CurrencyConfig {
	showFractions?: boolean;
	currencyDisplay?: CurrencyDisplay;
	currency?: Currency;
}

const DEFAULT_DECIMAL_PLACES = 2;
const DECIMAL_SEPARATOR = ".";

type DecimalToPercentFormatter = (
	percent: string | number,
	decimalPlaces?: number,
	showPercentSymbol?: boolean,
	noLocalisation?: boolean
) => string;

type PercentToDecimalFormatter = (decimal: string | number) => string;

type LocalizedNumberFormatter = ({
	value,
	decimalPlaces,
}: {
	value: string | number;
	decimalPlaces?: number;
}) => string;

type CurrencyFormatter = (number: number | string, config?: CurrencyConfig) => string;
type DateFormatter = (date: string, options?: Intl.DateTimeFormatOptions) => string;

export const useFormatter = (): {
	decimalToPercent: DecimalToPercentFormatter;
	percentToDecimal: PercentToDecimalFormatter;
	localizedNumber: LocalizedNumberFormatter;
	currency: CurrencyFormatter;
	date: DateFormatter;
} => {
	const locale = useLocale();
	return {
		decimalToPercent: (
			percent,
			decimalPlaces = DEFAULT_DECIMAL_PLACES,
			showPercentSymbol = false,
			noLocalisation = false
		) => {
			const decimalNumber = typeof percent === "number" ? percent : Number(percent);

			if (noLocalisation) {
				return (decimalNumber * 100).toFixed(decimalPlaces);
			}

			return `${(decimalNumber * 100).toLocaleString(locale, {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			})}${showPercentSymbol ? "%" : ""}`;
		},
		localizedNumber: ({ value, decimalPlaces = DEFAULT_DECIMAL_PLACES }) => {
			const valueNumber = typeof value === "number" ? value : Number(value);
			return `${valueNumber.toLocaleString(locale, {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			})}`;
		},
		percentToDecimal: (decimal) => {
			const valString = decimal.toString();
			const dotIndex = valString.indexOf(DECIMAL_SEPARATOR);

			// add 2 to decimal place to compensate division by 100
			const decimalPlaces = valString.slice(dotIndex + 1).length + 2;

			const decimalNumber = typeof decimal === "number" ? decimal : Number(decimal);
			return (Number(decimalNumber) / 100).toFixed(decimalPlaces);
		},
		currency: (number, config) => {
			const showFractions = config?.showFractions !== undefined ? config.showFractions : true;

			return new Intl.NumberFormat(locale, {
				style: "currency",
				currencyDisplay: CurrencyDisplay.SYMBOL,
				currency: config?.currency || Currency.EUR,
				minimumFractionDigits: showFractions ? 2 : 0,
				maximumFractionDigits: showFractions ? 2 : 0,
			}).format(Number(number));
		},
		date: (date, options = {}) => {
			return date ? new Intl.DateTimeFormat(locale, options).format(new Date(date)) : "-";
		},
	};
};

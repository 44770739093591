import dayjs from "dayjs";

export const rawDateFormat: string = "YYYY-MM-DD";

export const yearMonthDay: string = "YYYY.MM.DD";
export const dayMonthYear: string = "DD.MM.YYYY";

export const descriptiveMonthDayYear: string = "MMM D, YYYY";

export const fullDateTime = "DD.MM.YYYY HH:mm";
export const fullDateTimeUTC = "YYYY.MM.DD hh:mm (UTC)";

// when FE requires only year we still need to send it to BE as full date
export const yearToIsoDate = (year: string | number): string =>
	dayjs(`01-02-${year}`, "MM-DD-YYYY").toISOString();

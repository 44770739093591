import dayjs from "dayjs";

import { FinanceObjectGetResponse, FinanceObjectRequest } from "@teylor-tools/Api";
import { rawDateFormat, yearToIsoDate } from "@teylor-tools/utils/dateFormats";

import { FinancingObjectForm } from "./ObjectForm";

export const prepareRequestObjectFromForm = (
	form: FinancingObjectForm | FinanceObjectGetResponse
): FinanceObjectRequest => ({
	...form,
	built_at: form.built_at ? yearToIsoDate(form.built_at) : undefined,
	merchant_company_id: form.merchant_company_id || "",
	expected_delivery_date:
		form.expected_delivery_date &&
		dayjs(form.expected_delivery_date.toString()).format(rawDateFormat),
});

export const prepareObjectFormFromResponse = (
	obj: FinanceObjectGetResponse
): FinancingObjectForm => ({
	...obj,
	built_at: dayjs(obj.built_at).get("year"),
	expected_delivery_date: obj.expected_delivery_date
		? dayjs(obj.expected_delivery_date, { format: rawDateFormat })
		: undefined,
});

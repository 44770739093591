import { useMemo } from "react";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, FormItemProps, Input } from "antd";
import { PasswordProps } from "antd/es/input";
import { Rule } from "antd/lib/form";

import { useTranslations } from "../../../translations/translations";

// check for a special char, a digit, a capital letter, and lower case letter
const PASSWORD_REGEX = /(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]).*(?=.*[0-9]).*/;
const MAX_PASSWORD_LENGTH = 254;

export interface PasswordFormItemProps extends FormItemProps {
	inputProps?: PasswordProps;
	checkPattern?: boolean;
}

const PasswordFormItem = ({ checkPattern = true, inputProps, ...props }: PasswordFormItemProps) => {
	const t = useTranslations();

	const rules = useMemo(() => {
		const rules: Rule[] = [
			{
				required: true,
				message: t.formErrors.fieldRequired,
			},
		];

		if (checkPattern) {
			rules.push(
				{
					type: "string",
					min: 8,
					message: t.formErrors.passwordShort,
				},
				{
					pattern: PASSWORD_REGEX,
					message: t.formErrors.passwordNotComplex,
				}
			);
		}

		return rules;
	}, [checkPattern, t]);

	return (
		<Form.Item rules={rules} validateFirst {...props}>
			<Input.Password
				maxLength={MAX_PASSWORD_LENGTH}
				placeholder="•••••••••"
				iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
				{...inputProps}
				data-cy="password-field"
			/>
		</Form.Item>
	);
};

export default PasswordFormItem;

export const validateValueDifferentThan = <T extends string | number>({
	value,
	differentThan,
	error,
}: {
	value: T;
	differentThan: T | T[];
	error: string;
}): Promise<void | string> => {
	if (!value) {
		return Promise.resolve();
	}

	const isArray = Array.isArray(differentThan);
	if ((isArray && differentThan.includes(value)) || (!isArray && value === differentThan)) {
		return Promise.reject(error);
	}

	return Promise.resolve();
};

import { CountryCode } from "../Api";
import { CountryList } from "./i18n/Countries";

export interface CountriesList {
	code: CountryCode;
	countryLabel: string;
}

export const getCountries = ({
	preferredCountries,
	countries,
}: {
	preferredCountries?: CountryCode[];
	countries: CountryList;
}): CountriesList[] => {
	let result: CountriesList[];

	if (!!preferredCountries?.length) {
		const preferredCountriesList = preferredCountries.map((code) => [code, countries[code]]);
		const availableCountiesList = [...preferredCountriesList, ...Object.entries(countries)];

		result = [
			...new Map(availableCountiesList.map((country) => [country[0], country])).values(),
		].map(([code, countryLabel]) => ({ code, countryLabel })) as CountriesList[];
	} else {
		result = Object.entries(countries).map(([code, countryLabel]) => ({
			code,
			countryLabel,
		})) as CountriesList[];
	}

	return result;
};

import { ReactNode, createContext, useContext } from "react";

import { ObjectFinancingType, ObjectRentalState } from "@teylor-tools/Api";
import { CountryList, countriesEN } from "@teylor-tools/utils/i18n/Countries";

export interface Translations {
	add: string;
	save: string;
	yes: string;
	no: string;
	cancel: string;
	select: string;
	delete: string;
	edit: string;

	city: string;
	cityPlaceholder: string;
	street: string;
	streetPlaceholder: string;
	country: string;
	countries: CountryList;
	emailInputErrorMessage: string;
	emailInputLabel: string;
	emailInputPlaceholder: string;
	postcode: string;
	postcodePlaceholder: string;
	tinInvalid: string;
	tinPlaceholder: string;
	hrNumber: string;
	hrNumberPlaceholder: string;
	houseNumber: string;
	houseNumberPlaceholder: string;
	ibanInvalid: string;

	formErrors: {
		fieldRequired: string;
		fieldInvalid: string;
		fieldMustBeBetweenMinMaxNumbers: (min: number, max: number) => string;
		fieldMustBeBetweenMinMaxChars: (min: number, max: number) => string;
		fieldMustBeBetweenMinMax: (min: number | string, max: number | string) => string;
		fieldMustBeMinChars: (min: number) => string;
		passwordShort: string;
		passwordNotComplex: string;
		invalidPhoneNumber: string;
		fieldContainsInvalidCharacters: string;
		personalTinMustBeDifferentThanCompanyTin: string;
	};

	object: {
		object: string;
		manufacturer: string;
		type: string;
		yearBuilt: string;
		objectDescription: string;
		netPurchasePrice: string;
		downPayment: string;
		remainingValue: string;
		numberOfObjects: string;
		financingType: string;
		rentalState: string;
		isFixedInterest: string;
		companyName: string;
		street: string;
		houseNumber: string;
		city: string;
		postcode: string;
		country: string;
		retailerCompanyId: string;
		retailerName: string;
		retailerAddress: string;
		expectedDeliveryDate: string;
		createOrderForm: string;
		errors: {
			required: string;
			postcodeInvalid: string;
		};
		placeholders: {
			manufacturer: string;
			type: string;
			yearBuilt: string;
			objectDescription: string;
			objectCompanyName: string;
			street: string;
			houseNumber: string;
			city: string;
			retailerCompanyId: string;
		};
		options: {
			financingTypes: {
				[key in ObjectFinancingType]: string;
			};
			rentalStates: {
				[key in ObjectRentalState]: string;
			};
			isFixedInterest: {
				yes: string;
				no: string;
			};
		};
		sections: {
			retailer: string;
		};
	};

	representativeCard: {
		address: string;
		addTin: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		email: string;
		legalSignatory: string;
		nationality: string;
		ownership: string;
		phone: string;
		tin: string;
		willingToSign: string;
		creditScore: string;
		lastUpdated: string;
	};

	representativeModal: {
		addRepresentative: string;
		allFieldsRequired: string;
		beneficialOwner: string;
		canSignAlone: string;
		dateOfBirth: string;
		editRepresentative: string;
		firstName: string;
		houseNumber: string;
		lastName: string;
		legalSignatory: string;
		nationality: string;
		optional: string;
		percentageOwnership: string;
		phoneNumber: string;
		representativeNotContacted: string;
		street: string;
		taxNumber: string;
		willingToSign: string;
	};
	companySelectFormItem: {
		label: string;
		placeholder: string;
		errorCompanyNameInvalid: string;
		errorCompanyNameNotFound: string;
		errorCompanyNameNotFoundLinkText: string;
	};
	industryCodes: {
		industryCode: string;
		industryCodes: string;
		description: string;
		weight: string;
		lastUpdatedOn: (date: string) => JSX.Element | string;
		source: (source: string) => JSX.Element | string;
		codeType: (type: string) => JSX.Element | string;
	};
	industryCodesEditModal: {
		title: string;
		update: string;
		type: string;
		codeEnum: {
			wz_2008: string;
			noga_2008: string;
		};
		industryCodeNumber: (index: number) => string;
		code: string;
		weight: string;
		optional: string;
		description: string;
		addIndustryCode: string;
		fieldRequired: string;
	};
	scoring: {
		overview: {
			alyze: string;
			alyzeRating: string;
			gridRate: string;
		};
		issues: {
			issues: string;
			critical: string;
			nonCritical: string;
		};
		scores: {
			scores: string;
			pdScore: string;
			totalScore: string;
			ratingScore: (year: number) => string;
		};
	};
}

export const fallbackTranslations: Translations = {
	add: "Add",
	save: "Save",
	yes: "Yes",
	no: "No",
	cancel: "Cancel",
	select: "Select",
	edit: "Edit",
	delete: "Delete",

	city: "City",
	cityPlaceholder: "e.g. Berlin",
	street: "Street",
	streetPlaceholder: "e.g. Whateverstreet",
	country: "Country",
	countries: countriesEN,
	emailInputErrorMessage: "Please provide valid e-mail address.",
	emailInputLabel: "E-mail Address",
	emailInputPlaceholder: "E-mail Address",
	postcode: "Postcode",
	postcodePlaceholder: "Eg. 10315",
	tinInvalid: "Please provide valid tax number.",
	tinPlaceholder: "Example: 1234567890",
	hrNumber: "HR Number",
	hrNumberPlaceholder: "e.g. 12345",
	houseNumber: "House number",
	houseNumberPlaceholder: "e.g. 30a",
	ibanInvalid: "Please provide a valid IBAN.",

	formErrors: {
		fieldRequired: "Required field",
		fieldInvalid: "Field invalid",
		fieldMustBeBetweenMinMaxNumbers: (min, max) =>
			`Field must be between ${min} and ${max} numbers`,
		fieldMustBeBetweenMinMaxChars: (min, max) =>
			`Field must be between ${min} and ${max} characters`,
		fieldMustBeBetweenMinMax: (min, max) => `Field must be between ${min} and ${max}`,
		fieldMustBeMinChars: (min) => `Field must be minimum ${min} characters long`,
		passwordShort: "Password is too short",
		passwordNotComplex: "Password is not complex",
		invalidPhoneNumber: 'Must start with "+" and a country code',
		fieldContainsInvalidCharacters: "Field contains invalid characters",
		personalTinMustBeDifferentThanCompanyTin: "TIN must be different than company TIN",
	},

	object: {
		object: "Object",
		manufacturer: "Manufacturer",
		type: "Type",
		yearBuilt: "Year built",
		objectDescription: "Detailed Description",
		netPurchasePrice: "Net Purchase Price",
		downPayment: "Down Payment",
		remainingValue: "Remaining Value",
		numberOfObjects: "Number of Objects",
		financingType: "Financing Type",
		rentalState: "Will this machine be rented out?",
		isFixedInterest: "Should the interest  be a fixed rate?",
		companyName: "Company name",
		street: "Street",
		houseNumber: "House number",
		city: "City",
		postcode: "Post code",
		country: "Country",
		retailerCompanyId: "Company register number",
		retailerName: "Name",
		retailerAddress: "Address",
		expectedDeliveryDate: "Deliver date (expected)",
		createOrderForm: "Create Order Form",
		errors: {
			required: "Required",
			postcodeInvalid: "Please provide a valid postcode.",
		},
		placeholders: {
			manufacturer: "Eg. Machine GmbH",
			type: "Eg. Machinery",
			yearBuilt: "Eg. 2020",
			objectDescription: "Describe the object being financed",
			objectCompanyName: "Eg. Metabo GmbH",
			street: "Eg. Example street",
			houseNumber: "Eg. 103",
			city: "Eg. Berlin",
			retailerCompanyId: "Eg. 881234",
		},
		options: {
			isFixedInterest: {
				yes: "Yes",
				no: "No",
			},
			financingTypes: {
				leasing: "Leasing",
				hire_purchase: "Hire-Purchase",
				financing: "Financing",
				sale_leaseback: "Sale & leaseback",
				credit_line: "Credit line",
				other: "Other",
			},
			rentalStates: {
				no: "No",
				internal: "Within company",
				b2c: "To the end customer",
			},
		},
		sections: {
			retailer: "Retailer",
		},
	},

	representativeCard: {
		address: "Address",
		addTin: "Add TIN",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		email: "Email",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		ownership: "Ownership",
		phone: "Phone",
		tin: "TIN",
		willingToSign: "Willing to sign",
		creditScore: "Credit Score",
		lastUpdated: "Last updated",
	},

	representativeModal: {
		addRepresentative: "Add representative",
		allFieldsRequired: "All fields are required",
		beneficialOwner: "Beneficial owner",
		canSignAlone: "Can sign alone",
		dateOfBirth: "Date of birth",
		editRepresentative: "Edit representative",
		firstName: "First name",
		houseNumber: "House number",
		lastName: "Last name",
		legalSignatory: "Legal signatory",
		nationality: "Nationality",
		optional: "(can be added later)",
		percentageOwnership: "Percentage Ownership",
		phoneNumber: "Phone",
		representativeNotContacted:
			"The representative will not be contacted before the offer is accepted.",
		street: "Street",
		taxNumber: "TIN (Tax ID number or Tax Number)",
		willingToSign: "Willing to sign",
	},
	companySelectFormItem: {
		label: "Company name",
		placeholder: "Example GmbH",
		errorCompanyNameInvalid: "Please review your company name and try again.",
		errorCompanyNameNotFound: "Not found? No Problem!",
		errorCompanyNameNotFoundLinkText: "Click here to enter the company details manually.",
	},
	industryCodes: {
		industryCode: "Industry code",
		industryCodes: "Industry codes",
		description: "Description",
		weight: "Weight",
		lastUpdatedOn: (date) => `last updated on: ${date}`,
		source: (source) => `source: ${source}`,
		codeType: (type) => `code type: ${type}`,
	},
	industryCodesEditModal: {
		title: "Update existing Industry Codes",
		update: "Update",
		type: "Type",
		codeEnum: { wz_2008: "WZ 20008", noga_2008: "NOGA 2008" },
		industryCodeNumber: (index) => `Industry code ${index}`,
		code: "Code",
		weight: "Weight",
		optional: "optional",
		description: "Description",
		addIndustryCode: "Add Industry Code",
		fieldRequired: "This field is required",
	},
	scoring: {
		overview: {
			alyze: "Alyze",
			alyzeRating: "Alyze rating",
			gridRate: "Grid rate",
		},
		issues: {
			issues: "Issues",
			critical: "Critical",
			nonCritical: "Non-Critical",
		},
		scores: {
			scores: "Scores",
			pdScore: "PD Score",
			totalScore: "Total score",
			ratingScore: (year) => `Rating score ${year}`,
		},
	},
};

const TranslationsContext = createContext<Translations>(fallbackTranslations);

export const useTranslations = () => {
	return useContext(TranslationsContext);
};

interface TranslationsProviderProps {
	translations: Partial<Translations>;
	children: ReactNode;
}

export const TranslationsProvider = ({ translations, children }: TranslationsProviderProps) => {
	const t = useTranslations();

	return (
		<TranslationsContext.Provider value={{ ...t, ...translations }}>
			{children}
		</TranslationsContext.Provider>
	);
};

import { CountryCode } from "../../Api";

export type CountryList = Record<CountryCode, string>;
export function getCountryList(langCode: string): CountryList {
	if (langCode.includes("fr")) {
		return countriesFR;
	} else if (langCode.includes("en")) {
		return countriesEN;
	} else return countriesDE;
}

export const countriesEN: CountryList = {
	AF: "Afghanistan",
	AX: "Åland Islands",
	AL: "Albania",
	DZ: "Algeria",
	AS: "American Samoa",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarctica",
	AG: "Antigua and Barbuda",
	AR: "Argentina",
	AM: "Armenia",
	AW: "Aruba",
	AU: "Australia",
	AT: "Austria",
	AZ: "Azerbaijan",
	BS: "Bahamas",
	BH: "Bahrain",
	BD: "Bangladesh",
	BB: "Barbados",
	BY: "Belarus",
	BE: "Belgium",
	BZ: "Belize",
	BJ: "Benin",
	BM: "Bermuda",
	BT: "Bhutan",
	BO: "Bolivia",
	BA: "Bosnia and Herzegovina",
	BW: "Botswana",
	BV: "Bouvet Island",
	BR: "Brazil",
	IO: "British Indian Ocean Territory",
	BN: "Brunei Darussalam",
	BG: "Bulgaria",
	BF: "Burkina Faso",
	BI: "Burundi",
	KH: "Cambodia",
	CM: "Cameroon",
	CA: "Canada",
	CV: "Cape Verde",
	KY: "Cayman Islands",
	CF: "Central African Republic",
	TD: "Chad",
	CL: "Chile",
	CN: "China",
	CX: "Christmas Island",
	CC: "Cocos (Keeling) Islands",
	CO: "Colombia",
	KM: "Comoros",
	CG: "Congo",
	CD: "Congo, The Democratic Republic of the",
	CK: "Cook Islands",
	CR: "Costa Rica",
	CI: "Cote D'Ivoire",
	HR: "Croatia",
	CU: "Cuba",
	CY: "Cyprus",
	CZ: "Czech Republic",
	DK: "Denmark",
	DJ: "Djibouti",
	DM: "Dominica",
	DO: "Dominican Republic",
	EC: "Ecuador",
	EG: "Egypt",
	SV: "El Salvador",
	GQ: "Equatorial Guinea",
	ER: "Eritrea",
	EE: "Estonia",
	ET: "Ethiopia",
	FK: "Falkland Islands (Malvinas)",
	FO: "Faroe Islands",
	FJ: "Fiji",
	FI: "Finland",
	FR: "France",
	GF: "French Guiana",
	PF: "French Polynesia",
	TF: "French Southern Territories",
	GA: "Gabon",
	GM: "Gambia",
	GE: "Georgia",
	DE: "Germany",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Greece",
	GL: "Greenland",
	GD: "Grenada",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernsey",
	GN: "Guinea",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HT: "Haiti",
	HM: "Heard Island and Mcdonald Islands",
	VA: "Holy See (Vatican City State)",
	HN: "Honduras",
	HK: "Hong Kong",
	HU: "Hungary",
	IS: "Iceland",
	IN: "India",
	ID: "Indonesia",
	IR: "Iran, Islamic Republic Of",
	IQ: "Iraq",
	IE: "Ireland",
	IM: "Isle of Man",
	IL: "Israel",
	IT: "Italy",
	JM: "Jamaica",
	JP: "Japan",
	JE: "Jersey",
	JO: "Jordan",
	KZ: "Kazakhstan",
	KE: "Kenya",
	KI: "Kiribati",
	KP: "Korea, Democratic People'S Republic of",
	KR: "Korea, Republic of",
	KW: "Kuwait",
	KG: "Kyrgyzstan",
	LA: "Lao People'S Democratic Republic",
	LV: "Latvia",
	LB: "Lebanon",
	LS: "Lesotho",
	LR: "Liberia",
	LY: "Libyan Arab Jamahiriya",
	LI: "Liechtenstein",
	LT: "Lithuania",
	LU: "Luxembourg",
	MO: "Macao",
	MK: "Macedonia, The Former Yugoslav Republic of",
	MG: "Madagascar",
	MW: "Malawi",
	MY: "Malaysia",
	MV: "Maldives",
	ML: "Mali",
	MT: "Malta",
	MH: "Marshall Islands",
	MQ: "Martinique",
	MR: "Mauritania",
	MU: "Mauritius",
	YT: "Mayotte",
	MX: "Mexico",
	FM: "Micronesia, Federated States of",
	MD: "Moldova, Republic of",
	MC: "Monaco",
	MN: "Mongolia",
	MS: "Montserrat",
	MA: "Morocco",
	MZ: "Mozambique",
	MM: "Myanmar",
	NA: "Namibia",
	NR: "Nauru",
	NP: "Nepal",
	NL: "Netherlands",
	AN: "Netherlands Antilles",
	NC: "New Caledonia",
	NZ: "New Zealand",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	NF: "Norfolk Island",
	MP: "Northern Mariana Islands",
	NO: "Norway",
	OM: "Oman",
	PK: "Pakistan",
	PW: "Palau",
	PS: "Palestinian Territory, Occupied",
	PA: "Panama",
	PG: "Papua New Guinea",
	PY: "Paraguay",
	PE: "Peru",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Poland",
	PT: "Portugal",
	PR: "Puerto Rico",
	QA: "Qatar",
	RE: "Reunion",
	RO: "Romania",
	RU: "Russian Federation",
	RW: "Rwanda",
	SH: "Saint Helena",
	KN: "Saint Kitts and Nevis",
	LC: "Saint Lucia",
	PM: "Saint Pierre and Miquelon",
	VC: "Saint Vincent and the Grenadines",
	WS: "Samoa",
	SM: "San Marino",
	ST: "Sao Tome and Principe",
	SA: "Saudi Arabia",
	SN: "Senegal",
	CS: "Serbia and Montenegro",
	SC: "Seychelles",
	SL: "Sierra Leone",
	SG: "Singapore",
	SK: "Slovakia",
	SI: "Slovenia",
	SB: "Solomon Islands",
	SO: "Somalia",
	ZA: "South Africa",
	GS: "South Georgia and the South Sandwich Islands",
	ES: "Spain",
	LK: "Sri Lanka",
	SD: "Sudan",
	SR: "Suriname",
	SJ: "Svalbard and Jan Mayen",
	SZ: "Swaziland",
	SE: "Sweden",
	CH: "Switzerland",
	SY: "Syrian Arab Republic",
	TW: "Taiwan, Province of China",
	TJ: "Tajikistan",
	TZ: "Tanzania, United Republic of",
	TH: "Thailand",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinidad and Tobago",
	TN: "Tunisia",
	TR: "Turkey",
	TM: "Turkmenistan",
	TC: "Turks and Caicos Islands",
	TV: "Tuvalu",
	UG: "Uganda",
	UA: "Ukraine",
	AE: "United Arab Emirates",
	GB: "United Kingdom",
	US: "United States",
	UM: "United States Minor Outlying Islands",
	UY: "Uruguay",
	UZ: "Uzbekistan",
	VU: "Vanuatu",
	VE: "Venezuela",
	VN: "Viet Nam",
	VG: "Virgin Islands, British",
	VI: "Virgin Islands, U.S.",
	WF: "Wallis and Futuna",
	EH: "Western Sahara",
	YE: "Yemen",
	ZM: "Zambia",
	ZW: "Zimbabwe",
};

export const countriesDE: CountryList = {
	AF: "Afghanistan",
	EG: "Ägypten",
	AX: "Ålandinseln",
	AL: "Albanien",
	DZ: "Algerien",
	AS: "Amerikanisch-Samoa",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarktis",
	AG: "Antigua und Barbuda",
	GQ: "Äquatorialguinea",
	SY: "Arabische Republik Syrien",
	AR: "Argentinien",
	AM: "Armenien",
	AW: "Aruba",
	AZ: "Aserbaidschan",
	ET: "Äthiopien",
	AU: "Australien",
	BS: "Bahamas",
	BH: "Bahrain",
	BD: "Bangladesch",
	BB: "Barbados",
	BE: "Belgien",
	BZ: "Belize",
	BJ: "Benin",
	BM: "Bermuda",
	BT: "Bhutan",
	BO: "Bolivien",
	BA: "Bosnien und Herzegowina",
	BW: "Botswana",
	BV: "Bouvetinsel",
	BR: "Brasilien",
	IO: "Britisches Territorium im Indischen Ozean",
	BN: "Brunei Darussalam",
	BG: "Bulgarien",
	BF: "Burkina Faso",
	BI: "Burundi",
	CL: "Chile",
	CN: "China",
	CC: "Cocos-(Keeling-)Inseln",
	CK: "Cookinseln",
	CR: "Costa Rica",
	CI: "Cote D'Ivoire",
	DK: "Dänemark",
	LA: "Demokratische Volksrepublik Laos",
	DE: "Deutschland",
	DM: "Dominica",
	DO: "Dominikanische Republik",
	DJ: "Dschibuti",
	EC: "Ecuador",
	SV: "El Salvador",
	ER: "Eritrea",
	EE: "Estland",
	FK: "Falklandinseln (Malvinas)",
	FO: "Färöer-Inseln",
	FJ: "Fidschi",
	FI: "Finnland",
	FR: "Frankreich",
	GF: "Französisch-Guayana",
	PF: "Französisch-Polynesien",
	TF: "Französische Südgebiete",
	GA: "Gabun",
	GM: "Gambia",
	GE: "Georgia",
	GH: "Ghana",
	GI: "Gibraltar",
	GD: "Grenada",
	GR: "Griechenland",
	GL: "Grönland",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernsey",
	GN: "Guinea",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HT: "Haiti",
	HM: "Heard Island und Mcdonald Islands",
	VA: "Heiliger Stuhl (Staat Vatikanstadt)",
	HN: "Honduras",
	HK: "Hongkong",
	IN: "Indien",
	ID: "Indonesien",
	IQ: "Irak",
	IR: "Iran, Islamische Republik",
	IE: "Irland",
	IS: "Island",
	IM: "Isle of Man",
	IL: "Israel",
	IT: "Italien",
	JM: "Jamaika",
	JP: "Japan",
	YE: "Jemen",
	JE: "Jersey",
	JO: "Jordanien",
	VG: "Jungferninseln, Britisch",
	KY: "Kaimaninseln",
	KH: "Kambodscha",
	CM: "Kamerun",
	CA: "Kanada",
	CV: "Kap Verde",
	KZ: "Kasachstan",
	QA: "Katar",
	KE: "Kenia",
	KG: "Kirgisistan",
	KI: "Kiribati",
	UM: "Kleinere abgelegene Inseln der Vereinigten Staaten",
	CO: "Kolumbien",
	KM: "Komoren",
	CG: "Kongo",
	CD: "Kongo, Demokratische Republik",
	KP: "Korea, Demokratische Volksrepublik",
	KR: "Korea, Republik",
	HR: "Kroatien",
	CU: "Kuba",
	KW: "Kuwait",
	LS: "Lesotho",
	LV: "Lettland",
	LB: "Libanon",
	LR: "Liberia",
	LY: "Libysche Arabische Jamahiriya",
	LI: "Liechtenstein",
	LT: "Litauen",
	LU: "Luxemburg",
	MO: "Macao",
	MG: "Madagaskar",
	MW: "Malawi",
	MY: "Malaysia",
	MV: "Malediven",
	ML: "Mali",
	MT: "Malta",
	MA: "Marokko",
	MH: "Marshallinseln",
	MQ: "Martinique",
	MR: "Mauretanien",
	MU: "Mauritius",
	YT: "Mayotte",
	MK: "Mazedonien, ehemalige jugoslawische Republik",
	MX: "Mexiko",
	FM: "Mikronesien, Föderierte Staaten von",
	MD: "Moldawien, Republik",
	MC: "Monaco",
	MN: "Mongolei",
	MS: "Montserrat",
	MZ: "Mosambik",
	MM: "Myanmar",
	NA: "Namibia",
	NR: "Nauru",
	NP: "Nepal",
	NC: "Neukaledonien",
	NZ: "Neuseeland",
	NI: "Nicaragua",
	NL: "Niederlande",
	AN: "Niederländische Antillen",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	MP: "Nördliche Marianen",
	NF: "Norfolkinsel",
	NO: "Norwegen",
	OM: "Oman",
	AT: "Österreich",
	PK: "Pakistan",
	PS: "Palästinensisches Territorium, besetzt",
	PW: "Palau",
	PA: "Panama",
	PG: "Papua-Neuguinea",
	PY: "Paraguay",
	PE: "Peru",
	PH: "Philippinen",
	PN: "Pitcairn",
	PL: "Polen",
	PT: "Portugal",
	PR: "Puerto Rico",
	RE: "Reunion",
	RO: "Rumänien",
	RU: "Russische Föderation",
	RW: "Rwanda",
	SB: "Salomonen",
	ZM: "Sambia",
	WS: "Samoa",
	SM: "San Marino",
	ST: "Sao Tome und Principe",
	SA: "Saudi-Arabien",
	SE: "Schweden",
	CH: "Schweiz",
	SN: "Senegal",
	CS: "Serbien und Montenegro",
	SC: "Seychellen",
	SL: "Sierra Leone",
	ZW: "Simbabwe",
	SG: "Singapur",
	SK: "Slowakei",
	SI: "Slowenien",
	SO: "Somalia",
	ES: "Spanien",
	SJ: "Spitzbergen und Jan Mayen",
	LK: "Sri Lanka",
	SH: "St. Helena",
	KN: "St. Kitts und Nevis",
	LC: "St. Lucia",
	PM: "St. Pierre und Miquelon",
	VC: "St. Vincent und die Grenadinen",
	ZA: "Südafrika",
	SD: "Sudan",
	GS: "Südgeorgien und die Südlichen Sandwichinseln",
	SR: "Suriname",
	SZ: "Swasiland",
	TJ: "Tadschikistan",
	TW: "Taiwan, Provinz China",
	TZ: "Tansania, Vereinigte Republik",
	TH: "Thailand",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinidad und Tobago",
	TD: "Tschad",
	CZ: "Tschechische Republik",
	TN: "Tunesien",
	TR: "Türkei",
	TM: "Turkmenistan",
	TC: "Turks- und Caicosinseln",
	TV: "Tuvalu",
	UG: "Uganda",
	UA: "Ukraine",
	HU: "Ungarn",
	UY: "Uruguay",
	UZ: "Usbekistan",
	VU: "Vanuatu",
	VE: "Venezuela",
	AE: "Vereinigte Arabische Emirate",
	US: "Vereinigte Staaten",
	GB: "Vereinigtes Königreich",
	VN: "Vietnam",
	VI: "Virgin Islands, USA",
	WF: "Wallis und Futuna",
	CX: "Weihnachtsinsel",
	BY: "Weißrussland",
	EH: "Westsahara",
	CF: "Zentralafrikanische Republik",
	CY: "Zypern",
};

export const countriesFR: CountryList = {
	AF: "Afghanistan",
	ZA: "Afrique du Sud",
	AL: "Albanie",
	DZ: "Algérie",
	DE: "Allemagne",
	AD: "Andorre",
	AO: "Angola",
	AI: "Anguilla",
	AQ: "Antarctique",
	AG: "Antigua et Barbuda",
	AN: "Antilles néerlandaises",
	SA: "Arabie Saoudite",
	AR: "Argentine",
	AM: "Arménie",
	AW: "Aruba",
	AU: "Australie",
	AT: "Autriche",
	AZ: "Azerbaïdjan",
	BS: "Bahamas",
	BH: "Bahreïn",
	BD: "Bangladesh",
	BB: "Barbade",
	BY: "Belarus",
	BE: "Belgique",
	BZ: "Belize",
	BJ: "Bénin",
	BM: "Bermudes",
	BT: "Bhoutan",
	BO: "Bolivie",
	BA: "Bosnie-Herzégovine",
	BW: "Botswana",
	BR: "Brésil",
	BN: "Brunei Darussalam",
	BG: "Bulgarie",
	BF: "Burkina Faso",
	BI: "Burundi",
	KH: "Cambodge",
	CM: "Cameroun",
	CA: "Canada",
	CV: "Cap-Vert",
	CL: "Chili",
	CN: "Chine",
	CY: "Chypre",
	CO: "Colombie",
	KM: "Comores",
	CG: "Congo",
	CD: "Congo, République démocratique du",
	KR: "Corée, République de",
	KP: "Corée, République populaire démocratique de",
	CR: "Costa Rica",
	CI: "Côte d'Ivoire",
	HR: "Croatie ",
	CU: "Cuba",
	DK: "Danemark",
	DJ: "Djibouti",
	DM: "Dominique",
	EG: "Égypte",
	SV: "El Salvador",
	AE: "Émirats arabes unis",
	EC: "Équateur",
	ER: "Eritrea",
	ES: "Espagne",
	EE: "Estonie",
	ET: "Ethiopie",
	RU: "Fédération de Russie",
	FJ: "Fiji",
	FI: "Finlande",
	FR: "France",
	GA: "Gabon",
	GM: "Gambie",
	GE: "Géorgie",
	GS: "Géorgie du Sud et les îles Sandwich du Sud",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Grèce",
	GD: "Grenade",
	GL: "Groenland",
	GP: "Guadeloupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernesey",
	GN: "Guinée",
	GQ: "Guinée équatoriale",
	GW: "Guinée-Bissau",
	GY: "Guyana",
	GF: "Guyane française",
	HT: "Haïti",
	HN: "Honduras",
	HK: "Hong Kong",
	HU: "Hongrie",
	BV: "Île Bouvet",
	CX: "Île Christmas",
	HM: "Île Heard et îles Mcdonald",
	NF: "Île Norfolk",
	AX: "Îles Åland",
	KY: "Îles Caïmans",
	CC: "Îles Cocos (Keeling)",
	CK: "Îles Cook",
	FK: "Îles Falkland (Malvinas)",
	FO: "Îles Féroé",
	UM: "Îles mineures éloignées des États-Unis",
	TC: "Îles Turks et Caicos",
	VI: "Iles Vierges américaines",
	VG: "Îles Vierges britanniques",
	IN: "Inde",
	ID: "Indonésie",
	IR: "Iran, République islamique d'",
	IQ: "Iraq",
	IE: "Irlande",
	IS: "Islande",
	IM: "Isle of Man",
	IL: "Israël",
	IT: "Italie",
	LY: "Jamahiriya arabe libyenne",
	JM: "Jamaïque",
	JP: "Japon",
	JE: "Jersey",
	JO: "Jordanie",
	KZ: "Kazakhstan",
	KE: "Kenya",
	KG: "Kirghizistan",
	KI: "Kiribati",
	KW: "Koweït",
	LA: "Lao People's Democratic Republic",
	LS: "Lesotho",
	LV: "Lettonie",
	LB: "Liban",
	LR: "Liberia",
	LI: "Liechtenstein",
	LT: "Lituanie",
	LU: "Luxembourg",
	MO: "Macao",
	MK: "Macédoine, l'ancienne République yougoslave de",
	MG: "Madagascar",
	MY: "Malaisie",
	MW: "Malawi",
	MV: "Maldives",
	ML: "Mali",
	MT: "Malte",
	MA: "Maroc",
	MH: "Marshall Islands",
	MQ: "Martinique",
	MU: "Maurice",
	MR: "Mauritanie",
	YT: "Mayotte",
	MX: "Mexique",
	FM: "Micronésie, Etats fédérés de",
	MD: "Moldavie, République de",
	MC: "Monaco",
	MN: "Mongolie",
	MS: "Montserrat",
	MZ: "Mozambique",
	MM: "Myanmar",
	NA: "Namibie",
	NR: "Nauru",
	NP: "Népal",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	MP: "Northern Mariana Islands",
	NO: "Norvège",
	NC: "Nouvelle-Calédonie",
	NZ: "Nouvelle-Zélande",
	OM: "Oman",
	UG: "Ouganda",
	UZ: "Ouzbékistan",
	PK: "Pakistan",
	PW: "Palau",
	PA: "Panama",
	PG: "Papouasie-Nouvelle-Guinée",
	PY: "Paraguay",
	NL: "Pays-Bas",
	PE: "Pérou",
	PH: "Philippines",
	PN: "Pitcairn",
	PL: "Pologne",
	PF: "Polynésie française",
	PR: "Porto Rico",
	PT: "Portugal",
	QA: "Qatar",
	SY: "République arabe syrienne",
	CF: "République centrafricaine",
	DO: "République dominicaine",
	CZ: "République tchèque",
	RE: "Réunion",
	RO: "Roumanie",
	GB: "Royaume-Uni",
	RW: "Rwanda",
	EH: "Sahara occidental",
	KN: "Saint Kitts and Nevis",
	PM: "Saint Pierre et Miquelon",
	VC: "Saint Vincent et les Grenadines",
	SM: "Saint-Marin",
	VA: "Saint-Siège (État de la Cité du Vatican)",
	SH: "Sainte-Hélène",
	LC: "Sainte-Lucie",
	WS: "Samoa",
	AS: "Samoa américaines",
	ST: "Sao Tomé-et-Principe",
	SN: "Sénégal",
	CS: "Serbie-et-Monténégro",
	SC: "Seychelles",
	SL: "Sierra Leone",
	SG: "Singapour",
	SK: "Slovaquie",
	SI: "Slovénie",
	SB: "Solomon Islands",
	SO: "Somalie",
	SD: "Soudan",
	LK: "Sri Lanka",
	SE: "Suède",
	CH: "Suisse",
	SR: "Suriname",
	SJ: "Svalbard et Jan Mayen",
	SZ: "Swaziland",
	TJ: "Tadjikistan",
	TW: "Taiwan, Province de Chine",
	TZ: "Tanzanie, République-Unie de",
	TD: "Tchad",
	TF: "Terres australes françaises",
	IO: "Territoire britannique de l'océan Indien",
	PS: "Territoire palestinien occupé",
	TH: "Thaïlande",
	TL: "Timor-Leste",
	TG: "Togo",
	TK: "Tokelau",
	TO: "Tonga",
	TT: "Trinité-et-Tobago",
	TN: "Tunisie",
	TM: "Turkménistan",
	TR: "Turquie",
	TV: "Tuvalu",
	UA: "Ukraine",
	US: "United States",
	UY: "Uruguay",
	VU: "Vanuatu",
	VE: "Venezuela",
	VN: "Viet Nam",
	WF: "Wallis et Futuna",
	YE: "Yémen",
	ZM: "Zambie",
	ZW: "Zimbabwe",
};

/*
 ** A handy function to sort countries according to the locale (takes into account special characters)
 ** You can use in on a new list and copy the result list here
 */
// const sortCountries = (list: CountryList, lang: Lang) => {
// 	return Object.entries(list)
// 		.sort(([, a], [, b]) => new Intl.Collator(lang).compare(a, b))
// 		.reduce((r, [k, v]) => ({ ...r, [k]: v }), {} as CountryList);
// };

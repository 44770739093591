import NumberInputLocalized, {
	NumberInputLocalizedProps,
} from "../number-input-localized/NumberInputLocalized";

const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", ",", "-"];

const PercentInput = (props: NumberInputLocalizedProps) => {
	const onKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		const { value } = evt.target;
		const dotIndex = value.indexOf(".");
		const dotExists = dotIndex > -1;
		const decimalPlaces = dotExists ? value.length - dotIndex - 1 : 0;
		const decimalPrecision = props.precision || 2;

		if (
			// don't allow to press key other than allowedKeys
			!allowedKeys.includes(evt.nativeEvent.key) ||
			// or more than 2 decimal places
			decimalPlaces === decimalPrecision ||
			// or multiple dots
			(evt.nativeEvent.key === "." && dotExists)
		) {
			evt.preventDefault();
			evt.stopPropagation();
		}
	};

	return <NumberInputLocalized onKeyPress={onKeyPress} prefix="%" {...props} />;
};

export default PercentInput;

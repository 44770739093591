export const downloadFileFromUrl = (url: string, fileName: string, newTab = false): void => {
	const linkSource = url;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;

	if (!url.includes(window.location.origin) || newTab) {
		// if the file's url doesn't match the website's origin
		// the download API is disabled, so open the file in the new
		// tab instead to create a fallback
		downloadLink.target = "_blank";
	}

	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export const downloadGeneratedFileFromUrl = async (
	url: string,
	fileName: string,
	newTab = false
): Promise<void> => {
	const response = await fetch(url, {
		credentials: "include",
	});
	if (response.status != 200) {
		throw new Error("Could not download the file");
	}
	const blob = await response.blob();
	const urlObject = URL.createObjectURL(blob);

	const downloadLink = document.createElement("a");
	downloadLink.href = urlObject;
	downloadLink.download = fileName;

	if (!url.includes(window.location.origin) || newTab) {
		downloadLink.target = "_blank";
	}

	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);

	URL.revokeObjectURL(urlObject);
};

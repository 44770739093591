import { BlockOutlined, PlusOutlined } from "@ant-design/icons";
import { Card as AntdCard, Button, Space, Typography } from "antd";
import styled from "styled-components";

const Card = styled(AntdCard)`
	margin-top: 24px;
	text-align: center;
	padding: 68px 92px 40px;
	display: flex;
	flex-direction: column;

	& .ant-card-body {
		padding: 0;
	}

	@media (max-width: 768px) {
		padding: 30px;
	}
`;

const BlockIcon = styled(BlockOutlined)`
	font-size: 19px;
	color: ${(props) => props.theme.color_primary};
`;

const ButtonWrapper = styled.div`
	margin-top: 24px;
`;

interface Props {
	onClick: () => void;
	translations: {
		title: string;
		subtitle: string;
		addObject: string;
	};
}
const NoObjectsCard = ({ onClick, translations }: Props) => {
	return (
		<Card>
			<Space direction="vertical">
				<BlockIcon />
				<Typography.Text strong>{translations.title}</Typography.Text>
				<Typography.Text type="secondary">{translations.subtitle}</Typography.Text>
				<ButtonWrapper>
					<Button
						type="primary"
						data-cy="add-representatives-button"
						size="large"
						icon={<PlusOutlined />}
						style={{ marginTop: 16 }}
						onClick={onClick}
					>
						{translations.addObject}
					</Button>
				</ButtonWrapper>
			</Space>
		</Card>
	);
};

export default NoObjectsCard;

import { KeyboardEvent } from "react";

import NumberInputLocalized, {
	NumberInputLocalizedProps,
} from "../number-input-localized/NumberInputLocalized";

const locale = window.navigator.language;

const getCurrencySymbol = (currency: string) => {
	const symbol = new Intl.NumberFormat(locale, { style: "currency", currency })
		.formatToParts()
		.find((x) => x.type === "currency");
	return symbol && symbol.value;
};

interface Props extends NumberInputLocalizedProps {
	currency?: string;
}
// don't allow to press key other than the following kit
const allowedKeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", ".", ","];

const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>): void => {
	if (!allowedKeys.includes(evt.nativeEvent.key)) {
		evt.preventDefault();
		evt.stopPropagation();
	}
};

const CurrencyInput = ({ currency = "EUR", ...props }: Props) => {
	const currencySymbol = getCurrencySymbol(currency);

	return (
		<NumberInputLocalized
			precision={2}
			onKeyPress={onKeyPress}
			prefix={currencySymbol}
			{...props}
		/>
	);
};

export default CurrencyInput;

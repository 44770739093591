/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface PaymentProviderInfo {
	provider_client_id?: string;
	provider_client_alias?: string;
	provider_user_id?: string;
	provider_wallet_id?: string;
	provider_bank_account_id?: string;
	provider_mandate_id?: string;
	provider_mandate_reference?: string;
}

export interface PartnerCreateData {
	partner_id?: string;
	name: string;
	partner_key: string;
	tenant_id?: string;
	country?: string;
	phone?: string;
	email?: string;
	website?: string;
	legal_form?: string;
	/** @format datetime */
	foundation_date?: string;
	/** @format datetime */
	active_since?: string;
	is_contract_exists?: string;
	type?: string;
	grade?: string;
	is_commission_ongoing?: string;
	commission_rate?: string;
	commission_notes?: string;
	address?: PartnerAddressCreateData;
	bank_info?: PartnerBankInfoCreateData;
	partner_config_type?: PartnerConfigType;
}

export type PartnerUpdateData = PartnerCreateData & {
	tenant_id: string;
};

export interface PartnerListResponseItem {
	partner_id: string;
	short_id?: string;
	tenant_id?: string;
	partner_key?: string;
	name: string;
	/** @format date */
	active_since?: string;
	type?: string;
	is_enabled?: boolean;
	/** @format datetime */
	created_at?: string;
}

export interface PartnerResponse {
	partner_id: string;
	short_id: string;
	tenant_id: string;
	partner_key: string;
	name: string;
	country?: string;
	phone?: string;
	email?: string;
	website?: string;
	legal_form?: string;
	/** @format datetime */
	foundation_date?: string;
	/** @format datetime */
	active_since?: string;
	is_contract_exists?: string;
	type?: string;
	grade?: string;
	is_commission_ongoing?: string;
	commission_rate?: string;
	commission_notes?: string;
	is_enabled: boolean;
	/** @format datetime */
	created_at: string;
	config: PartnerConfig;
	bank_info: PartnerBankInfoResponse;
	address?: PartnerAddressResponse;
	crm_id?: string;
	crm_link?: string;
	contact_persons?: PartnerContactPersonResponse[];
}

export interface PartnerBankInfoCreateData {
	bank_name?: string;
	iban?: string;
	bic?: string;
}

export interface PartnerBankInfoResponse {
	partner_bank_info_id?: string;
	bank_name?: string;
	iban?: string;
	bic?: string;
	/** @format datetime */
	created_at?: string;
}

export interface PartnerAddressCreateData {
	street_line_1?: string;
	street_line_2?: string;
	city?: string;
	state_province_region?: string;
	postal_code?: string;
	country?: string;
}

export interface PartnerAddressResponse {
	partner_address_id?: string;
	street_line_1?: string;
	street_line_2?: string;
	city?: string;
	state_province_region?: string;
	postal_code?: string;
	country?: string;
	/** @format datetime */
	created_at?: string;
}

export interface PartnerContactPersonUpsertData {
	title: string;
	first_name: string;
	last_name: string;
	job_title?: string;
	mobile_number?: string;
	phone_number?: string;
	email?: string;
}

export interface PartnerContactPersonResponse {
	partner_contact_person_id: string;
	title: PersonTitle;
	first_name: string;
	last_name: string;
	job_title?: string;
	mobile_number?: string;
	phone_number?: string;
	email?: string;
	/** @format datetime */
	created_at: string;
}

export enum PartnerConfigType {
	Default = "default",
	Custom = "custom",
}

export enum FeatureValueItem {
	PortfolioConcentrationLimits = "portfolio_concentration_limits",
}

export interface FeatureValues {
	supported?: string[];
	preferred?: string[];
	default?: string;
	terms?: string;
	privacy_policy?: string;
	imprint?: string;
	legal_name?: string;
	display_name?: string;
	display_document_name?: string;
	template_format?: "template_format_de" | "template_format_ch";
	enabled?: boolean;
	fe_theme?: ThemeConfig;
	partner_id?: string;
	items?: FeatureValueItem[];
}

export interface FeatureRequest {
	name: FeatureName;
	description?: string;
	value: FeatureValues;
	is_public: boolean;
	is_active?: boolean;
}

export interface Feature {
	feature_id: number;
	name: FeatureName;
	description?: string;
	value: FeatureValues;
	is_active: boolean;
	is_deleted: boolean;
	is_public: boolean;
}

export interface SystemConfig {
	system_config_id: string;
	name: SystemConfigName;
	description?: string;
	value: SystemConfigValue;
	is_active: boolean;
	is_deleted: boolean;
	/** @format datetime */
	created_at: string;
	/** @format datetime */
	updated_at: string;
}

export type SystemConfigValue =
	| UntypedSystemConfigValue
	| FrontendURLsSystemConfigValue
	| EmailSenderSystemConfigValue
	| CompanySearchSystemConfigValue
	| ContactFormSystemConfigValue
	| DefaultPartnerSystemConfigValue;

export type UntypedSystemConfigValue = object;

export interface FrontendURLsSystemConfigValue {
	bo_app_url_base: string;
	partner_app_url_base: string;
	user_app_url_base: string;
}

export interface EmailSenderSystemConfigValue {
	email_from_address: string;
}

export interface CompanySearchSystemConfigValue {
	firmenwissen_username: string;
	firmenwissen_password: string;
	search_country_code: string;
}

export interface ContactFormSystemConfigValue {
	contact_form_email: string;
	from_email: string;
}

export interface DefaultPartnerSystemConfigValue {
	partner_id: string;
}

export interface FieldUI {
	is_multiline?: boolean;
	rows?: number;
	precision?: number;
	is_percent?: boolean;
	is_currency?: boolean;
	is_radio_button?: boolean;
	is_select?: boolean;
	is_checkbox?: boolean;
	placeholder?: string;
	addon_after?: string;
	default_value?: string | number | boolean;
	possible_values?: (string | number | boolean)[];
}

export interface FieldRules {
	is_required: boolean;
	max?: string | number;
	min?: string | number;
}

export interface FieldConfig {
	name: string;
	type: "string" | "boolean" | "integer" | "decimal";
	ui: FieldUI;
	rules: FieldRules;
}

export interface CustomFieldsConfig {
	custom_fields_config_id: string;
	fields: FieldConfig[];
	entity: "application" | "pricing";
	/** @format datetime */
	created_at: string;
}

export interface CustomFieldsConfigRequest {
	fields: FieldConfig[];
	entity: "application" | "pricing";
}

export interface EmailChannelConfig {
	address: string;
}

export type NotificationChannelConfig = EmailChannelConfig;

export interface NotificationChannel {
	notification_channel_id: string;
	channel_type: "email_custom" | "email_entity" | "chat";
	channel_provider: "mailgun" | "slack";
	config: NotificationChannelConfig;
	is_deleted: boolean;
	/** @format datetime */
	created_at: string;
}

export interface NotificationChannelRequest {
	channel_type: "email_custom" | "email_entity" | "chat";
	channel_provider: "mailgun" | "slack";
	config: NotificationChannelConfig;
}

export interface EmailTemplateConfig {
	from: string;
	subject: string;
	template_name: string;
	template_vars: string[];
}

export type NotificationTemplateConfigForLanguage = EmailTemplateConfig;

/**
 * map where the key is a language and the value is the template config for that language
 */
export type NotificationTemplateConfig = Record<string, NotificationTemplateConfigForLanguage>;

export interface NotificationTemplate {
	notification_template_id: string;
	/** map where the key is a language and the value is the template config for that language */
	config: NotificationTemplateConfig;
	default_language: string;
	is_deleted: boolean;
	/** @format datetime */
	created_at: string;
}

export interface NotificationTemplateRequest {
	/** map where the key is a language and the value is the template config for that language */
	config: NotificationTemplateConfig;
	default_language: string;
}

export interface FilterRule {
	op: "and" | "or" | "eq" | "neq" | "gt" | "gte" | "lt" | "lte" | "in" | "not_in";
	left: string | FilterRule;
	right: string | number | string[] | FilterRule;
}

export interface NotificationSubscription {
	notification_subscription_id: string;
	entity_type: "user" | "bouser" | "puser" | "system";
	entity_id?: string;
	event_type: "application.created";
	notification_channel_id: string;
	notification_template_id: string;
	filter_rule?: FilterRule;
	is_deleted: boolean;
	/** @format datetime */
	created_at: string;
}

export interface NotificationSubscriptionRequest {
	entity_type: "user" | "bouser" | "puser" | "system";
	entity_id?: string;
	event_type: "application.created";
	notification_channel_id: string;
	notification_template_id: string;
	filter_rule?: FilterRule;
}

export interface AdminConfigResponse {
	fe_theme_admin: ThemeConfig;
}

export interface ConfigResponse {
	partner_config?: PartnerConfig;
	public_features: Feature[];
}

/**
 * map where the key is an allBanksLoanPurpose and the value is the custom fields config ID
 * @example {"purpose_akf_bike":"cfc_123"}
 */
export type AllBanksLoanPurposeToCustomFieldsConfigID = Record<string, string>;

export interface PartnerConfigRequest {
	id: string;
	partner_id: string;
	fe_theme: ThemeConfig;
	parameters: PartnerConfigParameters;
	terms_url: string;
	is_partner_terms_required: boolean;
	data_protection_url: string;
	entitlements?: EntitlementType[];
	application_types: AllBanksLoanPurposes[];
	user_application_types: AllBanksLoanPurposes[];
	custom_fields?: {
		/** map where the key is an allBanksLoanPurpose and the value is the custom fields config ID */
		application?: AllBanksLoanPurposeToCustomFieldsConfigID;
		/** map where the key is an allBanksLoanPurpose and the value is the custom fields config ID */
		pricing?: AllBanksLoanPurposeToCustomFieldsConfigID;
	};
	locale: string;
}

/**
 * map where the key is an allBanksLoanPurpose and the value is the custom fields config
 */
export type AllBanksLoanPurposeToCustomFieldsConfig = Record<string, CustomFieldsConfig>;

export interface PartnerConfig {
	id: string;
	partner_id: string;
	fe_theme: ThemeConfig;
	parameters: PartnerConfigParameters;
	terms_url: string;
	is_partner_terms_required: boolean;
	data_protection_url: string;
	entitlements?: EntitlementType[];
	application_types: AllBanksLoanPurposes[];
	user_application_types: AllBanksLoanPurposes[];
	custom_fields_configs?: CustomFieldsConfigs;
	locale: string;
}

export type PartnerConfigWithSelectors = UtilRequiredKeys<
	PartnerConfig,
	"id" | "partner_id" | "parameters"
> & {
	default_product_selectors: PartnerDefaultProductSelector[];
};

export interface CustomFieldsConfigs {
	/** map where the key is an allBanksLoanPurpose and the value is the custom fields config */
	application?: AllBanksLoanPurposeToCustomFieldsConfig;
	/** map where the key is an allBanksLoanPurpose and the value is the custom fields config */
	pricing?: AllBanksLoanPurposeToCustomFieldsConfig;
}

export interface ThemeConfig {
	main_menu_bg_color?: string;
	main_menu_color?: string;
	color_link?: string;
	color_primary?: string;
	color_cta_text?: string;
	logo_web_path?: string;
	logo_collapsed_web_path?: string;
}

export interface PartnerConfigParameters {
	customer_support_email: string;
	customer_support_phone: string;
	display_name: string;
	legal_name: string;
}

export interface PartnerDefaultProductSelector {
	product_default_selection_id: string;
	product_id: string;
	partner_id: string;
	condition: string;
	weight: number;
}

export interface PartnerDefaultProductSelectorRequest {
	product_id: string;
	condition: string;
	weight?: number;
}

export interface DebtNote {
	debt_note_id: string;
	name: string;
}

export interface ExternalOffer {
	external_offer_id?: string;
	external_id?: string;
	application_id?: string;
	status?: ExternalOfferStatusEnum;
	rejection_reason?: string;
	/** @format datetime */
	updated_at?: string;
	/** @format datetime */
	created_at?: string;
}

export enum ExternalOfferStatusEnum {
	CREATED = "CREATED",
	REJECTED = "REJECTED",
	RISK_CHECK = "RISK_CHECK",
	OFFER = "OFFER",
	ABANDONED = "ABANDONED",
	ACTIVATED = "ACTIVATED",
	VALIDATION_ERROR = "VALIDATION_ERROR",
}

export interface Application {
	application: UserApplication;
}

export interface ApplicationParametersResponse {
	application_id: string;
	loan_amount: string;
	loan_duration: number;
	representative_monthly_rate_low?: string;
	representative_monthly_rate_high?: string;
}

export interface ApplicationCustomFieldsValues {
	is_public_tender?: boolean;
	application_comment?: string;
	/** @format float */
	employer_rate_subsidy?: string;
	/** @format float */
	employer_service_subsidy?: string;
	max_bikes_per_employee?: number;
	/** @format float */
	price_limit_bike?: string;
	number_of_employees?: number;
}

export interface ApplicationCustomFieldsData {
	custom_fields_config_id: string;
	values: ApplicationCustomFieldsValues;
}

export interface PartnerApplication {
	application_id: string;
	short_id: string;
	company_id: string;
	email: string;
	company_name: string;
	status: ApplicationStatuses;
	loan_provider: LoanProviders;
	amount: string;
	currency: Currency;
	duration: number;
	status_owner_id?: string;
	status_owner_email?: string;
	status_owner_name?: string;
	account_manager_id?: string;
	account_manager_email?: string;
	account_manager_name?: string;
	product: Product;
	partner_id: string;
	created_at: string;
	pricing: Pricing;
	iban?: string;
	bankName?: string;
	companyType: CompanyTypes;
	hrNumber?: string;
	street?: string;
	houseNumber?: string;
	companyPostCode?: string;
	companyLocation?: string;
	companyCountry?: CountryCode;
	companyFoundationDate: string;
	purposeDescription?: string;
	allBanksLoanPurpose?: AllBanksLoanPurposes;
	taxIdNumber?: string;
	businessPurpose?: string;
	termsAndConditionsAcceptedTimestamp?: number;
	applicationId: string;
	shortApplicationId?: string;
	loanSize?: number;
	loanDuration?: number;
	companyName?: string;
	companyId?: string;
	user_email?: string;
	language?: string;
	possible_states: ApplicationStatuses[];
	status_flow: StatusFlowItem[];
	finance_objects?: FinanceObjectGetResponse[];
	country?: string;
	document_configurations: DocumentConfiguration;
	custom_fields_data?: ApplicationCustomFieldsData;
	/** used for the POST applications request */
	custom_fields?: ApplicationCustomFieldsValues;
	/** used for the POST applications request */
	leasing?: Leasing;
	companyCreditProviderId?: string;
}

export interface UserApplication {
	applicationId: string;
	allFilesUploaded?: boolean;
	allDocsSubmitted: boolean;
	allBanksLoanPurpose?: AllBanksLoanPurposes;
	bankName?: string;
	businessPurpose?: string;
	companyCountry: CountryCode;
	companyId: string;
	company_id_short?: string;
	companyLocation: string;
	companyFoundationDate?: string;
	companyName: string;
	companyPostCode: string;
	companyType: CompanyTypes;
	/** @example 2021-04-07T09:43:37.765Z */
	created?: string;
	houseNumber?: string;
	hrNumber: string;
	iban?: string;
	/** duration in months */
	loanDuration: number;
	currency: Currency;
	loanPurpose?: string;
	loanPurposeNumberOfMachines?: number;
	loanPurposePriceOfMachines?: number;
	/** @example 200000.00 */
	loanSize: string;
	pricing: Pricing;
	purposeDescription?: string;
	rate: string;
	representative_monthly_rate_low?: string;
	representative_monthly_rate_high?: string;
	monthlyRate: string;
	sepaMandatAccepted?: boolean;
	shortApplicationId: string;
	status: ApplicationStatuses;
	street?: string;
	taxIdNumber: string;
	language?: string;
	possible_states: ApplicationStatuses[];
	status_flow: StatusFlowItem[];
	finance_objects?: FinanceObjectGetResponse[];
	country?: string;
	custom_fields_data?: ApplicationCustomFieldsData;
	document_configurations: DocumentConfiguration;
}

export interface UserApplicationsResponse {
	pagination?: LegacyPagination;
	applications?: UserApplication[];
}

export interface IdentificationStatus {
	application_id?: string;
	signatory_id?: string;
	is_complete?: boolean;
	ident?: string;
	company_name?: string;
	signatory_name?: string;
	provider_status?:
		| "SUCCESS"
		| "SUCCESS_DATA_CHANGED"
		| "FRAUD_SUSPICION_CONFIRMED"
		| "IDENT_EXPIRED"
		| "ABORTED"
		| "REVIEW_PENDING"
		| "FRAUD_SUSPICION_PENDING";
	/** @format datetime */
	created_at?: string;
}

export interface SignatoryIdentStatus {
	transaction_id?: string;
	signatory_id?: string;
	ident_type?: IdentType;
	provider?: SignatureProvider;
	provider_transaction_id?: string;
	provider_status?: "SUCCESS SUCCESS_DATA_CHANGED FRAUD_SUSPICION_CONFIRMED ABORTED";
	/** @format datetime */
	provider_status_updated_at?: string;
	/** @format datetime */
	created_at?: string;
}

export enum OcrFilePeriodType {
	Interim = "interim",
	FullYear = "full_year",
}

export enum OcrFileDocumentType {
	AnnualStatement = "annual_statement",
	DeBwaSusa = "de_bwa_susa",
}

export interface OcrFileCreate {
	document_id: string;
	document_type: OcrFileDocumentType;
	period_type: OcrFilePeriodType;
	/** @format date */
	financials_date: string;
}

export interface OcrFileUpdateInfo {
	period_type?: OcrFilePeriodType;
	/** @format date */
	financials_date: string;
}

export interface OcrFileResponse {
	document_id: string;
	document_type: OcrFileDocumentType;
	period_type?: OcrFilePeriodType;
	/** @format date */
	financials_date?: string;
	status: "request_failed" | "upload_pending" | "scanning" | "completed" | "error";
	fincr_file_id: string;
}

export interface OcrFileResults {
	document_type: OcrFileDocumentType;
	/** @example de */
	country: string;
	/** @example de */
	language: string;
	document_data: OcrDocumentData;
}

export interface OcrDocumentData {
	sections: OcrSection[];
	warnings: OcrWarning[];
}

export interface OcrWarning {
	level: "error" | "warning";
	type: "calculation_mismatch" | "sign_mismatch" | "assets_and_liabilities_mismatch";
	code: string;
	message: string;
}

export interface OcrSection {
	type: string;
	fin_type: string;
	description?: string;
	account_number?: string;
	subsections?: OcrSection[];
	total_previous_period?: string;
	total_current_period?: string;
	previous_period_percent?: string;
	current_period_percent?: string;
	standard_account_framework?: string;
	standard_account_framework_check_id?: string;
	subaccount_number?: string;
	value_beginning_of_year?: OcrSusaDebitAndCreditValue;
	bookings_monthly_or_period?: OcrMonthlyOrPeriodBookingsData[];
	bookings_year_to_date?: OcrSusaDebitAndCreditValue;
	value_final?: OcrSusaDebitAndCreditValue;
	unclassified_columns?: OcrUnclassifiedColumn[];
	lines?: OcrLine[];
}

export interface OcrSusaDebitAndCreditValue {
	debit?: string;
	credit?: string;
}

export interface OcrMonthlyOrPeriodBookingsData {
	id: string;
	period: string;
	debit?: string;
	credit?: string;
}

export interface OcrUnclassifiedColumn {
	id: string;
	header: string;
	value: string;
}

export interface OcrLine {
	line_type: string;
	fin_type: string;
	columns: OcrColumn[];
}

export interface OcrColumn {
	text: string;
	column_type: string;
}

export interface FilterValuesPartners {
	partner_id?: string;
	partner_name?: string;
}

export interface FilterValuesProducts {
	product_id?: string;
	product_name?: string;
}

export enum LoanProviders {
	ING = "ING",
	SocieteGenerale = "Societe_Generale",
	SolarisBank = "Solaris_Bank",
	KFW = "KFW",
	EMPTY = "EMPTY",
	TEYLOR = "TEYLOR",
}

export enum ApplicationStatuses {
	PreCheck = "pre_check",
	Open = "Open",
	Signature = "Signature",
	InternalReview = "Internal_Review",
	InternalPricing = "internal_pricing",
	ExternalPricing = "external_pricing",
	FinalOffer = "Final_Offer",
	Reopened = "Reopened",
	Pricing = "Pricing",
	Submitted = "Submitted",
	AwaitingPayout = "Awaiting_Payout",
	FinalReview = "Final_Review",
	Approved = "Approved",
	Rejected = "Rejected",
	PaidOut = "Paid_Out",
	BankOpen = "Bank_Open",
	BankSubmitted = "bank_submitted",
	OfferAvailable = "offer_available",
	OfferAccepted = "offer_accepted",
	SalesPreCheck = "sales_pre_check",
}

export enum FeatureName {
	InterfaceLanguages = "interface_languages",
	CommunicationLanguages = "communication_languages",
	UserLocaleRegion = "user_locale_region",
	Currencies = "currencies",
	UrlList = "url_list",
	CompanyNames = "company_names",
	ApplicationLanguages = "application_languages",
	CompanyTypes = "company_types",
	ApplicationCountries = "application_countries",
	Websockets = "websockets",
	Documents = "documents",
	Ocr = "ocr",
	MaxCreditScore = "max_credit_score",
	Compartments = "compartments",
	BoUserCreateApplication = "bo_user_create_application",
	PartnerUserCreateApplication = "partner_user_create_application",
	UserCreateApplication = "user_create_application",
	FeThemePartner = "fe_theme_partner",
	DefaultPartner = "default_partner",
	BoUserExportData = "bo_user_export_data",
	GlobalRateNames = "global_rate_names",
	GlobalRatePeriods = "global_rate_periods",
	BoPepVisibility = "bo_pep_visibility",
	Tasks = "tasks",
	PortfolioMonitoring = "portfolio_monitoring",
	PartnerSabreCompanyFinancials = "partner_sabre_company_financials",
	PartnerSabreCompanyDocuments = "partner_sabre_company_documents",
	PartnerSabreCompanyScoring = "partner_sabre_company_scoring",
}

export enum SystemConfigName {
	CreditReport = "credit_report",
	Ident = "ident",
	Document = "document",
	PaymentEvent = "payment_event",
	Notification = "notification",
	EmailSender = "email_sender",
	PaymentProvider = "payment_provider",
	CrmZoho = "crm_zoho",
	FrontendAppUrls = "frontend_app_urls",
	DefaultPartner = "default_partner",
	DefaultTenant = "default_tenant",
	CompanySearch = "company_search",
	ContactForm = "contact_form",
}

export interface StatusFlowItem {
	status_name: ApplicationStatuses;
	is_current_status: boolean;
}

export interface LegacyPagination {
	currentPage?: string;
	itemsPerPage?: string;
	numberOfRows?: string;
}

export interface Pagination {
	total_rows: number;
	current_page: number;
	items_per_page: number;
}

export interface CreationIDResponse {
	new_id: string;
}

export interface ScoringFeedback {
	/** min length 200 characters */
	scoring_feedback: string;
	flags_reviewed: boolean;
}

export enum ChecklistType {
	Application = "application",
	FourEyeCheck = "four_eye_check",
	Payout = "payout",
	TeylorApplicationDocuments = "teylor_application_documents",
}

export interface ChecklistStatus {
	checklist_type?: ChecklistType;
	is_done?: boolean;
}

export enum LoanStatus {
	Open = "open",
	Delay = "delay",
	Default = "default",
	Repaid = "repaid",
	Pending = "pending",
}

export interface ChecklistTemplateResponse {
	id?: number;
	version?: number;
	type?: ChecklistType;
	checklist_template?: ChecklistTemplate;
}

export interface ApplicationChecklistResponse {
	application_checklist_id: number;
	checklist_id: number;
	application_id: string;
	is_done: boolean;
	checklist_data: ChecklistTemplate;
}

/**
 * map where key is id of the item and value is selected state or text
 */
export type ChecklistUpdateMap = Record<string, any>;

export interface Checklist {
	checklist_id?: number;
	application_id?: string;
	checklist_data?: ChecklistTemplate;
	application_checklist_id?: number;
}

export interface ChecklistTemplate {
	checklist_meta: {
		version: string;
		funding_source: string;
	};
	groups: ChecklistGroupTemplate[];
}

export interface ChecklistGroupTemplate {
	id: string;
	category: string;
	description: string;
	items: ChecklistItemTemplate[];
}

export interface ChecklistItemTemplate {
	id: string;
	name: string;
	item_type: "text" | "select" | "checkbox";
	text: string;
	description: string;
	not_applicable_allowed?: boolean;
	not_applicable?: boolean;
	requirements?: ChecklistItemRequirementsTemplate;
	options: ChecklistItemOptions[];
}

export interface ChecklistItemOptions {
	id: string;
	name: string;
	description: string;
	selected: boolean;
	type: "black" | "grey" | "white";
	requirements?: ChecklistItemRequirementsTemplate;
}

export interface IncomingApplicationPricing {
	interest_rate?: number;
	transaction_fee?: number;
	external_interest_rate?: number;
	external_transaction_fee?: number;
	prepayment_rate?: number;
	hedging_rate?: number;
}

export interface ChecklistItemRequirementsTemplate {
	min_length?: number;
}

export interface AdminPricing {
	interest_rate: string;
	external_interest_rate: string;
	external_transaction_fee: string;
	grid_rate?: string;
	hedging_rate?: string;
	minimum_interest_rate?: string;
	total_interest_rate?: string;
	total_transaction_fee?: string;
	transaction_fee: string;
	monthly_payment: string;
	prepayment_rate?: string;
	is_final_interest_rate: boolean;
	is_final_transaction_fee: boolean;
	leasing_factor?: string;
	service_rate?: string;
	remaining_value?: string;
	insurance_rate?: string;
}

export interface SignatoryRequirementsUserRequest {
	signatory_id?: string;
	application_id?: string;
	sepa_mandate_accepted?: boolean;
	legal_signature_accepted?: boolean;
}

export interface SignatoryRequirementsAdminRequest {
	signatory_id: string;
	application_id: string;
	is_loan_signature_required: boolean;
	is_ubo_signature_required: boolean;
	is_video_id_required: boolean;
	guarantor_contract_url: string;
	guarantor_contract_filename: string;
	digital_signature_url: string;
}

export interface SignatoryIdentManualCompletionAdminRequest {
	ident_type: IdentType;
}

export type SignatoryRequirements = (SignatoryRequirementsAdminRequest &
	SignatoryRequirementsUserRequest &
	SignatureStatusResponseForBOUsers &
	SignatureStatusResponseForUsers) & {
	is_signature_complete?: boolean;
	/** @format datetime */
	signature_completed_at?: string;
	signature_results_url?: string;
	/** @format datetime */
	sepa_mandate_accepted_at?: string;
	/** @format datetime */
	legal_signature_accepted_at?: string;
};

export interface SignatureStatusResponseForBOUsers {
	signature_internal_status?: SignatureInternalStatus;
	signature_user_status?: SignatureUserFacingStatus;
	signature_provider_status?: string;
	signature_provider?: SignatureProvider;
}

export interface SignatureStatusResponseForUsers {
	signature_status?: SignatureUserFacingStatus;
}

export enum SignatureInternalStatus {
	NotStarted = "not_started",
	InProgress = "in_progress",
	Completed = "completed",
	UnderReview = "under_review",
	Canceled = "canceled",
	Failed = "failed",
	Expired = "expired",
}

export enum SignatureUserFacingStatus {
	Completed = "completed",
	NotCompleted = "not_completed",
	Failed = "failed",
	InProgress = "in_progress",
}

export enum SignatureProvider {
	Idnow = "idnow",
	SwissSign = "swiss_sign",
	Postident = "postident",
	Manual = "manual",
}

export enum IdentType {
	Ident = "ident",
	Esign = "esign",
}

export interface Signatory {
	/** @example Jonh */
	first_name: string;
	/** @example Snow */
	last_name: string;
	/** @example DE */
	country: string;
	/** @example Berlin */
	location: string;
	street: string;
	street_number: string;
	postcode: string;
	telephone: string;
	email?: string;
	/** @example F */
	gender?: string;
	birthdate: string;
	/**
	 * 2 characters country code (GB, DE, US, ....)
	 * @example US
	 */
	nationality?: string;
	taxid: string;
	percentage_owned: string;
	application_id: string;
	is_beneficial_owner: boolean;
	is_legal_signatory: boolean;
	is_sole_signatory: boolean;
	is_contract_signatory: boolean;
	invite_status?: InviteStatus;
	/**
	 * @format datetime
	 * @example "2022-08-24T09:57:18.000Z"
	 */
	invite_status_updated_at?: string;
	requirements?: SignatoryRequirements;
}

export type SignatoryResponse = UtilRequiredKeys<SignatoryWithId, "signatory_id"> & {
	credit_score?: number;
	credit_score_info_text?: string;
	credit_score_provider?: string;
	credit_score_updated_at?: string;
	risk_class_type?: string;
	risk_class_value?: string;
	requirements?: SignatoryRequirements;
	kyc_status?: SignatoryKycStatus;
	pep_check_result?: PepCheckResult;
};

export type SignatoryWithId = Signatory & {
	signatory_id: string;
};

export type SignatoriesRequest = Signatory[];

export interface SignatoriesResponse {
	signatories: SignatoryResponse[];
	warnings: {
		warning_id: string;
		text_en: string;
		resource_id: string;
	}[];
}

export interface PepCheckResult {
	signatory_pep_check_id: string;
	signatory_id: string;
	approved_by?: string;
	/** @format date-time */
	approved_at?: string;
	pep_provider: string;
	is_result_positive: boolean;
	raw_result: object;
	/** @format date-time */
	created_at: string;
	is_deleted: boolean;
}

export interface Loan {
	/** @example ln_abcdef */
	loan_id: string;
	/** @example app_134def */
	application_id: string;
	loan_status: LoanStatus;
	/** @example 12345abcdf */
	loan_id_short: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount: string;
	currency?: Currency;
	/** @example de */
	language?: string;
	/** @example DE */
	country?: string;
	/** @example 48 */
	duration_months: number;
	/**
	 * @format float
	 * @example 5.52
	 */
	interest_rate: string;
	/** @format date */
	payout_date: string;
	/** @format date */
	due_date: string;
	/** @format date-time */
	created_at: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_total: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_interest: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	begin_balance_fees: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_total: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_interest: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_balance_fees: string;
	is_monitored: boolean;
	monitor_details: string;
	is_review_required: boolean;
	company: Company;
	payment_plan: PaymentPlan;
	debt_note_id?: string;
	debt_note_name?: string;
	external_reference: string;
	review_details: string;
	fronting_bank_iban?: string;
	product?: ProductResponse;
	compartment_id?: string;
	prepayment_allowed: boolean;
	is_eligible?: boolean;
	/**
	 * @format float
	 * @example 5.52
	 */
	actual_swap_rate?: string;
	eligibility_assessment_allowed: boolean;
}

export interface PaymentPlan {
	amount_total?: string;
	payment_plan_id: string;
	application_id: string;
	loan_id: string;
	interest_total?: string;
	payment?: string;
	installments: PaymentPlanInstallment[];
}

export interface Company {
	companyId: string;
	company_id_short: string;
	companyName: string;
	companyType: CompanyTypes;
	email: string;
	street: string;
	houseNumber?: string;
	companyLocation: string;
	companyPostCode: string;
	companyCountry: CountryCode;
	numberOfEmployees?: number;
	creditScore?: number;
	brancheCode?: string;
	hrNumber?: string;
	companyFoundationDate?: string;
	taxIdNumber?: string;
	businessPurpose?: string;
	crm_id?: string;
	creditreformShareCapital?: number;
	creditreformNumberOfShareholders?: number;
	shareHolderOneSharesValue?: number;
	shareHolderOneSharesPercentage?: number;
	shareHolderTwoSharesValue?: number;
	shareHolderTwoSharesPercentage?: number;
	shareHolderThreeSharesValue?: number;
	shareHolderThreeSharesPercentage?: number;
}

export interface PaymentPlanInstallment {
	installment_id: string;
	payment_plan_id: string;
	installment_index: number;
	is_paid: boolean;
	/** @format date */
	due_date: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount?: string;
	/** @example scheduled */
	status: InstallmentStatuses;
	currency: Currency;
	payment_reference: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	repaid_total?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_interest?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_total?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_principal?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	outstanding_interest?: string;
	actions: InstallmentActions[];
}

export enum InstallmentStatuses {
	Scheduled = "scheduled",
	PaymentPending = "payment_pending",
	Paid = "paid",
	Canceled = "canceled",
	Error = "error",
}

export enum InstallmentActions {
	ManualPayment = "manual_payment",
	RequestPayment = "request_payment",
	ReconcilePayment = "reconcile_payment",
	ViewPayment = "view_payment",
}

export interface LoanTransactionsResponse {
	transactions?: LoanTransaction[];
}

export interface LoanTransaction {
	transaction_id: string;
	loan_id: string;
	transaction_type: LoanTransactionType;
	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr: string;
	/** @format date */
	booking_date: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_total: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_interest: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount_fees: string;
	currency: Currency;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_total: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_interest: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	beg_bal_fees: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_total: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_principal: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_interest: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	end_bal_fees: string;
	description: string;
	bank_transaction_id?: string;
	/** @format date-time */
	created_at: string;
}

export interface UserProfile {
	/** @example usr_1rnby4knzmkTO56VsWyfeP6bsBk */
	user_id: string;
	/** @example a1b2c3d4ef */
	user_id_short?: string;
	/** @example user@teylor.com */
	email: string;
	name: string;
	is_enabled: boolean;
	/** @format datetime */
	created?: string;
	role: string;
	financialAdvisor: boolean;
	hasAcceptedTermsAndConditions: boolean;
	accepted_partner_terms?: boolean;
	mainContactNumber: string;
	emailVerified: boolean;
	guest: boolean;
	testUser: boolean;
	solarisBankOnly: boolean;
	/** @example de */
	interface_language: string;
	/** @example de */
	communication_language: string;
	region: string;
}

export interface UserProfileList {
	pagination: Pagination;
	users: UserProfile[];
}

export interface UserProfileUpdate {
	/** @example de */
	interface_language?: string;
	/** @example de */
	communication_language?: string;
	/** @example DE */
	region?: string;
}

export interface PartnerUser {
	entity_id: string;
	partner_id?: string;
	status: PartnerUserStatus;
	email?: string;
	name?: string;
	mobile_number?: string;
	role_id?: PartnerUserRole;
	role_name?: string;
	is_email_confirmed: boolean;
	is_enabled?: boolean;
	is_deleted?: boolean;
	/** @format datetime */
	created?: string;
	/** @example de */
	interface_language?: string;
}

export interface ProductStats {
	product_name?: string;
	count?: number;
	sum_of_loans?: number;
}

export interface StatusStats {
	status?: string;
	count?: number;
	sum_of_loans?: number;
	children?: ProductStats;
}

export interface PipelineStats {
	count?: number;
	sum_of_loans?: number;
	children?: StatusStats;
}

export interface BOUserProfile {
	/** @example bou_1rnby4knzmkTO56VsWyfeP6bsBk */
	id: string;
	/** @example admin@teylor.com */
	email: string;
	/** @example true */
	is_mfa_enabled: boolean;
	/** @example ["application","bouser","loan","checklist"] */
	features: BOUserFeature[];
	next_login_step?: NextLoginStep;
	/** @example de */
	interface_language: string;
	name: string;
}

export interface BOUserRole {
	/** @example role_system_admin */
	id?: string;
	/** @example System administrator */
	name?: string;
	/** @example can do anything they want */
	description?: string;
	permissions?: AuthPermission[];
}

export interface AuthPermission {
	/** @example pm_1qvxlxV7Td8J05ro2vY8QLbEHTj */
	id?: string;
	/** @example application */
	resource?: string;
	/** @example read */
	action?: string;
	description?: string;
}

export interface DocumentTemplate {
	document_template_id?: string;
	version?: number;
	document_type?:
		| "teylor_capital_loan_contract"
		| "teylor_capital_guarantor_contract"
		| "teylor_capital_payment_confirmation"
		| "teylor_capital_payment_plan"
		| "teylor_capital_gtc"
		| "teylor_capital_order_form"
		| "teylor_capital_ubo_form";
	product_id?: string;
	/** @format datetime */
	created?: string;
	aws_id?: string;
	language?: string;
	country?: string;
}

export enum ErrorCode {
	EntityNotFound = "entity_not_found",
	NoSignatoryRequirements = "no_signatory_requirements",
	ErrApplicationPayoutChecklistNotDone = "err_application_payout_checklist_not_done",
	ErrApplicationFourEyesChecklistNotDone = "err_application_four_eyes_checklist_not_done",
	ErrApplicationNoBankName = "err_application_no_bank_name",
	ErrApplicationNoIban = "err_application_no_iban",
	ErrApplicationNoSignatories = "err_application_no_signatories",
	ErrApplicationNoSignatoriesSignLoanContract = "err_application_no_signatories_sign_loan_contract",
	ErrApplicationNoTaxidForSignatory = "err_application_no_taxid_for_signatory",
	ErrApplicationNoLoanContract = "err_application_no_loan_contract",
	ErrApplicationInternalError = "err_application_internal_error",
	ErrApplicationNoSignatoryContract = "err_application_no_signatory_contract",
	ErrInsufficientSignatoriesSignLoanContract = "err_insufficient_signatories_sign_loan_contract",
	ErrApplicationSignatoriesSoleOrAtleast2 = "err_application_signatories_sole_or_atleast_2",
	ErrApplicationNoSignatoryIdentUrl = "err_application_no_signatory_ident_url",
	ErrApplicationNoPricing = "err_application_no_pricing",
	ErrSharePercentageGreaterThen100 = "err_share_percentage_greater_then_100",
	ErrApplicationSignatoryIdentNotDone = "err_application_signatory_ident_not_done",
	ErrApplicationMissingCompany = "err_application_missing_company",
	ErrApplicationCompanyTaxidNotSpecified = "err_application_company_taxid_not_specified",
	ErrApplicationUserIsTeylorEmployee = "err_application_user_is_teylor_employee",
	ErrMissingScoringData = "err_missing_scoring_data",
	ErrMissingScoringConfig = "err_missing_scoring_config",
	ErrLowScoringMark = "err_low_scoring_mark",
	ErrTooManyRedFlags = "err_too_many_red_flags",
	ErrTooManyWarnings = "err_too_many_warnings",
	ErrCrefoCompanySearchFailed = "err_crefo_company_search_failed",
	ErrChecklistBadScore = "err_checklist_bad_score",
	ErrChecklistNotExist = "err_checklist_not_exist",
	ErrChecklistNotDone = "err_checklist_not_done",
	ErrFinancialsNotExist = "err_financials_not_exist",
	ErrCompanyCountryNotAllowed = "err_company_country_not_allowed",
	ErrCompanyTypeNotAllowed = "err_company_type_not_allowed",
	ErrCompanyCreditScoreNotAllowed = "err_company_credit_score_not_allowed",
	ErrCompanyCreditScoreTooHigh = "err_company_credit_score_too_high",
	ErrCompanyCreditScoreTooLow = "err_company_credit_score_too_low",
	ErrApplicationRoleInvalid = "err_application_role_invalid",
	ErrStatusChangeNotAllowed = "err_status_change_not_allowed",
	ErrScoringFeedbackNotDone = "err_scoring_feedback_not_done",
	ErrCurrencyNotAllowed = "err_currency_not_allowed",
	ErrApplicationNoExternalPricing = "err_application_no_external_pricing",
	ErrDocumentAnnualStatmentMissing = "err_document_annual_statment_missing",
	ErrDocumentBwaSusaMissing = "err_document_bwa_susa_missing",
	ErrDocumentDebtRegistryMissing = "err_document_debt_registry_missing",
	ErrDocumentGuarantorConractMissing = "err_document_guarantor_conract_missing",
	ErrSepaMandateNotAccepted = "err_sepa_mandate_not_accepted",
	ErrRepaymentPlanNotGenerated = "err_repayment_plan_not_generated",
	ErrLoanStatusNotPending = "err_loan_status_not_pending",
	ErrLoanCannotChangeLoanStatus = "err_loan_cannot_change_loan_status",
	ErrLoanCannotChangePayoutDate = "err_loan_cannot_change_payout_date",
	ErrLoanDebtNoteAssignedToOneOrMoreLoans = "err_loan_debt_note_assigned_to_one_or_more_loans",
	ErrLoanDebtNoteNameIsEmpty = "err_loan_debt_note_name_is_empty",
	ErrLoanEntityNotFound = "err_loan_entity_not_found",
	PaymentCurrencyDiffersFromInstallmentCurrency = "payment_currency_differs_from_installment_currency",
	PaymentInstallmentIDDiffersFromInstallmentID = "payment_installment_ID_differs_from_installment_ID",
	PaymentAmountDiffersFromInstallmentAmount = "payment_amount_differs_from_installment_amount",
	PaymentStatusIsNotCorrectForReconciling = "payment_status_is_not_correct_for_reconciling",
	InstallmentAlreadyHasPaymentTransactions = "installment_already_has_payment_transactions",
	InstallmentHasWrongStatusForApplyingAPayment = "installment_has_wrong_status_for_applying_a_payment",
	InstallmentHasWrongStatusForRequestingAPayment = "installment_has_wrong_status_for_requesting_a_payment",
	ErrApiDeprectated = "err_api_deprectated",
	ErrLoanMustBePositive = "err_loan_must_be_positive",
	ErrUploadForbiddenForPendingLoan = "err_upload_forbidden_for_pending_loan",
	ErrInvalidProviderType = "err_invalid_provider_type",
	ErrProviderNotConnected = "err_provider_not_connected",
	ErrIbanOrBicNotFound = "err_iban_or_bic_not_found",
	ErrSignatoriesNotSignLoanContract = "err_signatories_not_sign_loan_contract",
	ErrProviderMangopayFailed = "err_provider_mangopay_failed",
	ErrApplicationStatusOwnerDuplicate = "err_application_status_owner_duplicate",
	ErrPercentageOwnedValueInvalid = "err_percentage_owned_value_invalid",
	ErrPricingInterestRateMustBeSet = "err_pricing_interest_rate_must_be_set",
	ErrPricingInterestRateMustBePositive = "err_pricing_interest_rate_must_be_positive",
	ErrPricingInterestRateIsHigherThanAllowed = "err_pricing_interest_rate_is_higher_than_allowed",
	ErrPricingInterestRateIsLowerThanAllowed = "err_pricing_interest_rate_is_lower_than_allowed",
	ErrPricingChangeNotAllowed = "err_pricing_change_not_allowed",
	ErrPricingNoScoringDataAvailable = "err_pricing_no_scoring_data_available",
	ErrPricingTransactionFeeMustBePositive = "err_pricing_transaction_fee_must_be_positive",
	ErrAuthEntityNotFound = "err_auth_entity_not_found",
	ErrAuthEntityIsDisabled = "err_auth_entity_is_disabled",
	ErrAuthEntityIsDeleted = "err_auth_entity_is_deleted",
	ErrAuthInvalidCredentials = "err_auth_invalid_credentials",
	ErrAuthSessionNotFound = "err_auth_session_not_found",
	ErrAuthSessionExpired = "err_auth_session_expired",
	ErrAuthTokenUsed = "err_auth_token_used",
	ErrAuthTokenExpired = "err_auth_token_expired",
	ErrAuthTokenInvalid = "err_auth_token_invalid",
	ErrAuthRoleNotFound = "err_auth_role_not_found",
	ErrAuthEmailNotYetConfirmed = "err_auth_email_not_yet_confirmed",
	ErrAuthEmailConflict = "err_auth_email_conflict",
	ErrAuthPasswordTooShort = "err_auth_password_too_short",
	ErrAuthPasswordTooLong = "err_auth_password_too_long",
	ErrAuthPasswordTooSimple = "err_auth_password_too_simple",
	ErrAuthEntityIsLocked = "err_auth_entity_is_locked",
	ErrBankTxStatusNotCorrectForReconciling = "err_bank_tx_status_not_correct_for_reconciling",
	ErrPaymentProviderMangopayConfirmationFailed = "err_payment_provider_mangopay_confirmation_failed",
	ErrPaymentProviderUnknown = "err_payment_provider_unknown",
	ErrBouserEmailAlreadyExists = "err_bouser_email_already_exists",
	ErrBouserEmailProvider = "err_bouser_email_provider",
	ErrIdnowInvalidIdentType = "err_idnow_invalid_ident_type",
	ErrPaymentPlanNotExist = "err_payment_plan_not_exist",
	ErrPaymentPlanLoanStatusMustBeOpenOrPending = "err_payment_plan_loan_status_must_be_open_or_pending",
	ErrPaymentPlanApplicationStatusMustBeAwaitingPayout = "err_payment_plan_application_status_must_be_awaiting_payout",
	ErrLocaleNotFound = "err_locale_not_found",
	ErrApplicationLanguageNotSet = "err_application_language_not_set",
	ErrLanguageNotSupported = "err_language_not_supported",
	ErrIndividualNotFound = "err_individual_not_found",
	ErrChooseNonDefaultProduct = "err_choose_non_default_product",
	ErrIndustryCodeNotAllowed = "err_industry_code_not_allowed",
	ErrIndustryCodeNotSet = "err_industry_code_not_set",
	ErrCustomValuesValidationFailed = "err_custom_values_validation_failed",
}

export interface InstallmentPaymentsResponse {
	bank_transactions?: BankTransaction[];
}

export interface BankTransaction {
	/** @example btx_23N4e53YUPMMrtdYBRJRWAqTHHW */
	transaction_id: string;
	/** @example payment_request */
	transaction_type?: BankTransactionType;
	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr?: string;
	/**
	 * @format date
	 * @example 2022-05-31
	 */
	booking_date?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount?: string;
	currency?: Currency;
	/** @example posted */
	status?: BankTransactionStatus;
	/** @example SUCCEEDED */
	provider_status?: string;
	/** @example ln_20B1yXb5jj9N0CffCc8xy6lov4A */
	loan_id?: string;
	/** @example ppi_20B7R1ORT7Zv5x4STcT7pjMGsjy */
	installment_id?: string;
	/** @example 12345678 */
	provider_transaction_id?: string;
	/** @example teylor kredit */
	customer_reference?: string;
	provider_reference?: string;
	provider_id?: string;
	/** @format date-time */
	created_at?: string;
	details?: object;
}

export interface BankTransactionCreateData {
	transaction_type?: string;
	/**
	 * debit or credit
	 * @example credit
	 */
	deb_cr?: string;
	/**
	 * @format date
	 * @example 2022-05-31
	 */
	booking_date?: string;
	/**
	 * @format currency
	 * @example 1234.56
	 */
	amount: string;
	currency: Currency;
	/** @example posted */
	status?: BankTransactionStatus;
	/** @example SUCCEEDED */
	provider_status?: string;
	/** @example 12345678 */
	provider_transaction_id?: string;
	/** @example teylor kredit */
	customer_reference?: string;
	provider_reference?: string;
	provider_id?: string;
	details?: object;
}

export enum BOUserFeature {
	Application = "application",
	Payment = "payment",
	Lead = "lead",
	User = "user",
	Loan = "loan",
	Checklist = "checklist",
	SystemSettings = "system_settings",
	Bouser = "bouser",
	DevOnlyBypassChecks = "dev_only_bypass_checks",
	Portfolio = "portfolio",
	Partner = "partner",
	NotificationSubscription = "notification_subscription",
	GlobalRate = "global_rate",
	NotificationChannel = "notification_channel",
	Feature = "feature",
	Product = "product",
	DebtNote = "debt_note",
	NotificationTemplate = "notification_template",
	Tenant = "tenant",
	DocumentTemplate = "document_template",
	Task = "task",
	BankAccount = "bank_account",
}

export enum BankTransactionStatus {
	Pending = "pending",
	Posted = "posted",
	Reconciled = "reconciled",
	Canceled = "canceled",
	Rejected = "rejected",
	Error = "error",
}

export enum BankTransactionType {
	PaymentRequest = "payment_request",
	Prepayment = "prepayment",
}

export interface StatusOwnerSettingsItem {
	application_status?: ApplicationStatuses;
	assignee_ids?: string[];
}

export enum TaskStatus {
	Todo = "todo",
	InProgress = "in_progress",
	Done = "done",
}

export enum TaskRelatedEntityType {
	Application = "application",
	Loan = "loan",
	Company = "company",
}

export enum TaskAssigneeType {
	Bouser = "bouser",
}

export enum TaskPriority {
	Low = "low",
	Medium = "medium",
	High = "high",
}

export enum TaskSortBy {
	DueDate = "due_date",
	Priority = "priority",
	CreatedAt = "created_at",
}

export enum AppSortBy {
	Amount = "amount",
	CreatedAt = "created_at",
}

export interface TaskResponse {
	task_id: string;
	related_entity_id: string;
	related_entity_type: TaskRelatedEntityType;
	assignee_id?: string;
	assignee_type?: TaskAssigneeType;
	assignee_name?: string;
	assignee_email?: string;
	reporter_id: string;
	reporter_type: TaskAssigneeType;
	reporter_name: string;
	reporter_email?: string;
	status: TaskStatus;
	priority: TaskPriority;
	subject: string;
	description?: string;
	/** @format date */
	due_date?: string;
	is_deleted: boolean;
	/** @format datetime */
	created_at: string;
}

export interface CreateTaskRequest {
	related_entity_id: string;
	related_entity_type: TaskRelatedEntityType;
	assignee_id?: string;
	assignee_type?: TaskAssigneeType;
	priority: TaskPriority;
	subject: string;
	description?: string;
	/** @format date */
	due_date?: string;
}

export interface UpdateTaskRequest {
	assignee_id?: string;
	assignee_type?: TaskAssigneeType;
	priority: TaskPriority;
	status: TaskStatus;
	subject: string;
	description?: string;
	/** @format date */
	due_date?: string;
}

export interface UserResponse {
	user_id: string;
	user_id_short?: string | null;
	email?: string;
	name?: string;
	/** @format datetime */
	created?: string | null;
	role?: string;
	financialAdvisor?: boolean;
	financialAdvisorAddress?: string | null;
	confirmationCode?: string | null;
	hasAcceptedTermsAndConditions?: boolean;
	userIdentityId?: string;
	campaign?: string | null;
	campaignArriveTime?: string | null;
	campaignSId?: string | null;
	mainContactNumber?: string;
	emailVerified?: boolean;
	guest?: boolean;
	testUser?: boolean;
	solarisBankOnly?: boolean;
	is_deleted?: boolean;
	has_credentials?: boolean;
	is_enabled?: boolean;
	is_email_confirmed?: boolean;
}

export interface CompanyUserResponse {
	campaign: string | null;
	campaignArriveTime: string | null;
	campaignSId: string | null;
	confirmationCode: string | null;
	/** @format datetime */
	created: string | null;
	email: string;
	emailVerified: boolean;
	financialAdvisor: boolean;
	financialAdvisorAddress: string | null;
	guest: boolean;
	hasAcceptedTermsAndConditions: boolean;
	is_deleted: boolean;
	mainContactNumber: string;
	name: string;
	role: string;
	solarisBankOnly: boolean;
	testUser: boolean;
	userIdentityId: string;
	user_id: string;
	user_id_short: string | null;
}

export enum NoteSubjectKind {
	Application = "application",
	Lead = "lead",
	Loan = "loan",
}

export interface NoteResponse {
	note_id: string;
	subject_kind: string;
	subject_id: string;
	created_by: string;
	message: string;
	/** @format datetime */
	created_at: string;
}

export interface CreateNoteRequest {
	subject_kind: NoteSubjectKind;
	subject_id: string;
	message: string;
}

export interface UpdateNoteRequest {
	message: string;
}

export interface ProductResponse {
	product_family_id: string;
	product_family_name: string;
	family_description?: string;
	product_id: string;
	product_name: string;
	product_description?: string;
	product_category?: "loan" | "leasing";
	product_config_id?: string;
	is_eligible?: boolean;
	errors?: string[];
}

export interface AvailableProductFamily {
	family_id?: string;
	family_name?: string;
	family_description?: string;
	products?: ProductResponse[];
}

export interface Product {
	product_id: string;
	name?: string;
	description?: string;
	product_family_id?: string;
	family_name?: string;
	family_description?: string;
	is_enabled?: boolean;
	product_category?: "loan" | "leasing";
	product_config_id?: string;
}

export enum PartnerType {
	None = "none",
	Free = "free",
	Whitelabel = "whitelabel",
}

export interface ProductRequest {
	name: string;
	description: string;
	product_family_id: string;
	is_enabled?: boolean;
}

export interface TransitionRule {
	product_transition_rule_id?: string;
	source_product_id?: string;
	destination_product_id?: string;
}

export interface TransitionRuleRequest {
	product_transition_rule_id?: string;
	destination_product_id?: string;
}

export interface StatusChangeConstraints {
	status_change_constraint_id?: number;
	product_id?: string;
	status_from?: string;
	status_to?: string;
	action?: string;
	weight?: number;
}

export interface StatusChangeConstraintsRequest {
	product_id?: string;
	status_from?: string;
	status_to?: string;
	action?: string;
	weight?: number;
}

export interface TransitionRuleUpdateRequest {
	destination_product_id?: string;
}

export interface ApplicationUpdateConstraints {
	application_update_constraint_id?: number;
	status?: string[];
	actor_entity_type?: string[];
	verifiers?: string[];
	allowed_fields?: string[];
	product_id?: string;
}

export interface ApplicationUpdateConstraintsRequest {
	status?: string[];
	actor_entity_type?: string[];
	verifiers?: string[];
	allowed_fields?: string[];
	product_id?: string;
}

export interface StatusChangeConfig {
	status_change_config_id?: number;
	status?: string;
	possible_statuses?: string[];
	product_id?: string;
}

export interface StatusChangeConfigRequest {
	status?: string;
	possible_statuses?: string[];
	product_id?: string;
}

export interface ProductFamily {
	product_family_id?: string;
	name?: string;
	description?: string;
}

export interface ProductFamilyRequest {
	name: string;
	description?: string;
}

export interface ProductConfigRequest {
	category: string;
	type: string;
	purposes: string[];
	company_types: string[];
	company_locations: string[];
	min_credit_score?: number;
	max_credit_score?: number;
	min_pd_score?: number;
	max_pd_score?: number;
	min_company_age?: number;
	min_amount?: number;
	max_amount?: number;
	min_length?: number;
	max_length?: number;
	currencies?: string[];
	legacy_provider?: string;
	is_enabled: boolean;
	config_version?: number;
	min_rate_sources?: MinRateSourcesEnum[];
	prepayment_rate?: boolean;
	allow_negative_internal_rate?: boolean;
	/** @format float */
	floor_rate?: number;
	allow_prepayment: boolean;
	document_configuration_id: string;
	industry_code_blacklist_id?: string;
	scoring_config_id?: string;
	allow_loan_eligibility_assessment: boolean;
	min_risk_class?: number;
	max_risk_class?: number;
}

export interface ProductConfigResponse {
	product_config_id: string;
	product_id: string;
	config_version: number;
	legacy_provider?: string;
	category: string;
	type: string;
	purposes: string[];
	company_types: string[];
	company_locations: string[];
	min_credit_score?: number;
	max_credit_score?: number;
	min_pd_score?: number;
	max_pd_score?: number;
	min_company_age?: number;
	requirements?: object;
	min_amount?: number;
	max_amount?: number;
	min_length?: number;
	max_length?: number;
	currencies: string[];
	allow_external_pricing: boolean;
	owner_nationality: string[];
	signatory_nationality: string[];
	is_enabled: boolean;
	min_rate_sources?: MinRateSourcesEnum[];
	prepayment_rate?: boolean;
	allow_negative_internal_rate?: boolean;
	/** @format float */
	floor_rate?: number;
	allow_prepayment: boolean;
	document_configuration_id: string;
	industry_code_blacklist_id?: string;
	scoring_config_id?: string;
	allow_loan_eligibility_assessment: boolean;
	min_risk_class?: number;
	max_risk_class?: number;
}

export interface ApplicationCreate {
	companyName: string;
	companyId?: string;
	numberOfEmployees?: number;
	taxIdNumber?: string;
	businessPurpose?: string;
	loanPurpose?: string;
	purposeDescription: string;
	loanPurposeNumberOfMachines?: number;
	allBanksLoanPurpose: string;
	loanPurposePriceOfMachines?: number;
	street?: string;
	houseNumber?: string;
	loanSize: number;
	loanDuration: number;
	currency?: Currency;
	companyType: CompanyTypes;
	isRevenueMoreThan50k?: boolean;
	companyPostCode?: string;
	/** @example Berlin */
	companyLocation?: string;
	companyCountry?: CountryCode;
	hrNumber?: string;
	referrer?: string;
	campaign?: {
		campaign?: string;
		campaignArriveTime?: string;
		campaignSId?: string;
	};
	confirmAllSignatoriesAdded?: boolean;
	finance_objects?: FinanceObjectRequest[];
	custom_fields?: {
		is_public_tender?: boolean;
		application_comment?: string;
		employer_rate_subsidy?: number;
		employer_service_subsidy?: number;
		max_bikes_per_employee?: number;
		price_limit_bike?: number;
		number_of_employees?: number;
	};
	leasing?: Leasing;
	companyCreditProviderId?: string;
}

export interface Leasing {
	/** @format float */
	leasing_factor?: string;
	service_rate?: string;
	/** @format float */
	remaining_value?: string;
	/** @format float */
	insurance_rate?: string;
}

export interface UserCreate {
	email: string;
	password: string;
	accepted_terms: boolean;
	accepted_partner_terms?: boolean;
	/** @example de */
	interface_language: string;
	/** @example de */
	communication_language: string;
	region?: string;
}

export interface UserRegister {
	password: string;
	token: string;
}

export type InvitedUserRegister = UserRegister & {
	/** @example true */
	accepted_terms: boolean;
	/** @example true */
	accepted_partner_terms?: boolean;
	/** @example de */
	interface_language: string;
	/** @example de */
	communication_language: string;
	/** @example DE */
	region?: string;
};

export interface UserLogin {
	email: string;
	password: string;
}

export interface ApplicationCreator {
	creator: {
		onBehalfUserId?: string;
	};
}

export type ApplicationAndUserCreate = ApplicationCreate & UserCreate;

export type ApplicationForUserCreate = ApplicationCreate & ApplicationCreator;

export type AdminApplication = UtilRequiredKeys<
	UserApplication,
	| "applicationId"
	| "allDocsSubmitted"
	| "allBanksLoanPurpose"
	| "companyId"
	| "currency"
	| "loanSize"
	| "rate"
	| "monthlyRate"
	| "sepaMandatAccepted"
	| "shortApplicationId"
	| "status"
	| "taxIdNumber"
	| "language"
> & {
	allBanksLoanPurpose: AllBanksLoanPurposes;
	allDocsMatch?: boolean;
	applicationId: string;
	creditScore?: string;
	status: ApplicationStatuses;
	isCompanyOlderThanTwoYears: boolean;
	isRevenueMoreThan50k: boolean;
	confirmAllSignatoriesAdded: boolean;
	submittedAtTimestamp?: number;
	yearOfFinancialFigures?: string;
	annualResult?: number;
	interestSpending?: number;
	writeOffs?: number;
	extraordinaryIncome?: number;
	plannedLoanSpending?: number;
	availableCapital?: number;
	predictedCashFlow?: number;
	yearlyLoanCost?: number;
	capitalCoverageRate?: number;
	companyBranche?: string;
	fatca: boolean;
	repaymentPlanId?: string;
	solarisLoanId?: string;
	identificationId?: string;
	identificationStatus?: string;
	numberOfEmployees?: number;
	bilanzSumme?: number;
	taxIdentificationId?: string;
	reject_description?: string;
	creditSummaries?: string;
	probabilityofdefault?: string;
	equity?: number;
	timestampSepaMandatAccepted?: number;
	brancheCode?: string;
	register?: string;
	ebita?: number;
	rejectionReason?: string;
	broker?: string;
	creditreformShareCapital?: number;
	creditreformNumberOfShareholders?: number;
	referrer?: string;
	crm_id?: string;
	crm_link?: string;
	creditType?: string;
	loanProvider?: LoanProviders;
	termsAndConditionsAcceptedTimestamp?: number;
	houseBankName?: string;
	bankSpecificInfo?: string;
	loan_contract_url?: string;
	loan_contract_filename?: string;
	user_id?: string;
	user_email?: string;
	status_owner_id?: string;
	status_owner_email?: string;
	status_owner_name?: string;
	status_owner_assigned_at?: string;
	account_manager_id?: string;
	account_manager_name?: string;
	account_manager_email?: string;
	created_by_email?: string;
	created_by_type?: UserType;
	created_by_name?: string;
	is_baas: boolean;
	possible_providers: LoanProviders[];
	product: ProductResponse;
	partner_id?: string;
	partner_name?: string;
	partner_type?: PartnerType;
	checklists?: ChecklistStatus[];
};

export interface ApplicationsList {
	result: ApplicationListItem[];
	pagination: Pagination;
}

export interface ApplicationListItem {
	application_id: string;
	short_id: string;
	company_id: string;
	email: string;
	company_name: string;
	status: ApplicationStatuses;
	loan_provider: LoanProviders;
	allBanksLoanPurpose?: string;
	amount: number;
	currency?: Currency;
	duration: number;
	status_owner_id?: string;
	status_owner_email?: string;
	status_owner_name?: string;
	account_manager_id?: string;
	account_manager_name?: string;
	account_manager_email?: string;
	created_by_email?: string;
	created_by_type?: UserType;
	created_by_name?: string;
	product: ProductResponse;
	partner_id?: string;
	partner_name?: string;
	/** @format datetime */
	created_at: string;
}

export interface AdminCompanyResponse {
	companyId: string;
	company_id_short?: string;
	companyName: string;
	companyType: CompanyTypes;
	hrNumber?: string;
	/** @format date-time */
	companyFoundationDate?: string;
	numberOfEmployees?: number;
	creditScore?: number;
	companyCreditProviderId?: string;
	businessPurpose?: string;
	taxIdNumber?: string;
	address_post_code: string;
	address_street: string;
	address_country: CountryCode;
	address_location: string;
	address_house_number: string;
	industry_codes?: IndustryCodesResponse;
	/** @format date */
	created: string;
}

export interface IndustryCodesResponse {
	company_industry_code_set_id: string;
	source: string;
	kind: IndustryCodeKindEnum;
	/** @format date-time */
	created_at: string;
	codes: IndustryCodeResponseItem[];
}

export interface IndustryCodeResponseItem {
	company_industry_code_id: string;
	code: string;
	rank: number;
	/** @format float */
	weight?: number;
	description: string;
}

export interface IndustryCodesCreateRequest {
	kind: IndustryCodeKindEnum;
	codes: IndustryCodesCreateRequestItem[];
}

export interface IndustryCodesCreateRequestItem {
	code: string;
	/** @format float */
	weight?: string;
	description: string;
}

export enum IndustryCodeKindEnum {
	Wz2008 = "wz_2008",
	Noga2008 = "noga_2008",
}

export type CompanySearchResult = CompanyPartner;

export type PartnerPortfolioItem = CompanyPartner;

export interface CompanyPartner {
	company_id: string;
	company_name: string;
	company_postcode?: string;
	company_country?: string;
	company_location?: string;
	company_type: string;
	company_street?: string;
	company_house_number?: string;
	hr_number?: string;
	foundation_date?: string;
	number_of_applications: number;
	/** @format date-time */
	created_at: string;
	number_of_employees: number;
	in_portfolio: boolean;
	tax_id_number?: string;
	company_status?: CompanyPartnerStatus;
	company_registration_number?: string;
	industry_codes?: IndustryCodesResponse;
	contact_info?: {
		telephone?: string;
		fax?: string;
		website?: string;
		email?: string;
	};
	company_register?: {
		city?: string;
		country?: string;
		id?: string;
		uniqueKey?: string;
	};
	global_company_id?: string;
	north_data_id: string;
	partner_portfolio_item_id: string;
	company_last_updated_at?: string;
	description?: string;
}

export enum CompanyPartnerStatus {
	Active = "active",
	Terminated = "terminated",
	Liquidation = "liquidation",
}

export interface TenantCreateRequest {
	tenant_id?: string;
	full_name: string;
	display_name: string;
	is_enabled: boolean;
}

export interface Tenant {
	id?: string;
	full_name?: string;
	display_name?: string;
	is_enabled?: boolean;
	is_deleted?: boolean;
	/** @format datetime */
	created_at?: string;
}

export interface Document {
	document_id: string;
	document_name: string;
	entity_id: string;
	entity_kind: EntityKind;
	document_category: DocumentCategoryName;
	document_type: DocumentTypeName;
	uploader_entity_id?: string;
	uploader_entity_kind?: string;
	related_entity_id?: string;
	related_entity_kind?: RelatedEntityKind;
	s3_key?: string;
	is_client_visible?: boolean;
	created_at: string;
}

export type CombinedDocument = Document & {
	is_application_document: boolean;
};

export enum MinRateSourcesEnum {
	HedgingRate = "hedging_rate",
	GridRate = "grid_rate",
}

export type DocumentCategoryName = string | DocumentCategory;

export enum DocumentCategory {
	None = "none",
	Contracts = "contracts",
}

export type DocumentTypeName = string | DocumentType;

export enum DocumentType {
	None = "none",
	GuarantorContractSigned = "guarantor_contract_signed",
	ObjectOrderForm = "object_order_form",
	CompanyDocBwa = "company_doc_bwa",
	CompanyDocBwaandsusa = "company_doc_bwaandsusa",
	CompanyDocSusa = "company_doc_susa",
	AnnualStatement20 = "annual_statement_20",
	AnnualStatement21 = "annual_statement_21",
	AnnualStatement22 = "annual_statement_22",
}

export interface UpdateDocumentCategoryRequest {
	name?: string;
	description?: string;
}

export type DocumentCategoriesResponse = DocumentConfigCategory[];

export interface DocumentConfigCategory {
	document_category_id: string;
	name: DocumentCategoryName;
	description?: string;
	document_types?: DocumentTypesResponse;
}

export interface UpdateDocumentTypeRequest {
	document_category_id?: string;
	name?: string;
	description?: string;
}

export interface DocumentTypeRequest {
	document_category_id?: string;
	name?: string;
	description?: string;
}

export type DocumentTypesResponse = DocumentTypeConfig[];

export interface DocumentTypeConfig {
	document_type_id: string;
	document_category_id?: string;
	name: DocumentTypeName;
	description?: string;
	is_active?: boolean;
	is_required?: boolean;
	template_url?: string;
	tooltip_description?: string;
}

export interface DocumentConfigurationRequest {
	name?: string;
	description?: string;
}

export interface UpdateDocumentConfigurationRequest {
	document_type_id?: string;
	is_required?: boolean;
	name?: string;
	description?: string;
}

export type DocumentConfigurationsResponse = DocumentConfiguration[];

export interface DocumentConfiguration {
	document_configuration_id: string;
	name?: string;
	description?: string;
	is_deleted: boolean;
	document_categories?: DocumentCategoriesResponse;
}

export interface DocumentTypeConfiguration {
	document_type_id: string;
	document_category_id?: string;
	category_name: DocumentCategoryName;
	category_description?: string;
	name?: DocumentTypeName;
	description?: string;
	is_active?: boolean;
	template_url?: string;
	tooltip_description?: string;
	is_required: boolean;
}

export interface DocumentsResponse {
	pagination: Pagination;
	result: Document[];
}

export interface CombinedDocumentsResponse {
	pagination: Pagination;
	result: CombinedDocument[];
}

export interface UploadDocumentPayload {
	file_name: string;
	document_type: string;
	related_entity_kind?: RelatedEntityKind;
	/** @example sg_123 */
	related_entity_id?: string;
}

export interface UploadDocumentResponse {
	document_id: string;
	upload_url: string;
}

export interface UpdateDocumentTypePayload {
	document_type: string;
}

export interface UpdateDocumentPayload {
	document_name: string;
	is_client_visible?: boolean;
}

export interface DocumentUrlResponse {
	download_url: string;
}

export interface DocumentVisibility {
	is_client_visible?: boolean;
}

export enum RelatedEntityKind {
	Signatory = "signatory",
}

export enum EntityKind {
	Company = "company",
	Application = "application",
	User = "user",
	Partner = "partner",
	Loan = "loan",
	Signatory = "signatory",
}

export interface UpdateApplicationStatusRequestBody {
	status: ApplicationStatuses;
	/** reason for rejecting the application */
	reject_description?: string;
	/** additional data when changing to Awaiting_Payout status */
	status_awaiting_payout?: {
		fronting_bank_iban?: string;
	};
}

export interface LoanContract {
	application_id: string;
	download_url: string;
	filename: string;
}

export interface StatusPendingActions {
	current_status: ApplicationStatuses;
	next_status: ApplicationStatuses;
	pending_actions: StatusPendingAction[];
	signatories_errors?: SignatoryPendingAction[];
}

export interface StatusPendingAction {
	action: PendingActionType;
	errors?: PendingActionDetails[];
}

export interface SignatoryPendingAction {
	errors: PendingActionError[];
	first_name: string;
	last_name: string;
	signatory_id: string;
}

export enum PendingActionType {
	StatusChangeNotAllowed = "status_change_not_allowed",
	ChecklistPayoutDone = "checklist_payout_done",
	ChecklistFourEyesDone = "checklist_four_eyes_done",
	BankNameIbanValid = "bank_name_iban_valid",
	SignatoriesSpecified = "signatories_specified",
	SignatoriesSharesLe100 = "signatories_shares_le_100",
	SignatoriesTaxidSpecified = "signatories_taxid_specified",
	ScoreMarkGeThreshold = "score_mark_ge_threshold",
	ScoreExists = "score_exists",
	ScoreWarningsCountLow = "score_warnings_count_low",
	ScoreRedFlagsCountLow = "score_red_flags_count_low",
	LoanContractExist = "loan_contract_exist",
	SignatoriesRequirementsSpecified = "signatories_requirements_specified",
	SignatoriesSignLoanContract = "signatories_sign_loan_contract",
	RepresentativeKycOwnershipGt25 = "representative_kyc_ownership_gt_25",
	GuarantorContractSignatories = "guarantor_contract_signatories",
	SignatoriesIdentUrlSpecified = "signatories_ident_url_specified",
	SignatoriesUboContractUrlSpecified = "signatories_ubo_contract_url_specified",
	SignatoriesSoleOrAtleast2 = "signatories_sole_or_atleast_2",
	ApplicationPricingSpecified = "application_pricing_specified",
	CompanyTinSpecified = "company_tin_specified",
	SignatoriesIdentCompleted = "signatories_ident_completed",
	RepresentativesDataCompleted = "representatives_data_completed",
	ProdUserNotTeylorEmployee = "prod_user_not_teylor_employee",
	ChecklistApplicationPositive = "checklist_application_positive",
	FinancialsComplete = "financials_complete",
	AllowedCompanyType = "allowed_company_type",
	AllowedCreditScore = "allowed_credit_score",
	AllowedCompanyCountry = "allowed_company_country",
	ScoringFeedbackDone = "scoring_feedback_done",
	ApplicationExternalPricingSpecified = "application_external_pricing_specified",
	DocumentBwaSusaUploaded = "document_bwa_susa_uploaded",
	DocumentAnnualStatementsUploaded = "document_annual_statements_uploaded",
	DocumentCurrentDebtRegisterUploaded = "document_current_debt_register_uploaded",
	GuarantorContractUploaded = "guarantor_contract_uploaded",
	SepaMandateAccepted = "sepa_mandate_accepted",
	LegalSignatureAccepted = "legal_signature_accepted",
	RepaymentPlanGenerated = "repayment_plan_generated",
	FinanceObjectsAdded = "finance_objects_added",
	PepCheckDocument = "pep_check_document",
	LanguageSupported = "language_supported",
	PepResultsApproved = "pep_results_approved",
	SignatoriesMinimumDataCompleted = "signatories_minimum_data_completed",
	SignatoriesIdentSuccessful = "signatories_ident_successful",
	ChooseNonDefaultProduct = "choose_non_default_product",
	DefaultPartnerTermsAccepted = "default_partner_terms_accepted",
	AllRequiredDocumentsUploaded = "all_required_documents_uploaded",
	ExtractBicFromIban = "extract_bic_from_iban",
	CompanyIndustryCodesAllowed = "company_industry_codes_allowed",
}

export interface PendingActionDetails {
	error: PendingActionError;
	signatories?: string[];
	document_types?: DocumentTypeName[];
}

export enum PendingActionError {
	ErrApplicationPayoutChecklistNotDone = "err_application_payout_checklist_not_done",
	ErrApplicationFourEyesChecklistNotDone = "err_application_four_eyes_checklist_not_done",
	ErrApplicationNoBankName = "err_application_no_bank_name",
	ErrApplicationNoIban = "err_application_no_iban",
	ErrBicNotFoundFromIban = "err_bic_not_found_from_iban",
	ErrInvalidIban = "err_invalid_iban",
	ErrApplicationNoSignatories = "err_application_no_signatories",
	ErrApplicationNoSignatoriesSignLoanContract = "err_application_no_signatories_sign_loan_contract",
	ErrApplicationNoTaxidForSignatory = "err_application_no_taxid_for_signatory",
	ErrApplicationNoLoanContract = "err_application_no_loan_contract",
	ErrApplicationInternalError = "err_application_internal_error",
	ErrApplicationNoSignatoryContract = "err_application_no_signatory_contract",
	ErrApplicationNoSignatoryIdentUrl = "err_application_no_signatory_ident_url",
	ErrApplicationNoPricing = "err_application_no_pricing",
	ErrApplicationNoPricingTransactionFee = "err_application_no_pricing_transaction_fee",
	ErrApplicationNoPricingPrepaymentRate = "err_application_no_pricing_prepayment_rate",
	ErrSharePercentageGreaterThen100 = "err_share_percentage_greater_then_100",
	ErrApplicationSignatoryIdentNotDone = "err_application_signatory_ident_not_done",
	ErrApplicationMissingCompany = "err_application_missing_company",
	ErrApplicationCompanyTaxidNotSpecified = "err_application_company_taxid_not_specified",
	ErrApplicationUserIsTeylorEmployee = "err_application_user_is_teylor_employee",
	ErrMissingScoringData = "err_missing_scoring_data",
	ErrMissingScoringConfig = "err_missing_scoring_config",
	ErrLowScoringMark = "err_low_scoring_mark",
	ErrTooManyRedFlags = "err_too_many_red_flags",
	ErrTooManyWarnings = "err_too_many_warnings",
	ErrCrefoCompanySearchFailed = "err_crefo_company_search_failed",
	ErrChecklistBadScore = "err_checklist_bad_score",
	ErrChecklistNotExist = "err_checklist_not_exist",
	ErrChecklistNotDone = "err_checklist_not_done",
	ErrFinancialsNotExist = "err_financials_not_exist",
	ErrCompanyCountryNotAllowed = "err_company_country_not_allowed",
	ErrCompanyTypeNotAllowed = "err_company_type_not_allowed",
	ErrCompanyCreditScoreNotAllowed = "err_company_credit_score_not_allowed",
	ErrCompanyCreditScoreTooHigh = "err_company_credit_score_too_high",
	ErrCompanyCreditScoreTooLow = "err_company_credit_score_too_low",
	ErrApplicationRoleInvalid = "err_application_role_invalid",
	ErrStatusChangeNotAllowed = "err_status_change_not_allowed",
	ErrScoringFeedbackNotDone = "err_scoring_feedback_not_done",
	ErrApplicationNoExternalPricing = "err_application_no_external_pricing",
	ErrDocumentAnnualStatmentMissing = "err_document_annual_statment_missing",
	ErrDocumentBwaSusaMissing = "err_document_bwa_susa_missing",
	ErrDocumentDebtRegistryMissing = "err_document_debt_registry_missing",
	ErrDocumentGuarantorConractMissing = "err_document_guarantor_conract_missing",
	ErrSepaMandateNotAccepted = "err_sepa_mandate_not_accepted",
	ErrRepaymentPlanNotGenerated = "err_repayment_plan_not_generated",
	ErrMinInterestRateNotSet = "err_min_interest_rate_not_set",
	ErrPepApprovalsMissing = "err_pep_approvals_missing",
	ErrSignatoryBirthdateMissing = "err_signatory_birthdate_missing",
	ErrHedingFeeNotSet = "err_heding_fee_not_set",
	ErrMinRateSourcesEmpty = "err_min_rate_sources_empty",
	ErrApplicationPepCheckNotUploaded = "err_application_pep_check_not_uploaded",
	ErrUserNotAcceptedTerms = "err_user_not_accepted_terms",
	ErrApplicationSignatoriesSoleOrAtleast2 = "err_application_signatories_sole_or_atleast_2",
	ErrInsufficientSignatoriesSignLoanContract = "err_insufficient_signatories_sign_loan_contract",
	ErrApplicationGuarantorSignatoryMissing = "err_application_guarantor_signatory_missing",
	ErrChooseNonDefaultProduct = "err_choose_non_default_product",
	ErrIndustryCodeNotSet = "err_industry_code_not_set",
	ErrIndustryCodeNotAllowed = "err_industry_code_not_allowed",
}

export interface ScoringConfigRequest {
	name: string;
	benchmarks: object;
	score_card: object;
	default_interest_rate_for_calculations: number;
	revenue_thresholds: object;
	years_needed_for_scoring: number[];
	max_red_flags_count?: number;
	max_warnings_count?: number;
	version: string;
}

export type ScoringConfigResponse = ScoringConfigRequest & {
	scoring_config_id: string;
	is_deleted: boolean;
	created_at: string;
	sanity_checks?: SanityCheck[];
};

export interface SanityCheck {
	check: string;
	warnings: string[];
	errors: string[];
}

export enum PersonTitle {
	Mr = "Mr",
	Mrs = "Mrs",
	Ms = "Ms",
	Miss = "Miss",
}

export enum CompanyTypes {
	GMBH = "GMBH",
	GMBH_CO_KG = "GMBH_CO_KG",
	FREIE_BERUFE = "FREIE_BERUFE",
	SELF_EMPLOYED = "SELF_EMPLOYED",
	KGAA = "KGAA",
	AG = "AG",
	GBR = "GBR",
	OHG = "OHG",
	PARTG = "PARTG",
	KG = "KG",
	UG = "UG",
	SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
	EG = "EG",
	EK = "EK",
	CH_AG = "CH_AG",
	CH_SOLE_PROPRIETORSHIP = "CH_SOLE_PROPRIETORSHIP",
	CH_OTHER = "CH_OTHER",
	CH_GMBH = "CH_GMBH",
}

export interface ProductInterestRateConfigRequest {
	purpose: "scoring" | "representative";
	interest_table: object;
}

export type ProductInterestRateConfigResponse = ProductInterestRateConfigRequest & {
	interest_rate_id: string;
	product_id: string;
	is_deleted: boolean;
	created_at: string;
};

export interface CompanySearchResponse {
	companyNameSuggestions?: CompanyNameSuggestion[];
}

export interface CompanyNameSuggestion {
	id?: string;
	crefonummer?: string;
	identnummer?: string;
	name: string;
	handelsName?: string;
	land: CountryCode;
	bundesland?: string;
	plz: string;
	ort: string;
	strasseHausnummer: string;
}

export enum AllBanksLoanPurposes {
	GeneralLiquidity = "general_liquidity",
	ObjectFinancing = "object_financing",
	RealEstate = "real_estate",
	Factoring = "factoring",
	Whitelabel = "whitelabel",
	Other = "other",
	PurposeAkfBike = "purpose_akf_bike",
	Material = "material",
	Project = "project",
	WorkingCapital = "working_capital",
}

export interface Entitlement {
	entitlement_id: number;
	entitlement: EntitlementType;
	is_deleted?: boolean;
}

export interface EntitlementRequest {
	entitlement: string;
}

export interface PartnerEntitlement {
	partner_entitlement_id: number;
	partner_id: string;
	entitlement_id: number;
	entitlement: EntitlementType;
}

export interface PartnerEntitlementRequest {
	entitlement_id: number;
}

export enum EntitlementType {
	CustomizedPricing = "customized_pricing",
	WhitelabeledUserBranding = "whitelabeled_user_branding",
	CustomizedApplicationAmounts = "customized_application_amounts",
	PartnerApplicationCreate = "partner_application_create",
	CompanyResearch = "company_research",
	PartnerLoanApplications = "partner_loan_applications",
}

export interface FinanceObjectRequest {
	/** @example John Deere */
	manufacturer: string;
	/** @example Tractor */
	type: string;
	/** @example 2022-08-26T18:36:30.250Z */
	built_at?: string;
	/** @example John Deere specialty tractors come with all the features, power and performance you want in a utility tractor in a size that’s just right for the specialty crops you grow. */
	detail_desc?: string;
	/** @example 90000 */
	net_purchase_price?: number;
	/** @example 9000 */
	down_payment?: number;
	/** @example 81000 */
	remaining_value?: number;
	/** @example 2 */
	object_count: number;
	/** @example true */
	is_fixed_interest: boolean;
	financing_type: ObjectFinancingType;
	rental_state: ObjectRentalState;
	/** @example Michael Gaar Landtechnik */
	merchant_name: string;
	/** @example Friedrich-Bergius-Str. 2 */
	merchant_street: string;
	/** @example 2 */
	merchant_house_num: string;
	/** @example Höhenkirchen-Siegb. */
	merchant_city: string;
	/** @example 85635 */
	merchant_postcode: string;
	/** @example DE */
	merchant_country: string;
	merchant_company_id: string;
	/** @example 0.025 */
	commission?: number;
	/** @format date */
	expected_delivery_date?: string;
}

export interface FinanceObjectCreateResponse {
	/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
	finance_object_id: string;
}

export interface FinanceObjectGetResponse {
	/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
	finance_object_id: string;
	/**
	 * @format date-time
	 * @example 2022-08-24T10:00:00.000Z
	 */
	created_at: string;
	/**
	 * @format date-time
	 * @example 2022-08-26T18:36:30.250Z
	 */
	updated_at?: string;
	/** @example John Deere */
	manufacturer: string;
	/** @example Tractor */
	type: string;
	/** @example 2022-08-26T18:36:30.250Z */
	built_at?: string;
	/** @example John Deere specialty tractors come with all the features, power and performance you want in a utility tractor in a size that’s just right for the specialty crops you grow. */
	detail_desc?: string;
	/** @example 90000 */
	net_purchase_price?: number;
	/** @example 9000 */
	down_payment?: number;
	/** @example 81000 */
	remaining_value?: number;
	/** @example 2 */
	object_count: number;
	/** @example true */
	is_fixed_interest: boolean;
	financing_type: ObjectFinancingType;
	rental_state: ObjectRentalState;
	/** @example Michael Gaar Landtechnik */
	merchant_name: string;
	/** @example Friedrich-Bergius-Str. 2 */
	merchant_street: string;
	/** @example 2 */
	merchant_house_num: string;
	/** @example Höhenkirchen-Siegb. */
	merchant_city: string;
	/** @example 85635 */
	merchant_postcode: string;
	/** @example DE */
	merchant_country: string;
	merchant_company_id?: string;
	/** @example 0.025 */
	commission?: number;
	/** @format date */
	expected_delivery_date?: string;
}

export enum ObjectFinancingType {
	Leasing = "leasing",
	HirePurchase = "hire_purchase",
	Financing = "financing",
	SaleLeaseback = "sale_leaseback",
	CreditLine = "credit_line",
	Other = "other",
}

export enum ObjectRentalState {
	No = "no",
	Internal = "internal",
	B2C = "b2c",
}

export interface OrderFormRequest {
	requested_offer_details?: string;
}

export interface SignatoryRequirementStatus {
	is_completed?: boolean;
	is_required?: boolean;
}

export interface SignatoryKycStatus {
	sepa_mandate_accepted?: SignatoryRequirementStatus;
	legal_signature_accepted?: SignatoryRequirementStatus;
	guarantor_contract_exist?: SignatoryRequirementStatus;
	guarantor_contract_uploaded?: SignatoryRequirementStatus;
	ident_url_specified?: SignatoryRequirementStatus;
	ident_completed?: SignatoryRequirementStatus;
	tax_id_specified?: SignatoryRequirementStatus;
	loan_signature?: SignatoryRequirementStatus;
}

export enum FinancialStatementType {
	AnnualStatement = "annual_statement",
	InterimFinancials = "interim_financials",
}

export interface FinancialDataResponse {
	financials: FinancialData[];
}

export interface FinancialDataRequest {
	statement_type: FinancialStatementType;
	/** @format date */
	financials_date?: string;
	input_data: FinancialInputData;
}

export interface FinancialData {
	financial_data_id: number;
	application_id?: string;
	/** @format date */
	financials_date?: string;
	statement_type: FinancialStatementType;
	input_data: FinancialInputData;
	summary_data?: FinancialSummaryData;
	interim?: boolean;
	origin?: "manual" | "ocr";
}

export interface CompanyFinancialDataRequest {
	/** @format date */
	financials_date: string;
	statement_type: FinancialStatementType;
	input_data: FinancialInputData;
}

export interface CompanyFinancialDataResponse {
	financials: CompanyFinancialData[];
}

export interface CompanyFinancialData {
	company_financial_data_id?: string;
	company_id?: string;
	/** @format date */
	financials_date: string;
	statement_type: FinancialStatementType;
	input_data: FinancialInputData;
	summary_data?: FinancialSummaryData;
	origin: "manual" | "ocr";
}

export interface FinancialInputData {
	revenue?: string;
	inventory_change?: string;
	activated_costs?: string;
	other_income?: string;
	extraordinary_income?: string;
	extraordinary_expense?: string;
	costs_of_materials?: string;
	external_services?: string;
	personnel_costs?: string;
	depreciations?: string;
	other_costs?: string;
	interest_income?: string;
	interest_expense?: string;
	other_finance_income?: string;
	other_finance_costs?: string;
	taxes?: string;
	other_taxes?: string;
	long_term_intangible_assets?: string;
	self_made_intangible_assets?: string;
	long_term_properties?: string;
	long_term_securities?: string;
	investments_in_related_companies?: string;
	goodwill?: string;
	inventories?: string;
	accounts_receivable?: string;
	other_current_assets?: string;
	loan_to_related_companies?: string;
	loan_to_shareholders?: string;
	cash?: string;
	accruals_aktiva?: string;
	deferred_tax_assets?: string;
	losses_not_covered_by_equity?: string;
	subscribed_capital?: string;
	capital_reserve?: string;
	retained_income?: string;
	profit_carried_forward?: string;
	annual_profit?: string;
	other_equity_position?: string;
	accrued_liabilities_short_term?: string;
	accrued_liabilities_long_term?: string;
	bonds?: string;
	bank_debts_short_term?: string;
	bank_debts_long_term?: string;
	deposits_received?: string;
	accounts_payable?: string;
	loan_from_related_companies?: string;
	other_liabilities_short_term?: string;
	other_liabilities_long_term?: string;
	loan_from_shareholders?: string;
	accruals_passiva?: string;
	deferred_tax_liabilities?: string;
	total_assets?: string;
	other_debts_short_term?: string;
	other_debts_long_term?: string;
	total_debt_service?: string;
}

export interface FinancialSummaryData {
	revenue: string;
	activated_costs: string;
	inventory_increase: string;
	other_income_regular: string;
	cogs: string;
	other_cor: string;
	cost_of_revenue: string;
	gross_profit: string;
	sga: string;
	depreciations: string;
	other_opex_costs: string;
	opex: string;
	operating_profit: string;
	ebitda: string;
	other_income_net: string;
	other_finance_income: string;
	other_finance_costs: string;
	interest_income: string;
	interest_expense: string;
	ebt: string;
	taxes_paid: string;
	nopat: string;
	tax_rate: string;
	net_income: string;
	fixed_costs: string;
	cash: string;
	short_term_investments: string;
	accounts_receivable: string;
	inventories: string;
	inventory_net: string;
	prepaid_expenses: string;
	other_current_assets: string;
	loan_to_shareholders: string;
	loan_to_related_companies: string;
	property_equipment_net: string;
	intangible_assets_net: string;
	self_made_intangible: string;
	goodwill: string;
	long_term_investments: string;
	investments_in_related_companies: string;
	other_non_current_assets: string;
	deferred_tax_assets: string;
	current_assets_published: string;
	non_current_assets_published: string;
	total_assets_published: string;
	equity_published: string;
	current_assets: string;
	non_current_assets: string;
	total_assets: string;
	current_portion_of_long_debt: string;
	accounts_payable: string;
	accrued_expenses: string;
	income_taxes_payable: string;
	other_current_liabilities: string;
	current_liabilities_published: string;
	current_liabilities: string;
	long_term_debt: string;
	underfunded_retirement_plans: string;
	deferred_tax_liabilities: string;
	other_long_term_liabilities: string;
	non_current_liabilities_published: string;
	total_liabilities_published: string;
	loan_from_shareholders: string;
	non_current_liabilities: string;
	total_liabilities: string;
	equity: string;
	preferred_stock: string;
	shares_issued: string;
	paid_in_capital: string;
	retained_earnings: string;
	accumulated_other_comprehensive_income: string;
	price_per_share: string;
	total_debt: string;
	net_debt: string;
	capital_employed: string;
	total_invested_capital: string;
	net_fixed_assets: string;
	working_capital: string;
	net_working_capital: string;
	risk_free_rate_of_return: string;
	market_rate_of_return: string;
	classification_by_revenue:
		| "mega_cap"
		| "large_cap"
		| "medium_cap"
		| "small_cap"
		| "mini_cap"
		| "micro_cap"
		| "extra_micro_cap";
	beta: string;
	balance_sheet_sum_check: string;
	debt_service: string;
	company_age: number;
	financial_result: string;
}

export interface KPIsResponse {
	kpis: KPIs[];
}

export interface KPIs {
	kpis_id: string;
	application_id: string;
	category:
		| "risk_standard"
		| "risk_standard_multi"
		| "future_performance"
		| "cash_flow"
		| "additional_loan"
		| "repayment_information";
	start_year: number;
	end_year: number;
	kpis:
		| StandardRiskKPIs
		| StandardRiskMultiKPIs
		| FuturePerformanceKPIs
		| CashFlowKPIs
		| AdditionalLoanKPIs;
}

export interface CompanyKPIsResponse {
	kpis: CompanyKPIs[];
}

export interface CompanyKPIs {
	company_kpis_id: string;
	company_id: string;
	category:
		| "risk_standard"
		| "risk_standard_multi"
		| "future_performance"
		| "cash_flow"
		| "additional_loan";
	/** @format date */
	start_date: string;
	/** @format date */
	end_date: string;
	kpis:
		| StandardRiskKPIs
		| StandardRiskMultiKPIs
		| FuturePerformanceKPIs
		| CashFlowKPIs
		| AdditionalLoanKPIs;
	version: string;
	/** @format date-time */
	created_at: string;
}

export interface StandardRiskKPIs {
	margin_kpis: MarginKPIs;
	profitability_kpis: ProfitabilityKPIs;
	liquidity_kpis: LiquidityKPIs;
	solvency_kpis: SolvencyKPIs;
}

export interface MarginKPIs {
	activated_costs_margin: string;
	cost_of_revenue_margin: string;
	gross_profit_margin: string;
	opex_margin: string;
	depreciations_margin: string;
	operating_profit_margin: string;
	ebitda_margin: string;
	ebt_margin: string;
	nopat_margin: string;
	other_income_net_margin: string;
	interest_expense_margin: string;
	taxes_margin: string;
	net_income_margin: string;
	fixed_costs_margin: string;
	current_assets_margin: string;
	cash_margin: string;
	short_term_investments_margin: string;
	accounts_receivable_margin: string;
	inventory_net_margin: string;
	prepaid_expenses_margin: string;
	other_current_assets_margin: string;
	loan_to_shareholders_margin: string;
	loan_to_related_companies_margin: string;
	property_equipment_net_margin: string;
	intangible_assets_net_margin: string;
	self_made_intangible_margin: string;
	goodwill_margin: string;
	long_term_investments_margin: string;
	investments_in_related_companies_margin: string;
	other_non_current_assets_margin: string;
	deferred_tax_assets_margin: string;
	non_current_assets_margin: string;
	current_liabilities_margin: string;
	current_portion_of_long_debt_margin: string;
	accounts_payable_margin: string;
	accrued_expenses_margin: string;
	income_taxes_payable_margin: string;
	other_current_liabilities_margin: string;
	long_term_debt_margin: string;
	underfunded_retirement_plans_margin: string;
	deferred_tax_liabilities_margin: string;
	other_long_term_liabilities_margin: string;
	loan_from_shareholders_margin: string;
	non_current_liabilities_margin: string;
	total_liabilities_margin: string;
	equity_margin: string;
	preferred_stock_margin: string;
	shares_issued_margin: string;
	paid_in_capital_margin: string;
	retained_earnings_margin: string;
	accumulated_other_comprehensive_income_margin: string;
	total_debt_margin: string;
	net_debt_margin: string;
	capital_employed_margin: string;
	total_invested_capital_margin: string;
	net_fixed_assets_margin: string;
	working_capital_margin: string;
	net_working_capital_margin: string;
	sga_margin: string;
	other_opex_costs_margin: string;
	other_income_regular_margin: string;
}

export interface ProfitabilityKPIs {
	return_on_invested_capital: string;
	return_on_equity: string;
	return_on_assets: string;
	return_on_sales: string;
	return_on_capital: string;
	return_on_capital_employed: string;
	cost_of_equity: string;
	cost_of_debt_and_preferred_stock: string;
	after_tax_cost_of_debt: string;
	weighted_average_cost_of_capital: string;
	value_spread: string;
}

export interface LiquidityKPIs {
	current_ratio: string;
	working_capital_to_revenue: string;
	quick_ratio: string;
	cash_ratio: string;
	days_sales_outstanding: string;
	days_payables_outstanding: string;
	days_inventory_outstanding: string;
	cash_conversion_cycle: string;
}

export interface SolvencyKPIs {
	equity_ratio: string;
	debt_to_equity_ratio: string;
	debt_to_assets_ratio: string;
	debt_to_capital_ratio: string;
	asset_coverage_ratio: string;
	solvency_ratio: string;
	retention_ratio: string;
	group_financing_to_total_assets: string;
}

export interface StandardRiskMultiKPIs {
	growth_kpis: GrowthKPIs;
	efficiency_kpis: EfficiencyKPIs;
	leverage_kpis: LeverageKPIs;
}

export interface GrowthKPIs {
	revenue_growth: string;
	gross_profit_growth: string;
	ebitda_growth: string;
	operating_profit_growth: string;
	opex_growth: string;
	interest_expense_growth: string;
	net_income_growth: string;
	current_assets_growth: string;
	cash_growth: string;
	short_term_investments_growth: string;
	accounts_receivable_growth: string;
	inventories_growth: string;
	prepaid_expenses_growth: string;
	other_current_assets_growth: string;
	loan_to_shareholders_growth: string;
	loan_to_related_companies_growth: string;
	property_equipment_net_growth: string;
	intangible_assets_net_growth: string;
	self_made_intangible_growth: string;
	goodwill_growth: string;
	long_term_investments_growth: string;
	investments_in_related_companies_growth: string;
	other_non_current_assets_growth: string;
	deferred_tax_assets_growth: string;
	non_current_assets_growth: string;
	total_assets_growth: string;
	current_liabilities_growth: string;
	current_portion_of_long_debt_growth: string;
	accounts_payable_growth: string;
	accrued_expenses_growth: string;
	income_taxes_payable_growth: string;
	other_current_liabilities_growth: string;
	long_term_debt_growth: string;
	underfunded_retirement_plans_growth: string;
	deferred_tax_liabilities_growth: string;
	other_long_term_liabilities_growth: string;
	loan_from_shl_growth: string;
	non_current_liabilities_growth: string;
	total_liabilities_growth: string;
	equity_growth: string;
	total_debt_growth: string;
	net_debt_growth: string;
	capital_employed_growth: string;
	total_invested_capital_growth: string;
	net_fixed_assets_growth: string;
	working_capital_growth: string;
	net_working_capital_growth: string;
}

export interface EfficiencyKPIs {
	asset_turnover_ratio: string;
	asset_turnover: string;
	fixed_asset_turnover_ratio: string;
	inventory_turnover_ratio: string;
	accounts_receivable_turnover_ratio: string;
	accounts_payable_turnover_ratio: string;
	days_working_capital: string;
	return_on_operating_assets: string;
	working_capital_growth_to_revenue_growth: string;
	opex_growth_ratio: string;
	cash_conversion_ratio: string;
}

export interface LeverageKPIs {
	debt_to_ebitda_ratio: string;
	liabilities_to_ebitda_ratio: string;
	debt_growth_to_ebitda_growth: string;
	leverage_index: string;
	times_interest_earned: string;
	interest_coverage_ratio: string;
	debt_service_coverage_ratio: string;
	net_debt_service_coverage_ratio: string;
	relative_debt: string;
	fixed_charges_before_taxes: string;
	fixed_charge_coverage_ratio: string;
	operating_leverage: string;
	net_debt_to_ebitda_ratio: string;
	free_cash_flow_post_finance_to_net_debt: string;
	interest_coverage_ratio_adjusted: string;
	free_cash_flow_to_net_debt: string;
}

export interface CashFlowKPIs {
	cfo: string;
	capex: string;
	free_cash_flow: string;
	free_cash_flow_post_finance: string;
	earnings_quality: string;
	operating_cash_flow_ratio: string;
}

export interface FuturePerformanceKPIs {
	roe: string;
	equity_start: string;
	equity_end: string;
	retained_earnings: string;
	ebitda: string;
	ebitda_growth: string;
	revenue: string;
	revenue_growth: string;
	debt_service_coverage_ratio: string;
	total_debt: string;
	net_debt_issued: string;
}

export interface RepaymentPlanForMonth {
	interest_paid: string;
	principal_paid: string;
	starting_balance: string;
	ending_balance: string;
	total_interest_paid: string;
}

export interface AdditionalLoanKPIs {
	return_on_invested_capital: string;
	net_income: string;
	return_on_equity: string;
	total_assets: string;
	total_debt: string;
	total_liabilities: string;
	return_on_assets: string;
	interest_expense: string;
	cost_of_debt_and_preferred_stock: string;
	after_tax_cost_of_debt: string;
	weighted_average_cost_of_capital: string;
	value_spread: string;
	current_liabilities: string;
	working_capital: string;
	working_capital_growth: string;
	working_capital_growth_to_revenue_growth: string;
	current_ratio: string;
	quick_ratio: string;
	equity_ratio: string;
	debt_to_equity_ratio: string;
	solvency_ratio: string;
	debt_to_ebitda_ratio: string;
	liabilities_to_ebitda_ratio: string;
	total_debt_growth: string;
	debt_growth_to_ebitda_growth: string;
	leverage_index: string;
	times_interest_earned: string;
	interest_coverage_ratio: string;
	debt_service: string;
	debt_service_coverage_ratio: string;
	net_debt_service_coverage_ratio: string;
	relative_debt: string;
	fixed_charges_before_taxes: string;
	fixed_charge_coverage_ratio: string;
	requested_loan_amount_to_total_assets: string;
	requested_loan_amount_to_revenue: string;
	net_debt_growth: string;
}

export interface CompanyScoreRequest {
	scoring_config_id: string;
	financial_data_ids: string[];
	loan_amount?: string;
	loan_duration?: number;
}

export interface CompanyScore {
	company_score_id: string;
	company_id: string;
	scoring_config_id: string;
	scoring_config_name?: string;
	financial_data_ids: string[];
	benchmark_result: ScoringResultForPeriod[];
	final_score: number;
	final_mark: string;
	warnings: Warning[];
	pd_score: number;
	is_approved: boolean;
	/** @format datetime */
	approved_at?: string;
	approved_by_entity_id?: string;
	approved_by_entity_type?: string;
	approved_by_entity_name?: string;
	loan_amount?: string;
	loan_duration?: number;
	created_by_entity_id: string;
	created_by_entity_type: string;
	created_by_entity_name?: string;
	/** @format datetime */
	created_at?: string;
}

export interface CompanyScoreFilterValuesScoringConfig {
	scoring_config_id: string;
	scoring_config_name: string;
}

export interface CompanyScoreFilterValuesUser {
	entity_id: string;
	entity_type: string;
	entity_name: string;
}

export interface CompanyScoreFilterValues {
	scoring_configs: CompanyScoreFilterValuesScoringConfig[];
	users: CompanyScoreFilterValuesUser[];
}

export interface CompanyScoresResponse {
	pagination: Pagination;
	result: CompanyScore[];
	filter_values: CompanyScoreFilterValues;
}

export interface ScoringResult {
	scoring_id: number;
	application_id: string;
	product_id?: string;
	scoring_config_id?: string;
	interest_rate_id?: number;
	benchmark_result?: ScoringResultForPeriod[];
	final_score: number;
	final_mark: string;
	grid_rate?: string;
	is_rejected: boolean;
	warnings: Warning[];
	pd_score: number;
	is_deleted?: boolean;
	/** @format datetime */
	created_at?: string;
}

export interface ScoringResultForPeriod {
	period_start: number;
	period_end: number;
	benchmarking_scores: BenchmarkingScores;
	total_score: number;
	mark: string;
	warnings: Warning[];
}

export interface Warning {
	type: "red_flag" | "warning";
	message: string;
	code: string;
}

export interface BenchmarkingScores {
	solvency: Score;
	profitability: Score;
	liquidity: Score;
	leverage: Score;
	future_performance?: Score;
	efficiency: Score;
	additional_loan: Score;
}

export interface Score {
	value: number;
	mark: string;
}

export enum InviteStatus {
	NotInvited = "not_invited",
	NotAccepted = "not_accepted",
	Expired = "expired",
	Accepted = "accepted",
}

export type StatusAction = object;

export type StatusActionRequest = object;

export interface PartnerProfile {
	partner_id: string;
	created_at: string;
	email: string;
	entity_id: string;
	mobile_number: string;
	name: string;
	status: string;
	partner_key: string;
	interface_language?: string;
}

export enum Salutations {
	F = "F",
	M = "M",
}

export interface CompanyFromSearch {
	crefonummer?: string;
	identnummer?: string;
	name?: string;
	handelsName?: string;
	land?: CountryCode;
	bundesland?: string;
	plz?: string;
	ort?: string;
	strasseHausnummer?: string;
}

export interface CrefoResult {
	crefonummer?: string;
	identnummer?: string;
	name?: string;
	handelsName?: string;
	land?: CountryCode;
	bundesland?: string;
	plz?: string;
	ort?: string;
	strasseHausnummer?: string;
}

export interface Pricing {
	/** @format float */
	interest_rate: number;
	/** @format float */
	external_interest_rate: number;
	/** @format float */
	external_transaction_fee?: number;
	/** @format float */
	total_interest_rate: number;
	/** @format float */
	total_transaction_fee?: number;
	/** @format float */
	monthly_payment: number;
	/** @format float */
	prepayment_rate: number;
	grid_rate: number;
	minimum_interest_rate: number;
	hedging_rate: number;
	is_final_interest_rate: boolean;
	is_final_transaction_fee: boolean;
	external_pricing_allowed?: boolean;
	/** @format float */
	leasing_factor: number;
	service_rate: number;
	/** @format float */
	remaining_value: number;
	/** @format float */
	insurance_rate: number;
	offer_is_range: boolean;
	/** @format float */
	offer_rate_low: number;
	/** @format float */
	offer_rate_high: number;
	/** @format float */
	offer_representative_monthly_rate_low: number;
	/** @format float */
	offer_representative_monthly_rate_high: number;
	/** @format float */
	transaction_fee?: number;
}

export interface Address {
	street: string;
	house_number?: string;
	postcode: string;
	city: string;
	country: string;
}

export enum SignatoryProviderStatus {
	SUCCESS = "SUCCESS",
	SUCCESS_DATA_CHANGED = "SUCCESS_DATA_CHANGED",
	FRAUD_SUSPICION_CONFIRMED = "FRAUD_SUSPICION_CONFIRMED",
	IDENT_EXPIRED = "IDENT_EXPIRED",
	ABORTED = "ABORTED",
	REVIEW_PENDING = "REVIEW_PENDING",
	FRAUD_SUSPICION_PENDING = "FRAUD_SUSPICION_PENDING",
}

export interface AuditLog {
	event_id?: string;
	target_type?: string;
	target_id?: string;
	actor_type?: string;
	actor_id?: string;
	event_action?: string;
	event_name?: string;
	event_description?: string;
	event_data?: object;
	event_time?: string;
}

export interface CreditScore {
	solvency?: Score;
}

export interface CompanyLastCreditScore {
	type?: ReportTypeEnum;
	/** @format datetime */
	date?: string;
}

export interface CompanyCreditScore {
	lastReport?: CompanyLastCreditScore;
	score?: number;
	riskClassType?: string;
	riskClassValue?: string;
	fullReportState?: FullReportStateEnum;
	pd?: number;
	provider?: string;
	documents?: Document[];
}

export interface SignatoryLastCreditScore {
	type?: ReportTypeEnum;
	/** @format datetime */
	date?: string;
}

export interface SignatoryCreditScore {
	lastReport?: SignatoryLastCreditScore;
	score?: number;
	fullReportState?: FullReportStateEnum;
}

export interface UploadSuccessRequestParam {
	id: string;
	docId: string;
}

export interface UploadSuccessRequestBody {
	is_upload_successful?: boolean;
}

export enum ReportTypeEnum {
	Full = "full",
}

export enum FullReportStateEnum {
	NotRequested = "not_requested",
	Ready = "ready",
	NotReady = "not_ready",
}

export enum Currency {
	EUR = "EUR",
	CHF = "CHF",
}

export interface GlobalRateRequest {
	global_rate_name: GlobalRateName;
	global_rate_period: GlobalRatePeriod;
	/**
	 * A string representation of a percent value
	 * @example 0.0352
	 */
	global_rate_value: string;
	/**
	 * @format date
	 * @example 2021-10-31
	 */
	global_rate_effective_date: string;
}

export interface GlobalRate {
	global_rate_id: string;
	global_rate_name: GlobalRateName;
	global_rate_period: GlobalRatePeriod;
	/**
	 * A string representation of a percent value
	 * @example 0.0352
	 */
	global_rate_value: string;
	/**
	 * @format date
	 * @example 2021-10-31
	 */
	global_rate_effective_date: string;
	/** @example manual */
	global_rate_source: string;
	/** @format datetime */
	created_at: string;
}

export enum GlobalRateName {
	Euribor = "euribor",
}

export enum GlobalRatePeriod {
	Month = "month",
	Type3Months = "3months",
}

export interface Prepayment {
	amount: number;
	amortization_type: "principal_only";
	/**
	 * @format date
	 * @example 2021-10-31
	 */
	date: string;
	notes?: string;
}

export interface PrepaymentResp {
	loan_transaction: LoanTransaction;
	bank_transaction: BankTransaction;
}

export interface BoUser {
	/** @example bou_111 */
	id: string;
	/** @example testadmin@teylor.com */
	email: string;
	/** @example Test Admin */
	name: string;
	status?: BoUserStatus;
	is_enabled?: boolean;
	is_deleted?: boolean;
	/** @example true */
	is_mfa_enabled?: boolean;
	roles?: BoUserRole[];
}

export interface BoUserSimpleResponse {
	id: string;
	email: string;
	name: string;
}

export interface BoUserRole {
	/** @example role_id */
	id?: string;
	/** @example role_name */
	name?: string;
}

export enum BoUserStatus {
	Active = "active",
	Invited = "invited",
	Disabled = "disabled",
}

export interface BOUserGroupCreateRequest {
	description: string;
}

export interface BOUserGroupCreateResponse {
	group_id: string;
}

export interface BOUserGroupUpdateListRequest {
	bo_user_ids?: string[];
}

export interface BOUserGroupResponse {
	bouser_group_id: string;
	description?: string;
	/** @format date-time */
	created_at: string;
	is_deleted: boolean;
	bo_users?: BoUserSimpleResponse[];
}

export enum PartnerUserStatus {
	Active = "active",
	Invited = "invited",
	Disabled = "disabled",
}

export enum PartnerUserRole {
	RolePartnerAdmin = "role_partner_admin",
	RolePartnerUser = "role_partner_user",
}

export enum CountryCode {
	DE = "DE",
	AT = "AT",
	CH = "CH",
	AF = "AF",
	AX = "AX",
	AL = "AL",
	DZ = "DZ",
	AS = "AS",
	AD = "AD",
	AO = "AO",
	AI = "AI",
	AQ = "AQ",
	AG = "AG",
	AR = "AR",
	AM = "AM",
	AW = "AW",
	AU = "AU",
	AZ = "AZ",
	BS = "BS",
	BH = "BH",
	BD = "BD",
	BB = "BB",
	BY = "BY",
	BE = "BE",
	BZ = "BZ",
	BJ = "BJ",
	BM = "BM",
	BT = "BT",
	BO = "BO",
	BA = "BA",
	BW = "BW",
	BV = "BV",
	BR = "BR",
	IO = "IO",
	BN = "BN",
	BG = "BG",
	BF = "BF",
	BI = "BI",
	KH = "KH",
	CM = "CM",
	CA = "CA",
	CV = "CV",
	KY = "KY",
	CF = "CF",
	TD = "TD",
	CL = "CL",
	CN = "CN",
	CX = "CX",
	CC = "CC",
	CO = "CO",
	KM = "KM",
	CG = "CG",
	CD = "CD",
	CK = "CK",
	CR = "CR",
	CI = "CI",
	HR = "HR",
	CU = "CU",
	CY = "CY",
	CZ = "CZ",
	DK = "DK",
	DJ = "DJ",
	DM = "DM",
	DO = "DO",
	EC = "EC",
	EG = "EG",
	SV = "SV",
	GQ = "GQ",
	ER = "ER",
	EE = "EE",
	ET = "ET",
	FK = "FK",
	FO = "FO",
	FJ = "FJ",
	FI = "FI",
	FR = "FR",
	GF = "GF",
	PF = "PF",
	TF = "TF",
	GA = "GA",
	GM = "GM",
	GE = "GE",
	GH = "GH",
	GI = "GI",
	GR = "GR",
	GL = "GL",
	GD = "GD",
	GP = "GP",
	GU = "GU",
	GT = "GT",
	GG = "GG",
	GN = "GN",
	GW = "GW",
	GY = "GY",
	HT = "HT",
	HM = "HM",
	VA = "VA",
	HN = "HN",
	HK = "HK",
	HU = "HU",
	IS = "IS",
	IN = "IN",
	ID = "ID",
	IR = "IR",
	IQ = "IQ",
	IE = "IE",
	IM = "IM",
	IL = "IL",
	IT = "IT",
	JM = "JM",
	JP = "JP",
	JE = "JE",
	JO = "JO",
	KZ = "KZ",
	KE = "KE",
	KI = "KI",
	KP = "KP",
	KR = "KR",
	KW = "KW",
	KG = "KG",
	LA = "LA",
	LV = "LV",
	LB = "LB",
	LS = "LS",
	LR = "LR",
	LY = "LY",
	LI = "LI",
	LT = "LT",
	LU = "LU",
	MO = "MO",
	MK = "MK",
	MG = "MG",
	MW = "MW",
	MY = "MY",
	MV = "MV",
	ML = "ML",
	MT = "MT",
	MH = "MH",
	MQ = "MQ",
	MR = "MR",
	MU = "MU",
	YT = "YT",
	MX = "MX",
	FM = "FM",
	MD = "MD",
	MC = "MC",
	MN = "MN",
	MS = "MS",
	MA = "MA",
	MZ = "MZ",
	MM = "MM",
	NA = "NA",
	NR = "NR",
	NP = "NP",
	NL = "NL",
	AN = "AN",
	NC = "NC",
	NZ = "NZ",
	NI = "NI",
	NE = "NE",
	NG = "NG",
	NU = "NU",
	NF = "NF",
	MP = "MP",
	NO = "NO",
	OM = "OM",
	PK = "PK",
	PW = "PW",
	PS = "PS",
	PA = "PA",
	PG = "PG",
	PY = "PY",
	PE = "PE",
	PH = "PH",
	PN = "PN",
	PL = "PL",
	PT = "PT",
	PR = "PR",
	QA = "QA",
	RE = "RE",
	RO = "RO",
	RU = "RU",
	RW = "RW",
	SH = "SH",
	KN = "KN",
	LC = "LC",
	PM = "PM",
	VC = "VC",
	WS = "WS",
	SM = "SM",
	ST = "ST",
	SA = "SA",
	SN = "SN",
	CS = "CS",
	SC = "SC",
	SL = "SL",
	SG = "SG",
	SK = "SK",
	SI = "SI",
	SB = "SB",
	SO = "SO",
	ZA = "ZA",
	GS = "GS",
	ES = "ES",
	LK = "LK",
	SD = "SD",
	SR = "SR",
	SJ = "SJ",
	SZ = "SZ",
	SE = "SE",
	SY = "SY",
	TW = "TW",
	TJ = "TJ",
	TZ = "TZ",
	TH = "TH",
	TL = "TL",
	TG = "TG",
	TK = "TK",
	TO = "TO",
	TT = "TT",
	TN = "TN",
	TR = "TR",
	TM = "TM",
	TC = "TC",
	TV = "TV",
	UG = "UG",
	UA = "UA",
	AE = "AE",
	GB = "GB",
	US = "US",
	UM = "UM",
	UY = "UY",
	UZ = "UZ",
	VU = "VU",
	VE = "VE",
	VN = "VN",
	VG = "VG",
	VI = "VI",
	WF = "WF",
	EH = "EH",
	YE = "YE",
	ZM = "ZM",
	ZW = "ZW",
}

export enum ReportType {
	FullReport = "full_report",
}

export enum LoanTransactionType {
	Payment = "payment",
	InitialPayout = "initial_payout",
	Prepayment = "prepayment",
	AccruedInterest = "accrued_interest",
}

export enum Compartment {
	Compartment1 = "compartment_1",
	Compartment2 = "compartment_2",
	Compartment3 = "compartment_3",
}

export interface NextLoginStep {
	step?: MFAStep;
	mfa_device?: MFADevice;
}

export enum MFAStep {
	LoginStepNone = "login_step_none",
	LoginStepRegisterPendingMfaDevice = "login_step_register_pending_mfa_device",
	LoginStepAuthenticateWithMfaDevice = "login_step_authenticate_with_mfa_device",
	LoginStepAddNewMfaDevice = "login_step_add_new_mfa_device",
}

export enum MFADeviceType {
	Totp = "totp",
}

export interface MFADevice {
	/** @example mdfmfd_2OWIUCYzwoJdTLir7vp78WGPjv8_123 */
	mfa_device_id?: string;
	mfa_device_type?: MFADeviceType;
}

export interface MFADeviceCreate {
	/** @example mfd_2OWIUCYzwoJdTLir7vp78WGPjv8 */
	mfa_device_id?: string;
	mfa_device_type?: MFADeviceType;
	/** @example IQZ6BWE4XXNY4QUJ3KFWN25ICHOSXXMS */
	secret?: string;
	/** @example otpauth://totp/Teylor:mfd_2OWIUCYzwoJdTLir7vp78WGPjv8?algorithm=SHA512\u0026digits=6\u0026issuer=Teylor\u0026period=30\u0026secret=IQZ6BWE4XXNY4QUJ3KFWN25ICHOSXXMS */
	registration_url?: string;
	/** A base64 encoded PNG image of a QR code. The QR code contains the registration url and is the most common way of adding a TOTP MFA device. */
	registration_image?: string;
}

export enum RejectionReason {
	UserRejectedOffer = "user_rejected_offer",
	Unknown = "unknown",
	Other = "other",
}

export enum UserType {
	User = "user",
	Puser = "puser",
	Bouser = "bouser",
}

export interface IndustryCodeRequest {
	kind: string;
	code: string;
	level?: number;
	parent_code?: string;
}

export type IndustryCode = IndustryCodeRequest & {
	industry_code_id: string;
};

export interface IndustryCodeBlacklistItem {
	industry_code_id: string;
	max_value: number;
}

export interface IndustryCodeBlacklistRequest {
	industry_codes: IndustryCodeBlacklistItem[];
}

export type IndustryCodeBlacklist = IndustryCodeBlacklistRequest & {
	industry_code_blacklist_id: string;
};

export interface BankAccountBalance {
	/** @example bab_acb2SnHC1wHDSueWxbXSrZL2YPJN5N */
	bank_account_balance_id?: string;
	/** @example ba_2Smzv7lediHCxGG9av1bsQMA4In */
	bank_account_id?: string;
	/** @example Raisin Test Account 8a27...1daa */
	bank_account_name?: string;
	/**
	 * @format currency
	 * @example 9550000.00
	 */
	account_balance_current?: string;
	/**
	 * @format currency
	 * @example 0
	 */
	account_balance_available?: string;
	/**
	 * @format currency
	 * @example
	 */
	account_balance_locked?: string;
	currency?: Currency;
	/**
	 * @format date
	 * @example 2023-07-19
	 */
	effective_date?: string;
	/**
	 * @format datetime
	 * @example 2023-07-19T15:17:10.697383+02:00
	 */
	retrieved_at?: string;
	/**
	 * @format datetime
	 * @example 2023-07-19T15:17:10.697383+02:00
	 */
	created_at?: string;
}

export interface GlobalCompany {
	global_company_id: string;
	north_data_id?: string;
	/** @format datetime */
	north_data_last_updated_at?: string;
	elf_code?: string;
	phone?: string;
	fax?: string;
	email?: string;
	website?: string;
	description?: string;
	original_description?: string;
	original_description_lang_code?: string;
	business_status?: string;
	industry_codes?: object;
	name?: GlobalCompanyName;
	name_history?: GlobalCompanyNameHistoryItem[];
	address?: GlobalCompanyAddress;
	address_history?: GlobalCompanyAddressHistoryItem[];
	register?: GlobalCompanyRegister;
	register_history?: GlobalCompanyRegisterHistoryItem[];
	events?: GlobalCompanyEventItem[];
	persons?: GlobalCompanyPerson[];
}

export interface GlobalCompanyName {
	name: string;
	legal_form?: string;
}

export type GlobalCompanyNameHistoryItem = GlobalCompanyName & {
	/** @format datetime */
	effective_date?: string;
};

export interface GlobalCompanyAddress {
	country_code?: string;
	state?: string;
	post_code?: string;
	city?: string;
	street_line_1?: string;
	street_line_2?: string;
	extra?: string;
	latitude?: number;
	longitude?: number;
	formattedValue?: string;
}

export type GlobalCompanyAddressHistoryItem = GlobalCompanyAddress & {
	/** @format datetime */
	effective_date?: string;
};

export interface GlobalCompanyRegister {
	id?: string;
	unique_key?: string;
	country_code?: string;
	city?: string;
}

export type GlobalCompanyRegisterHistoryItem = GlobalCompanyRegister & {
	/** @format datetime */
	effective_date?: string;
};

export interface GlobalCompanyEventItem {
	description?: string;
	type?: string;
	/** @format datetime */
	date?: string;
}

export interface GlobalCompanyPerson {
	global_person_id: string;
	external_person_id: string;
	first_name: string;
	last_name: string;
	/** @format date */
	birthdate?: string;
	address?: GlobalCompanyAddress;
	created_at?: string;
	company_relation_description?: string;
	roles?: GlobalCompanyPersonRole[];
}

export interface GlobalCompanyPersonRole {
	global_company_id: string;
	global_person_id: string;
	role_group: string;
	role_name: string;
	role_type: string;
	/** @format date */
	role_date?: string;
}

export interface ConcentrationLimitsApplicationData {
	application_id: string;
	/** @format float */
	amount?: string;
	duration?: number;
	/** @format float */
	total_interest_rate?: string;
	/** @format float */
	hedging_rate?: string;
}

export type ConcentrationLimitsApplicationDataResponse = ConcentrationLimitsApplicationData & {
	company_name: string;
};

export interface ConcentrationLimitsResult {
	concentration_limits_result_id: string;
	/** @format float */
	pool_size: string;
	/** @format datetime */
	created_at: string;
	checks: ConcentrationLimitChecks;
	applications?: ConcentrationLimitsApplicationDataResponse[];
}

export interface ConcentrationLimitChecks {
	general?: ConcentrationLimitCheck[];
	nace_level_1?: ConcentrationLimitCheck[];
	nace_level_2?: ConcentrationLimitCheck[];
}

export interface ConcentrationLimitCheck {
	concentration_limits_result_id: string;
	concentration_limit_id: string;
	/** @format float */
	parameter_value?: string;
	/** @format float */
	parameter_excess_amount_value?: string;
	parameter_excess_amount_type?: "percent" | "currency" | "integer";
	is_condition_met?: boolean;
	concentration_limit_description: string;
	concentration_limit_type: "general" | "nace_level_1" | "nace_level_2";
	parameter_type: "percent" | "currency" | "integer";
	parameter_name: string;
	/** @format float */
	parameter_limit: string;
	index: number;
	/** @format float */
	parameter_difference_based_on_what_if_analysis?: string;
	parameter_improved_based_on_what_if_analysis?: boolean;
}

export namespace Partner {
	/**
	 * No description
	 * @tags account
	 * @name AccountLoginCreate
	 * @summary Log in as a partner
	 * @request POST:/partner/account/login
	 * @response `200` `string` successful login
	 */
	export namespace AccountLoginCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			email: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = string;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRegisterCreate
	 * @summary Register partner
	 * @request POST:/partner/account/register
	 * @response `200` `void` ok
	 */
	export namespace AccountRegisterCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			token: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountChangePasswordCreate
	 * @summary Change password
	 * @request POST:/partner/account/change_password
	 * @response `200` `void` ok
	 */
	export namespace AccountChangePasswordCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			old_password: string;
			new_password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRecoverCreate
	 * @summary Recover partner
	 * @request POST:/partner/account/recover
	 * @response `200` `void` ok
	 */
	export namespace AccountRecoverCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			token: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountResetCreate
	 * @summary Reset partner password
	 * @request POST:/partner/account/reset
	 * @response `200` `void` ok
	 */
	export namespace AccountResetCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			email: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountProfileList
	 * @summary Get partner profile info
	 * @request GET:/partner/account/profile
	 * @response `200` `PartnerProfile` partner profile info
	 */
	export namespace AccountProfileList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerProfile;
	}
	/**
	 * No description
	 * @name AccountProfilePartialUpdate
	 * @summary Update partner profile data
	 * @request PATCH:/partner/account/profile
	 */
	export namespace AccountProfilePartialUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			interface_language?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = any;
	} /**
 * No description
 * @tags company
 * @name CompanySearchCreate
 * @summary Search for a company
 * @request POST:/partner/company_search
 * @response `200` `{
  companyNameSuggestions?: (CrefoResult)[],

}` success
*/
	export namespace CompanySearchCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			name: string;
			country?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			companyNameSuggestions?: CrefoResult[];
		};
	} /**
 * No description
 * @tags company
 * @name GlobalCompanySearchCreate
 * @summary Search for a companies in 3rd party  global sources ( northdata )
 * @request POST:/partner/global/company_search
 * @response `200` `{
  pagination?: Pagination,
  result?: (GlobalCompany)[],

}` companies matching
*/
	export namespace GlobalCompanySearchCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			name?: string;
			address?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: GlobalCompany[];
		};
	}
	/**
	 * No description
	 * @tags company
	 * @name GlobalCompanyList
	 * @summary Search for a company in 3rd party  global sources ( northdata )
	 * @request GET:/partner/global/company
	 * @response `200` `GlobalCompany` company found
	 */
	export namespace GlobalCompanyList {
		export type RequestParams = {};
		export type RequestQuery = {
			name?: string;
			address?: string;
			north_data_id?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = GlobalCompany;
	}
	/**
	 * No description
	 * @tags company
	 * @name PortfolioCreate
	 * @summary Add company to partner portfolio
	 * @request POST:/partner/portfolio
	 * @response `201` `PartnerPortfolioItem` new portfolio item
	 */
	export namespace PortfolioCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			north_data_id?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = PartnerPortfolioItem;
	} /**
 * No description
 * @tags company
 * @name PortfolioList
 * @summary get partner portfolio
 * @request GET:/partner/portfolio
 * @response `200` `{
  pagination?: Pagination,
  companies?: (PartnerPortfolioItem)[],

}` success
*/
	export namespace PortfolioList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			companies?: PartnerPortfolioItem[];
		};
	}
	/**
	 * No description
	 * @tags company
	 * @name PortfolioDelete
	 * @summary Delete company from partner portfolio
	 * @request DELETE:/partner/portfolio/{id}
	 * @response `200` `void` ok
	 */
	export namespace PortfolioDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags company
	 * @name PortfolioDetail
	 * @summary get single company from partner portfolio
	 * @request GET:/partner/portfolio/{id}
	 * @response `200` `PartnerPortfolioItem` portfolio item
	 */
	export namespace PortfolioDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerPortfolioItem;
	}
	/**
	 * @description Get company by id
	 * @tags companies
	 * @name CompaniesDetail
	 * @request GET:/partner/companies/{companyId}
	 * @response `200` `AdminCompanyResponse` success
	 */
	export namespace CompaniesDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminCompanyResponse;
	}
	/**
	 * No description
	 * @tags company
	 * @name KeyConfigDetail
	 * @summary Get partner config by subdomain key
	 * @request GET:/partner/key/{key}/config
	 * @response `200` `PartnerConfig` partner config
	 */
	export namespace KeyConfigDetail {
		export type RequestParams = {
			key: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfig;
	}
	/**
	 * No description
	 * @name ApplicationsList
	 * @summary Get partner applications
	 * @request GET:/partner/applications
	 * @response `200` `ApplicationsList` partner applications
	 */
	export namespace ApplicationsList {
		export type RequestParams = {};
		export type RequestQuery = {
			text?: string;
			status?: string;
			provider?: string;
			allBanksLoanPurpose?: string;
			status_owner_id?: string;
			account_manager_id?: string;
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationsList;
	} /**
 * No description
 * @name ApplicationsCreate
 * @summary Create a new application
 * @request POST:/partner/applications
 * @response `200` `{
  application: PartnerApplication,

}` application created
*/
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = PartnerApplication;
		export type RequestHeaders = {};
		export type ResponseBody = {
			application: PartnerApplication;
		};
	}
	/**
	 * No description
	 * @name ApplicationsDetail
	 * @summary Get partner application info
	 * @request GET:/partner/applications/{application_id}
	 * @response `200` `PartnerApplication` application info
	 */
	export namespace ApplicationsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPartialUpdate
	 * @summary update application
	 * @request PATCH:/partner/applications/{application_id}
	 * @response `200` `PartnerApplication` updated application
	 */
	export namespace ApplicationsPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			taxIdNumber?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = PartnerApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsNextStatusValidateDetail
	 * @summary Query if status can be changed, get all failed requirents for status change
	 * @request GET:/partner/applications/{application_id}/next_status_validate
	 * @response `200` `StatusPendingActions` status change is possible if no errors retunred
	 */
	export namespace ApplicationsNextStatusValidateDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusPendingActions;
	}
	/**
	 * No description
	 * @name ApplicationsBankinfoPartialUpdate
	 * @request PATCH:/partner/applications/{application_id}/bankinfo
	 * @response `200` `PartnerApplication` application
	 */
	export namespace ApplicationsBankinfoPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			bankName?: string;
			iban?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = PartnerApplication;
	} /**
 * No description
 * @name ApplicationsSignatoriesDetail
 * @summary Get signatories for application
 * @request GET:/partner/applications/{application_id}/signatories
 * @response `200` `{
  signatories: (SignatoryResponse)[],
  warnings: ({
  warning_id: string,
  resource_id: string,
  text_en: string,

})[],

}` application signatories
*/
	export namespace ApplicationsSignatoriesDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			signatories: SignatoryResponse[];
			warnings: {
				warning_id: string;
				resource_id: string;
				text_en: string;
			}[];
		};
	}
	/**
	 * No description
	 * @name ApplicationsSignatoriesCreate
	 * @summary Add signatory to application
	 * @request POST:/partner/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` created signatory object
	 */
	export namespace ApplicationsSignatoriesCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			data?: Signatory[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	} /**
 * No description
 * @name ApplicationsSignatoriesInviteCreate
 * @summary Invite signatory as a user to application
 * @request POST:/partner/applications/{application_id}/signatories/{signatory_id}/invite
 * @response `200` `{
  email_sent?: boolean,
  invite_status?: InviteStatus,

}` invitation and email status
*/
	export namespace ApplicationsSignatoriesInviteCreate {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @format email */
			email?: string;
			/** @example DE */
			region?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = {
			email_sent?: boolean;
			invite_status?: InviteStatus;
		};
	}
	/**
	 * No description
	 * @name ApplicationsSignatoriesInviteDelete
	 * @summary Delete signatory invite, unlink user from application
	 * @request DELETE:/partner/applications/{application_id}/signatories/{signatory_id}/invite
	 * @response `200` `void` deleted
	 */
	export namespace ApplicationsSignatoriesInviteDelete {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @name ApplicationsPricingPartialUpdate
	 * @summary Update application pricing
	 * @request PATCH:/partner/applications/{application_id}/pricing
	 * @response `200` `Pricing` updated pricing info
	 */
	export namespace ApplicationsPricingPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @format float */
			external_interest_rate?: number;
			external_transaction_fee?: number;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Pricing;
	}
	/**
	 * No description
	 * @name ApplicationsPricingPrecalculatePartialUpdate
	 * @summary Calculate application pricing without any updates (read-only)
	 * @request PATCH:/partner/applications/{application_id}/pricing_precalculate
	 * @response `200` `Pricing` updated pricing info
	 */
	export namespace ApplicationsPricingPrecalculatePartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @format float */
			external_interest_rate?: number;
			/** @format float */
			external_transaction_fee?: number;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Pricing;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsCreate
	 * @summary Create finance object
	 * @request POST:/partner/applications/{application_id}/finance_objects
	 * @response `201` `FinanceObjectCreateResponse` created finance object
	 */
	export namespace ApplicationsFinanceObjectsCreate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectCreateResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDetail
	 * @summary Get application finance objects
	 * @request GET:/partner/applications/{application_id}/finance_objects
	 * @response `200` `(FinanceObjectGetResponse)[]` list of finance objects
	 */
	export namespace ApplicationsFinanceObjectsDetail {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectGetResponse[];
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsUpdate
	 * @summary Update finance object
	 * @request PUT:/partner/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` updated finance object
	 */
	export namespace ApplicationsFinanceObjectsUpdate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDelete
	 * @summary Delete finance object
	 * @request DELETE:/partner/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` deleted finance object
	 */
	export namespace ApplicationsFinanceObjectsDelete {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @name SignatoriesPartialUpdate
	 * @summary Update signatory details
	 * @request PATCH:/partner/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` updated signatory object
	 */
	export namespace SignatoriesPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryWithId;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesKycDetail
	 * @summary Get signatory kyc status
	 * @request GET:/partner/signatories/{signatory_id}/kyc
	 * @response `200` `SignatoryKycStatus` signatory kyc status
	 */
	export namespace SignatoriesKycDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryKycStatus;
	} /**
 * No description
 * @name CompanySearchList
 * @summary Search company
 * @request GET:/partner/company-search
 * @response `200` `{
  error?: string,
  query?: string,
  suggestionCategory?: string,
  companyNameSuggestions?: (CompanyFromSearch)[],

}` companies results
*/
	export namespace CompanySearchList {
		export type RequestParams = {};
		export type RequestQuery = {
			name?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			error?: string;
			query?: string;
			suggestionCategory?: string;
			companyNameSuggestions?: CompanyFromSearch[];
		};
	} /**
 * No description
 * @name FilterValuesList
 * @summary get possible statuses, types for a partner
 * @request GET:/partner/filter_values
 * @response `200` `{
  all_statuses?: (ApplicationStatuses)[],
  existing_statuses?: (ApplicationStatuses)[],
  application_types?: ("general_liquidity" | "object_financing" | "real_estate" | "factoring" | "other")[],

}` statuses
*/
	export namespace FilterValuesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			all_statuses?: ApplicationStatuses[];
			existing_statuses?: ApplicationStatuses[];
			application_types?: (
				| "general_liquidity"
				| "object_financing"
				| "real_estate"
				| "factoring"
				| "other"
			)[];
		};
	} /**
 * @description Search companies
 * @tags companies
 * @name CompaniesList
 * @request GET:/partner/companies
 * @response `200` `{
  pagination?: Pagination,
  companies?: (CompanySearchResult)[],

}` success
*/
	export namespace CompaniesList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			/** company name search */
			name?: string;
			/** company location ( city ) search */
			location?: string;
			/** company country search */
			country?: string;
			/** company address street search */
			street?: string;
			/** company address house_number search */
			house_number?: string;
			/** company address postcode search */
			post_code?: string;
			/** company hr_number search */
			hr_number?: string;
			/** text search */
			text?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			companies?: CompanySearchResult[];
		};
	}
	/**
	 * @description Get list of documents for the company (global and local)
	 * @tags documents
	 * @name CompaniesDocumentsDetail
	 * @request GET:/partner/companies/{company_id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the company
	 */
	export namespace CompaniesDocumentsDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			related_entity_id?: string;
			related_entity_kind?: RelatedEntityKind;
			/** comma-separated list of DocumentCategoryName */
			document_category?: DocumentCategoryName | DocumentCategoryName[];
			/** comma-separated list of DocumentTypeName */
			document_type?: DocumentTypeName | DocumentTypeName[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description update partner user
	 * @tags pusers
	 * @name PusersPartialUpdate
	 * @request PATCH:/partner/pusers/{id}
	 * @response `200` `void` updated partner user
	 */
	export namespace PusersPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			email?: string;
			name?: string;
			mobile_number?: string;
			role_id?: string;
			interface_language?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags pusers
	 * @name PusersDelete
	 * @summary Delete the puser (partner user) account
	 * @request DELETE:/partner/pusers/{id}
	 * @response `200` `void` successfully deleted the puser
	 */
	export namespace PusersDelete {
		export type RequestParams = {
			id: string;
			puserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get partner theme config for FE
	 * @tags partner
	 * @name KeyConfigDetail2
	 * @request GET:/partner/key/{id}/config
	 * @originalName keyConfigDetail
	 * @duplicate
	 * @response `200` `PartnerConfig` partner theme config
	 */
	export namespace KeyConfigDetail2 {
		export type RequestParams = {
			/** `partner_key` column value from `partners` table */
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfig;
	}
	/**
	 * @description Get list of documents for the application
	 * @tags documents
	 * @name ApplicationsDocumentsDetail
	 * @request GET:/partner/applications/{id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the application
	 */
	export namespace ApplicationsDocumentsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			related_entity_id?: string;
			related_entity_kind?: RelatedEntityKind;
			/** comma-separated list of DocumentCategoryName */
			document_category?: DocumentCategoryName | DocumentCategoryName[];
			/** comma-separated list of DocumentTypeName */
			document_type?: DocumentTypeName | DocumentTypeName[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description Upload a document for the application
	 * @tags documents
	 * @name ApplicationsDocumentsCreate
	 * @request POST:/partner/applications/{id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace ApplicationsDocumentsCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name ApplicationsDocumentsDownloadUrlDetail
	 * @request GET:/partner/applications/{appId}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace ApplicationsDocumentsDownloadUrlDetail {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
	/**
	 * @description Update document type
	 * @tags documents
	 * @name ApplicationsDocumentsTypePartialUpdate
	 * @request PATCH:/partner/applications/{appId}/documents/{docId}/type
	 * @response `200` `void` Updated document type
	 */
	export namespace ApplicationsDocumentsTypePartialUpdate {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentTypePayload;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Admin {
	/**
	 * No description
	 * @tags account
	 * @name AccountLoginCreate
	 * @summary Log in as an admin
	 * @request POST:/admin/account/login
	 * @response `200` `BOUserProfile` successful login
	 */
	export namespace AccountLoginCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			email: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = BOUserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRegisterCreate
	 * @summary register new user
	 * @request POST:/admin/account/register
	 * @response `200` `void` account created
	 */
	export namespace AccountRegisterCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRecoverCreate
	 * @summary Recover admin
	 * @request POST:/admin/account/recover
	 * @response `200` `void` ok
	 */
	export namespace AccountRecoverCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			token: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountResetCreate
	 * @summary Reset bouser password
	 * @request POST:/admin/account/reset
	 * @response `200` `void` ok
	 */
	export namespace AccountResetCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			email: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountChangePasswordCreate
	 * @summary Change bouser password
	 * @request POST:/admin/account/change_password
	 * @response `200` `void` ok
	 * @response `400` `void` bad request, weak new password
	 * @response `401` `void` unauthorized, bad old password
	 */
	export namespace AccountChangePasswordCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			old_password: string;
			password: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @name AccountMfaDevicesCreate
	 * @summary Create a new MFA device
	 * @request POST:/admin/account/mfa_devices
	 * @response `200` `MFADeviceCreate` Successful create MFA device. Use the information provided to complete registration.
	 */
	export namespace AccountMfaDevicesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = MFADeviceCreate;
	}
	/**
	 * No description
	 * @name AccountMfaDevicesRegisterCreate
	 * @summary Register a MFA device
	 * @request POST:/admin/account/mfa_devices/{device_id}/register
	 * @response `200` `void` Successfully registered MFA device.
	 */
	export namespace AccountMfaDevicesRegisterCreate {
		export type RequestParams = {
			deviceId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			device_type: MFADeviceType;
			/** @example 123456 */
			code: string;
			/** @example true */
			remember: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @name AccountMfaDevicesAuthenticateCreate
	 * @summary Authenticate with a MFA device
	 * @request POST:/admin/account/mfa_devices/{device_id}/authenticate
	 * @response `200` `void` Successfully authenticated with a MFA device.
	 */
	export namespace AccountMfaDevicesAuthenticateCreate {
		export type RequestParams = {
			deviceId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			device_type: MFADeviceType;
			/** @example 123456 */
			code: string;
			/** @example true */
			remember: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags pipeline
	 * @name StatisticsApplicationPipelineList
	 * @summary Get the pipeline statistics
	 * @request GET:/admin/statistics/application_pipeline
	 * @response `200` `PipelineStats` successful data
	 */
	export namespace StatisticsApplicationPipelineList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PipelineStats;
	}
	/**
	 * No description
	 * @tags account
	 * @name ProfileList
	 * @summary Get the admin's profile
	 * @request GET:/admin/profile
	 * @response `200` `BOUserProfile` successful login
	 */
	export namespace ProfileList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserProfile;
	}
	/**
	 * No description
	 * @name ProfilePartialUpdate
	 * @summary Update profile data
	 * @request PATCH:/admin/profile
	 */
	export namespace ProfilePartialUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			interface_language?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = any;
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesList
	 * @summary Get features
	 * @request GET:/admin/features
	 * @response `200` `(Feature)[]` return all features
	 */
	export namespace FeaturesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Feature[];
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesCreate
	 * @summary create new feature
	 * @request POST:/admin/features
	 * @response `200` `Feature` created feature
	 */
	export namespace FeaturesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = FeatureRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Feature;
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesDetail
	 * @request GET:/admin/features/{feature_id}
	 * @response `200` `Feature` feature
	 */
	export namespace FeaturesDetail {
		export type RequestParams = {
			featureId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Feature;
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesPartialUpdate
	 * @request PATCH:/admin/features/{feature_id}
	 * @response `200` `Feature` feature
	 */
	export namespace FeaturesPartialUpdate {
		export type RequestParams = {
			featureId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			name?: FeatureName;
			description?: string;
			value?: FeatureValues;
			is_active?: boolean;
			is_deleted?: boolean;
			is_public?: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Feature;
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesNameDetail
	 * @request GET:/admin/features/name/{feature_name}
	 * @response `200` `Feature` feature
	 */
	export namespace FeaturesNameDetail {
		export type RequestParams = {
			featureName: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Feature;
	}
	/**
	 * No description
	 * @tags feature
	 * @name FeaturesActivatePartialUpdate
	 * @request PATCH:/admin/features/{feature_id}/activate/{feature_name}
	 * @response `200` `Feature` activated feature
	 */
	export namespace FeaturesActivatePartialUpdate {
		export type RequestParams = {
			featureId: number;
			featureName: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Feature;
	}
	/**
	 * @description Get all system configs
	 * @tags system config
	 * @name SystemConfigsList
	 * @request GET:/admin/system_configs
	 * @response `200` `(SystemConfig)[]` success
	 */
	export namespace SystemConfigsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SystemConfig[];
	}
	/**
	 * @description Create system config
	 * @tags system config
	 * @name SystemConfigsCreate
	 * @request POST:/admin/system_configs
	 * @response `200` `SystemConfig` success
	 */
	export namespace SystemConfigsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			name: string;
			description?: string;
			value: SystemConfigValue;
		};
		export type RequestHeaders = {};
		export type ResponseBody = SystemConfig;
	}
	/**
	 * @description Get system config by id
	 * @tags system config
	 * @name SystemConfigsDetail
	 * @request GET:/admin/system_configs/{id}
	 * @response `200` `SystemConfig` success
	 */
	export namespace SystemConfigsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SystemConfig;
	}
	/**
	 * @description Get system config by name
	 * @tags system config
	 * @name SystemConfigsNameDetail
	 * @request GET:/admin/system_configs/name/{name}
	 * @response `200` `SystemConfig` success
	 */
	export namespace SystemConfigsNameDetail {
		export type RequestParams = {
			name: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SystemConfig;
	}
	/**
	 * @description Activate system config by id and name
	 * @tags system config
	 * @name SystemConfigsActivatePartialUpdate
	 * @request PATCH:/admin/system_configs/{id}/activate/{name}
	 * @response `200` `SystemConfig` success
	 */
	export namespace SystemConfigsActivatePartialUpdate {
		export type RequestParams = {
			id: string;
			name: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SystemConfig;
	}
	/**
	 * No description
	 * @tags custom_fields_config
	 * @name CustomFieldsConfigsList
	 * @summary Get custom fields configs
	 * @request GET:/admin/custom_fields_configs
	 * @response `200` `(CustomFieldsConfig)[]` return all custom fields configs
	 */
	export namespace CustomFieldsConfigsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CustomFieldsConfig[];
	}
	/**
	 * No description
	 * @tags custom_fields_config
	 * @name CustomFieldsConfigsCreate
	 * @summary create new custom fields config
	 * @request POST:/admin/custom_fields_configs
	 * @response `200` `CustomFieldsConfig` created custom fields config
	 */
	export namespace CustomFieldsConfigsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CustomFieldsConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = CustomFieldsConfig;
	}
	/**
	 * No description
	 * @tags custom_fields_config
	 * @name CustomFieldsConfigsDetail
	 * @request GET:/admin/custom_fields_configs/{custom_fields_config_id}
	 * @response `200` `CustomFieldsConfig` custom fields config
	 */
	export namespace CustomFieldsConfigsDetail {
		export type RequestParams = {
			customFieldsConfigId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CustomFieldsConfig;
	}
	/**
	 * No description
	 * @tags notification_channel
	 * @name NotificationChannelsCreate
	 * @summary create new notification channel
	 * @request POST:/admin/notification_channels
	 * @response `200` `NotificationChannel` created notification channel
	 */
	export namespace NotificationChannelsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = NotificationChannelRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationChannel;
	}
	/**
	 * No description
	 * @tags notification_channel
	 * @name NotificationChannelsList
	 * @request GET:/admin/notification_channels
	 * @response `200` `(NotificationChannel)[]` return all notification channels
	 */
	export namespace NotificationChannelsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationChannel[];
	}
	/**
	 * No description
	 * @tags notification_channel
	 * @name NotificationChannelsDetail
	 * @request GET:/admin/notification_channels/{notification_channel_id}
	 * @response `200` `NotificationChannel` notification channel
	 */
	export namespace NotificationChannelsDetail {
		export type RequestParams = {
			notificationChannelId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationChannel;
	}
	/**
	 * No description
	 * @tags notification_channel
	 * @name NotificationChannelsPartialUpdate
	 * @request PATCH:/admin/notification_channels/{notification_channel_id}
	 * @response `200` `NotificationChannel` notification_channel
	 */
	export namespace NotificationChannelsPartialUpdate {
		export type RequestParams = {
			notificationChannelId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = NotificationChannelRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationChannel;
	}
	/**
	 * No description
	 * @tags notification_channel
	 * @name NotificationChannelsDelete
	 * @summary Delete notification channel
	 * @request DELETE:/admin/notification_channels/{notification_channel_id}
	 * @response `200` `void` deleted notification channel
	 */
	export namespace NotificationChannelsDelete {
		export type RequestParams = {
			notificationChannelId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags notification_template
	 * @name NotificationTemplatesCreate
	 * @summary create new notification template
	 * @request POST:/admin/notification_templates
	 * @response `200` `NotificationTemplate` created notification template
	 */
	export namespace NotificationTemplatesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = NotificationTemplateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationTemplate;
	}
	/**
	 * No description
	 * @tags notification_template
	 * @name NotificationTemplatesList
	 * @request GET:/admin/notification_templates
	 * @response `200` `(NotificationTemplate)[]` return all notification templates
	 */
	export namespace NotificationTemplatesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationTemplate[];
	}
	/**
	 * No description
	 * @tags notification_template
	 * @name NotificationTemplatesDetail
	 * @request GET:/admin/notification_templates/{notification_template_id}
	 * @response `200` `NotificationTemplate` notification template
	 */
	export namespace NotificationTemplatesDetail {
		export type RequestParams = {
			notificationTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationTemplate;
	}
	/**
	 * No description
	 * @tags notification_template
	 * @name NotificationTemplatesPartialUpdate
	 * @request PATCH:/admin/notification_templates/{notification_template_id}
	 * @response `200` `NotificationTemplate` notification_template
	 */
	export namespace NotificationTemplatesPartialUpdate {
		export type RequestParams = {
			notificationTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = NotificationTemplateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationTemplate;
	}
	/**
	 * No description
	 * @tags notification_template
	 * @name NotificationTemplatesDelete
	 * @summary Delete notification template
	 * @request DELETE:/admin/notification_templates/{notification_template_id}
	 * @response `200` `void` deleted notification template
	 */
	export namespace NotificationTemplatesDelete {
		export type RequestParams = {
			notificationTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags notification_subscription
	 * @name NotificationSubscriptionsCreate
	 * @summary create new notification subscription
	 * @request POST:/admin/notification_subscriptions
	 * @response `200` `NotificationSubscription` created notification subscription
	 */
	export namespace NotificationSubscriptionsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = NotificationSubscriptionRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationSubscription;
	}
	/**
	 * No description
	 * @tags notification_subscription
	 * @name NotificationSubscriptionsList
	 * @request GET:/admin/notification_subscriptions
	 * @response `200` `(NotificationSubscription)[]` return all notification subscriptions
	 */
	export namespace NotificationSubscriptionsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationSubscription[];
	}
	/**
	 * No description
	 * @tags notification_subscription
	 * @name NotificationSubscriptionsDetail
	 * @request GET:/admin/notification_subscriptions/{notification_subscription_id}
	 * @response `200` `NotificationSubscription` notification subscription
	 */
	export namespace NotificationSubscriptionsDetail {
		export type RequestParams = {
			notificationSubscriptionId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationSubscription;
	}
	/**
	 * No description
	 * @tags notification_subscription
	 * @name NotificationSubscriptionsPartialUpdate
	 * @request PATCH:/admin/notification_subscriptions/{notification_subscription_id}
	 * @response `200` `NotificationSubscription` notification_subscription
	 */
	export namespace NotificationSubscriptionsPartialUpdate {
		export type RequestParams = {
			notificationSubscriptionId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = NotificationSubscriptionRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NotificationSubscription;
	}
	/**
	 * No description
	 * @tags notification_subscription
	 * @name NotificationSubscriptionsDelete
	 * @summary Delete notification subscription
	 * @request DELETE:/admin/notification_subscriptions/{notification_subscription_id}
	 * @response `200` `void` deleted notification subscription
	 */
	export namespace NotificationSubscriptionsDelete {
		export type RequestParams = {
			notificationSubscriptionId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesCreate
	 * @request POST:/admin/document_templates
	 * @response `200` `void` ok
	 */
	export namespace DocumentTemplatesCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			type:
				| "teylor_capital_loan_contract"
				| "teylor_capital_guarantor_contract"
				| "teylor_capital_payment_confirmation"
				| "teylor_capital_payment_plan"
				| "teylor_capital_gtc"
				| "teylor_capital_order_form"
				| "teylor_capital_ubo_form";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = File;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesLastList
	 * @request GET:/admin/document_templates/last
	 * @response `200` `DocumentTemplate` document info
	 */
	export namespace DocumentTemplatesLastList {
		export type RequestParams = {};
		export type RequestQuery = {
			type:
				| "teylor_capital_loan_contract"
				| "teylor_capital_guarantor_contract"
				| "teylor_capital_payment_confirmation"
				| "teylor_capital_payment_plan"
				| "teylor_capital_gtc"
				| "teylor_capital_order_form"
				| "teylor_capital_ubo_form";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTemplate;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesDetail
	 * @request GET:/admin/document_templates/{document_template_id}
	 * @response `200` `DocumentTemplate` document info
	 */
	export namespace DocumentTemplatesDetail {
		export type RequestParams = {
			documentTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTemplate;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesDelete
	 * @request DELETE:/admin/document_templates/{document_template_id}
	 * @response `200` `void` document info
	 */
	export namespace DocumentTemplatesDelete {
		export type RequestParams = {
			documentTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesBodyDetail
	 * @request GET:/admin/document_templates/{document_template_id}/body
	 * @response `200` `File` document info
	 */
	export namespace DocumentTemplatesBodyDetail {
		export type RequestParams = {
			documentTemplateId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags document_template
	 * @name DocumentTemplatesLastBodyList
	 * @request GET:/admin/document_templates/last/body
	 * @response `200` `File` document info
	 */
	export namespace DocumentTemplatesLastBodyList {
		export type RequestParams = {};
		export type RequestQuery = {
			type:
				| "teylor_capital_loan_contract"
				| "teylor_capital_guarantor_contract"
				| "teylor_capital_payment_confirmation"
				| "teylor_capital_payment_plan"
				| "teylor_capital_gtc"
				| "teylor_capital_order_form"
				| "teylor_capital_ubo_form";
			product_id: string;
			language: string;
			country: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags portfolio
	 * @name GetPortfolioExport
	 * @summary get portfolio export as a zip archive containing csv files
	 * @request GET:/admin/portfolio_export
	 * @response `200` `File` zip archive containing csv files
	 */
	export namespace GetPortfolioExport {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * @description start ocr file scan
	 * @tags ocr
	 * @name StartOcrFileScan
	 * @request POST:/admin/ocr
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace StartOcrFileScan {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = OcrFileCreate;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name UpdateOcrFileInfo
	 * @summary update ocr file info
	 * @request PATCH:/admin/ocr/{document_id}
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace UpdateOcrFileInfo {
		export type RequestParams = {
			documentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = OcrFileUpdateInfo;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name GetOcrFileInfo
	 * @summary get ocr file info
	 * @request GET:/admin/ocr/{document_id}
	 * @response `200` `OcrFileResponse` created ocr file object
	 */
	export namespace GetOcrFileInfo {
		export type RequestParams = {
			documentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name RescanOcrFile
	 * @summary rescan ocr file
	 * @request POST:/admin/ocr/{document_id}/rescan
	 * @response `200` `void` triggered the rescanning process
	 */
	export namespace RescanOcrFile {
		export type RequestParams = {
			documentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name GetOcrFileResults
	 * @summary get ocr file results
	 * @request GET:/admin/ocr/{document_id}/results
	 * @response `200` `OcrFileResults` created ocr file object
	 */
	export namespace GetOcrFileResults {
		export type RequestParams = {
			documentId: string;
		};
		export type RequestQuery = {
			format?: "xlsx" | "json";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResults;
	}
	/**
	 * No description
	 * @tags ocr
	 * @name GetOcrFilesByApplicationId
	 * @summary get ocr files for application
	 * @request GET:/admin/applications/{application_id}/ocr/documents
	 * @response `200` `(OcrFileResponse)[]` ocr files for application
	 */
	export namespace GetOcrFilesByApplicationId {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse[];
	}
	/**
	 * No description
	 * @tags ocr
	 * @name GetOcrFilesByCompanyId
	 * @summary get ocr files for company
	 * @request GET:/admin/companies/{company_id}/ocr/documents
	 * @response `200` `(OcrFileResponse)[]` ocr files for company
	 */
	export namespace GetOcrFilesByCompanyId {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = OcrFileResponse[];
	}
	/**
	 * @description calculate concentration limits
	 * @tags concentration_limits
	 * @name ConcentrationLimitsCalculateCreate
	 * @request POST:/admin/concentration_limits/calculate
	 * @response `200` `ConcentrationLimitsResult` concentration limits calculation result
	 */
	export namespace ConcentrationLimitsCalculateCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			application_data?: ConcentrationLimitsApplicationData[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = ConcentrationLimitsResult;
	}
	/**
	 * @description get latest concentration limits result
	 * @tags concentration_limits
	 * @name ConcentrationLimitsResultList
	 * @request GET:/admin/concentration_limits/result
	 * @response `200` `ConcentrationLimitsResult` concentration limits calculation result
	 */
	export namespace ConcentrationLimitsResultList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ConcentrationLimitsResult;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsList
	 * @summary List applications from admin panel
	 * @request GET:/admin/applications
	 * @response `200` `ApplicationsList` applications list
	 */
	export namespace ApplicationsList {
		export type RequestParams = {};
		export type RequestQuery = {
			text?: string;
			status?: string;
			provider?: string;
			partner_id?: string;
			partner_name?: string;
			product_id?: string;
			product_name?: string;
			allBanksLoanPurpose?: string;
			status_owner_id?: string;
			account_manager_id?: string;
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			sort_by?: AppSortBy;
			sort_order?: "ascend" | "descend";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationsList;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new application on behalf of user
	 * @request POST:/admin/applications
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationForUserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsDetail
	 * @summary Get application item from admin panel
	 * @request GET:/admin/applications/{application_id}
	 * @response `200` `AdminApplication` application
	 */
	export namespace ApplicationsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPartialUpdate
	 * @summary update application
	 * @request PATCH:/admin/applications/{application_id}
	 * @response `200` `Application` updated application
	 */
	export namespace ApplicationsPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			language?: string;
			purposeDescription?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsInviteCreate
	 * @summary invite new or existing user to connect to application as account manager
	 * @request POST:/admin/applications/{application_id}/invite
	 * @response `200` `void` ok
	 */
	export namespace ApplicationsInviteCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @format email */
			email: string;
			region?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsUsersDetail
	 * @summary get list of users connected to an application
	 * @request GET:/admin/applications/{application_id}/users
	 * @response `200` `UserProfileList` ok
	 */
	export namespace ApplicationsUsersDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserProfileList;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsUsersDelete
	 * @summary disconnect user from application
	 * @request DELETE:/admin/applications/{application_id}/users/{user_id}
	 * @response `200` `void` ok
	 */
	export namespace ApplicationsUsersDelete {
		export type RequestParams = {
			applicationId: string;
			userId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsParametersPartialUpdate
	 * @summary update applications parameters
	 * @request PATCH:/admin/applications/{application_id}/parameters
	 * @response `200` `ApplicationParametersResponse` updated application
	 */
	export namespace ApplicationsParametersPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			loan_amount?: number;
			loan_duration?: number;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationParametersResponse;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCrmDataPartialUpdate
	 * @summary update application crm data
	 * @request PATCH:/admin/applications/{application_id}/crm_data
	 * @response `200` `void` updated application crm data
	 */
	export namespace ApplicationsCrmDataPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			crm_id: string;
			crm_link?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags audit
 * @name ApplicationsLogsDetail
 * @summary get audit logs for specific application
 * @request GET:/admin/applications/{application_id}/logs
 * @response `200` `{
  pagination?: Pagination,
  result?: (AuditLog)[],

}` audit logs list
*/
	export namespace ApplicationsLogsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {
			/** specify to enable return type `AuditLog` items list, otherwise old log will be returned */
			new_format?: boolean;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: AuditLog[];
		};
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsCreate
	 * @summary Create finance object
	 * @request POST:/admin/applications/{application_id}/finance_objects
	 * @response `201` `FinanceObjectCreateResponse` created finance object
	 */
	export namespace ApplicationsFinanceObjectsCreate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectCreateResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDetail
	 * @summary Get application finance objects
	 * @request GET:/admin/applications/{application_id}/finance_objects
	 * @response `200` `(FinanceObjectGetResponse)[]` list of finance objects
	 */
	export namespace ApplicationsFinanceObjectsDetail {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectGetResponse[];
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsUpdate
	 * @summary Update finance object
	 * @request PUT:/admin/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` updated finance object
	 */
	export namespace ApplicationsFinanceObjectsUpdate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDelete
	 * @summary Delete finance object
	 * @request DELETE:/admin/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` deleted finance object
	 */
	export namespace ApplicationsFinanceObjectsDelete {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags finance object
 * @name ApplicationsFinanceObjectsGenerateOrderFormCreate
 * @summary Generate finance object order form
 * @request POST:/admin/applications/{application_id}/finance_objects/{finance_object_id}/generate_order_form
 * @response `200` `{
  url: string,

}` generated finance object order form
*/
	export namespace ApplicationsFinanceObjectsGenerateOrderFormCreate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = OrderFormRequest;
		export type RequestHeaders = {};
		export type ResponseBody = {
			url: string;
		};
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsCreate
	 * @summary create product
	 * @request POST:/admin/products
	 * @response `200` `Product` created product
	 */
	export namespace ProductsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ProductRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Product;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsList
	 * @summary get all product related rules
	 * @request GET:/admin/products
	 * @response `200` `(Product)[]` OK
	 */
	export namespace ProductsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Product[];
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsDetail
	 * @summary get all product related rules
	 * @request GET:/admin/products/{product_id}
	 * @response `200` `UntypedSystemConfigValue` OK
	 */
	export namespace ProductsDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UntypedSystemConfigValue;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsPartialUpdate
	 * @summary update product
	 * @request PATCH:/admin/products/{product_id}
	 * @response `200` `Product` updated product
	 */
	export namespace ProductsPartialUpdate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ProductRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Product;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsDelete
	 * @summary Delete product
	 * @request DELETE:/admin/products/{product_id}
	 * @response `200` `void` delete product
	 */
	export namespace ProductsDelete {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsEnablePartialUpdate
	 * @summary Enable/disable product
	 * @request PATCH:/admin/products/{product_id}/enable
	 * @response `200` `void` Enabled/disabled product
	 */
	export namespace ProductsEnablePartialUpdate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			is_enabled?: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsChecklistsDetail
	 * @request GET:/admin/products/{product_id}/checklists
	 * @response `200` `(ChecklistTemplateResponse)[]` get product checklists template object
	 */
	export namespace ProductsChecklistsDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse[];
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigCreate
	 * @summary create product config
	 * @request POST:/admin/products/{product_id}/config
	 * @response `200` `ProductConfigResponse` created product config
	 */
	export namespace ProductsConfigCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ProductConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigDetail
	 * @summary List product config
	 * @request GET:/admin/products/{product_id}/config
	 * @response `200` `(ProductConfigResponse)[]` product config
	 */
	export namespace ProductsConfigDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse[];
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodesCreate
	 * @summary create new industry code
	 * @request POST:/admin/industry_codes
	 * @response `201` `IndustryCode` created industry code
	 */
	export namespace IndustryCodesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = IndustryCodeRequest;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCode;
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodesList
	 * @summary get all industry codes
	 * @request GET:/admin/industry_codes
	 * @response `200` `(IndustryCode)[]` list of industry codes
	 */
	export namespace IndustryCodesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCode[];
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodesDetail
	 * @summary get industry code
	 * @request GET:/admin/industry_codes/{industry_code_id}
	 * @response `200` `IndustryCode` industry code
	 */
	export namespace IndustryCodesDetail {
		export type RequestParams = {
			industryCodeId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCode;
	}
	/**
	 * No description
	 * @tags product
	 * @name IndustryCodesDelete
	 * @summary delete an industry code, not linked to any blacklist
	 * @request DELETE:/admin/industry_codes/{industry_code_id}
	 * @response `200` `void` ok
	 */
	export namespace IndustryCodesDelete {
		export type RequestParams = {
			industryCodeId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodeBlacklistsCreate
	 * @summary create new industry code blacklist
	 * @request POST:/admin/industry_code_blacklists
	 * @response `201` `IndustryCodeBlacklist` created industry code blacklist
	 */
	export namespace IndustryCodeBlacklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = IndustryCodeBlacklistRequest;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCodeBlacklist;
	}
	/**
	 * No description
	 * @tags product
	 * @name IndustryCodeBlacklistsList
	 * @summary get all industry code blacklists
	 * @request GET:/admin/industry_code_blacklists
	 * @response `200` `(IndustryCodeBlacklist)[]` list of industry code blacklists
	 */
	export namespace IndustryCodeBlacklistsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCodeBlacklist[];
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodeBlacklistsDetail
	 * @summary get industry code blacklist
	 * @request GET:/admin/industry_code_blacklists/{industry_code_blacklist_id}
	 * @response `200` `IndustryCodeBlacklist` industry code blacklist
	 */
	export namespace IndustryCodeBlacklistsDetail {
		export type RequestParams = {
			industryCodeBlacklistId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = IndustryCodeBlacklist;
	}
	/**
	 * No description
	 * @tags industry_code
	 * @name IndustryCodeBlacklistsDelete
	 * @summary delete an industry code blacklist
	 * @request DELETE:/admin/industry_code_blacklists/{industry_code_blacklist_id}
	 * @response `200` `void` ok
	 */
	export namespace IndustryCodeBlacklistsDelete {
		export type RequestParams = {
			industryCodeBlacklistId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigPartialUpdate
	 * @summary update product config
	 * @request PATCH:/admin/products/{product_id}/config/{config_id}
	 * @response `200` `ProductConfigResponse` updated product config
	 */
	export namespace ProductsConfigPartialUpdate {
		export type RequestParams = {
			productId: string;
			configId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ProductConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsConfigDetail2
	 * @summary get product config by product-config-id
	 * @request GET:/admin/products/{product_id}/config/{config_id}
	 * @originalName productsConfigDetail
	 * @duplicate
	 * @response `200` `ProductConfigResponse` product config
	 */
	export namespace ProductsConfigDetail2 {
		export type RequestParams = {
			productId: string;
			configId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ScoringConfigsCreate
	 * @summary create product scoring config
	 * @request POST:/admin/scoring_configs
	 * @response `200` `ScoringConfigResponse` created product scoring config
	 */
	export namespace ScoringConfigsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ScoringConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ScoringConfigsList
	 * @summary Scoring configs
	 * @request GET:/admin/scoring_configs
	 * @response `200` `(ScoringConfigResponse)[]` scoring configs
	 */
	export namespace ScoringConfigsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringConfigResponse[];
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsScoringConfigDetail
	 * @summary Product scoring config
	 * @request GET:/admin/products/{product_id}/scoring_config
	 * @response `200` `ScoringConfigResponse` product scoring config
	 */
	export namespace ProductsScoringConfigDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ScoringConfigsDelete
	 * @summary delete scoring config
	 * @request DELETE:/admin/scoring_configs/{scoring_config_id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ScoringConfigsDelete {
		export type RequestParams = {
			scoringConfigId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigDetail
	 * @summary List product interest rate configs
	 * @request GET:/admin/products/{product_id}/interest_rate_config
	 * @response `200` `(ProductInterestRateConfigResponse)[]` product interest rate configs
	 */
	export namespace ProductsInterestRateConfigDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductInterestRateConfigResponse[];
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigCreate
	 * @summary create product interest rate config, replace previous interest rate config if existed
	 * @request POST:/admin/products/{product_id}/interest_rate_config
	 * @response `200` `ProductInterestRateConfigResponse` created product scoring config
	 */
	export namespace ProductsInterestRateConfigCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ProductInterestRateConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductInterestRateConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigPartialUpdate
	 * @summary update product interest rate config
	 * @request PATCH:/admin/products/{product_id}/interest_rate_config
	 * @response `200` `ProductInterestRateConfigResponse` update product interest rate config
	 */
	export namespace ProductsInterestRateConfigPartialUpdate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ProductInterestRateConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductInterestRateConfigResponse;
	}
	/**
	 * No description
	 * @tags product
	 * @name ProductsInterestRateConfigDelete
	 * @summary delete product interest rate config
	 * @request DELETE:/admin/products/{product_id}/interest_rate_config/{id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ProductsInterestRateConfigDelete {
		export type RequestParams = {
			productId: string;
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags transition_rules
	 * @name ProductsTransitionRulesDetail
	 * @summary List product transition rules
	 * @request GET:/admin/products/{product_id}/transition_rules
	 * @response `200` `(TransitionRule)[]` transition rules
	 */
	export namespace ProductsTransitionRulesDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = TransitionRule[];
	}
	/**
	 * No description
	 * @tags transition_rules
	 * @name ProductsTransitionRulesCreate
	 * @summary create product transition rules
	 * @request POST:/admin/products/{product_id}/transition_rules
	 * @response `201` `void` created product transition rules
	 */
	export namespace ProductsTransitionRulesCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = TransitionRuleRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags transition_rules
	 * @name ProductsTransitionRulesDelete
	 * @summary delete product transition rules
	 * @request DELETE:/admin/products/{product_id}/transition_rules/{product_transition_rule_id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ProductsTransitionRulesDelete {
		export type RequestParams = {
			productId: string;
			productTransitionRuleId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags transition_rules
	 * @name ProductsTransitionRulesPartialUpdate
	 * @summary update transition rules
	 * @request PATCH:/admin/products/{product_id}/transition_rules/{product_transition_rule_id}
	 * @response `200` `TransitionRule` updated transition rules
	 */
	export namespace ProductsTransitionRulesPartialUpdate {
		export type RequestParams = {
			productId: string;
			productTransitionRuleId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = TransitionRuleUpdateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = TransitionRule;
	}
	/**
	 * No description
	 * @tags status_change_constraints
	 * @name ProductsStatusChangeConstraintsDetail
	 * @summary List status change constraints
	 * @request GET:/admin/products/{product_id}/status_change_constraints
	 * @response `200` `(StatusChangeConstraints)[]` status change constraints
	 */
	export namespace ProductsStatusChangeConstraintsDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusChangeConstraints[];
	} /**
 * No description
 * @tags status_change_constraints
 * @name ProductsStatusChangeConstraintsCreate
 * @summary create status change constraints
 * @request POST:/admin/products/{product_id}/status_change_constraints
 * @response `201` `{
  status_change_constraint_id?: number,

}` created status change constraints
*/
	export namespace ProductsStatusChangeConstraintsCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusChangeConstraintsRequest;
		export type RequestHeaders = {};
		export type ResponseBody = {
			status_change_constraint_id?: number;
		};
	}
	/**
	 * No description
	 * @tags status_change_constraints
	 * @name ProductsStatusChangeConstraintsPartialUpdate
	 * @summary update status change constraints
	 * @request PATCH:/admin/products/{product_id}/status_change_constraints/{status_change_constraints_id}
	 * @response `200` `StatusChangeConstraints` updated status change constraints
	 */
	export namespace ProductsStatusChangeConstraintsPartialUpdate {
		export type RequestParams = {
			productId: string;
			statusChangeConstraintsId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusChangeConstraintsRequest;
		export type RequestHeaders = {};
		export type ResponseBody = StatusChangeConstraints;
	}
	/**
	 * No description
	 * @tags status_change_constraints
	 * @name ProductsStatusChangeConstraintsDelete
	 * @summary delete status change constraints
	 * @request DELETE:/admin/products/{product_id}/status_change_constraints/{status_change_constraints_id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ProductsStatusChangeConstraintsDelete {
		export type RequestParams = {
			productId: string;
			statusChangeConstraintsId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application_update_constraints
	 * @name ProductsApplicationUpdateConstraintsDetail
	 * @summary List application update constraints
	 * @request GET:/admin/products/{product_id}/application_update_constraints
	 * @response `200` `(ApplicationUpdateConstraints)[]` application update constraints
	 */
	export namespace ProductsApplicationUpdateConstraintsDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationUpdateConstraints[];
	} /**
 * No description
 * @tags application_update_constraints
 * @name ProductsApplicationUpdateConstraintsCreate
 * @summary application update constraints
 * @request POST:/admin/products/{product_id}/application_update_constraints
 * @response `201` `{
  application_update_constraint_id?: number,

}` created application update constraints
*/
	export namespace ProductsApplicationUpdateConstraintsCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ApplicationUpdateConstraintsRequest;
		export type RequestHeaders = {};
		export type ResponseBody = {
			application_update_constraint_id?: number;
		};
	}
	/**
	 * No description
	 * @tags application_update_constraints
	 * @name ProductsApplicationUpdateConstraintsPartialUpdate
	 * @summary application update constraints
	 * @request PATCH:/admin/products/{product_id}/application_update_constraints/{application_update_constraints_id}
	 * @response `200` `ApplicationUpdateConstraints` application update constraints
	 */
	export namespace ProductsApplicationUpdateConstraintsPartialUpdate {
		export type RequestParams = {
			productId: string;
			applicationUpdateConstraintsId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = ApplicationUpdateConstraintsRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationUpdateConstraints;
	}
	/**
	 * No description
	 * @tags application_update_constraints
	 * @name ProductsApplicationUpdateConstraintsDelete
	 * @summary delete application update constraints
	 * @request DELETE:/admin/products/{product_id}/application_update_constraints/{application_update_constraints_id}
	 * @response `200` `void` deleted application update constraints
	 */
	export namespace ProductsApplicationUpdateConstraintsDelete {
		export type RequestParams = {
			productId: string;
			applicationUpdateConstraintsId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags status_change_config
	 * @name ProductsStatusChangeConfigDetail
	 * @summary List product status change config
	 * @request GET:/admin/products/{product_id}/status_change_config
	 * @response `200` `(StatusChangeConfig)[]` product status change config
	 */
	export namespace ProductsStatusChangeConfigDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusChangeConfig[];
	} /**
 * No description
 * @tags status_change_config
 * @name ProductsStatusChangeConfigCreate
 * @summary product status change config
 * @request POST:/admin/products/{product_id}/status_change_config
 * @response `201` `{
  status_change_config_id?: number,

}` created product status change config
*/
	export namespace ProductsStatusChangeConfigCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusChangeConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = {
			status_change_config_id?: number;
		};
	}
	/**
	 * No description
	 * @tags status_change_config
	 * @name ProductsStatusChangeConfigPartialUpdate
	 * @summary application update constraints
	 * @request PATCH:/admin/products/{product_id}/status_change_config/{status_change_config_id}
	 * @response `200` `StatusChangeConfig` product status change config
	 */
	export namespace ProductsStatusChangeConfigPartialUpdate {
		export type RequestParams = {
			productId: string;
			statusChangeConfigId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusChangeConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = StatusChangeConfig;
	}
	/**
	 * No description
	 * @tags status_change_config
	 * @name ProductsStatusChangeConfigDelete
	 * @summary delete product status change config
	 * @request DELETE:/admin/products/{product_id}/status_change_config/{status_change_config_id}
	 * @response `200` `void` deleted product status change config
	 */
	export namespace ProductsStatusChangeConfigDelete {
		export type RequestParams = {
			productId: string;
			statusChangeConfigId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags status_actions
	 * @name ProductsStatusActionsDetail
	 * @summary List product status actions
	 * @request GET:/admin/products/{product_id}/status_actions
	 * @response `200` `(StatusAction)[]` product status actions
	 */
	export namespace ProductsStatusActionsDetail {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusAction[];
	} /**
 * No description
 * @tags status_actions
 * @name ProductsStatusActionsCreate
 * @summary product status actions
 * @request POST:/admin/products/{product_id}/status_actions
 * @response `201` `{
  status_change_event_id?: number,

}` created product status action
*/
	export namespace ProductsStatusActionsCreate {
		export type RequestParams = {
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusActionRequest;
		export type RequestHeaders = {};
		export type ResponseBody = {
			status_change_event_id?: number;
		};
	}
	/**
	 * No description
	 * @tags status_actions
	 * @name ProductsStatusActionsPartialUpdate
	 * @summary product status actions
	 * @request PATCH:/admin/products/{product_id}/status_actions/{status_change_event_id}
	 * @response `200` `StatusAction` product status actions
	 */
	export namespace ProductsStatusActionsPartialUpdate {
		export type RequestParams = {
			productId: string;
			statusChangeEventId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = StatusActionRequest;
		export type RequestHeaders = {};
		export type ResponseBody = StatusAction;
	}
	/**
	 * No description
	 * @tags status_actions
	 * @name ProductsStatusActionsDelete
	 * @summary delete product status actions
	 * @request DELETE:/admin/products/{product_id}/status_actions/{status_change_event_id}
	 * @response `200` `void` deleted product status actions
	 */
	export namespace ProductsStatusActionsDelete {
		export type RequestParams = {
			productId: string;
			statusChangeEventId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesList
	 * @summary List product families
	 * @request GET:/admin/product_families
	 * @response `200` `(ProductFamily)[]` product families
	 */
	export namespace ProductFamiliesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily[];
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesCreate
	 * @summary create product family
	 * @request POST:/admin/product_families
	 * @response `200` `ProductFamily` created product family
	 */
	export namespace ProductFamiliesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ProductFamilyRequest;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesDetail
	 * @summary get given product family
	 * @request GET:/admin/product_families/{product_family_id}
	 * @response `200` `ProductFamily` OK
	 */
	export namespace ProductFamiliesDetail {
		export type RequestParams = {
			productFamilyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesPartialUpdate
	 * @summary update product family
	 * @request PATCH:/admin/product_families/{product_family_id}
	 * @response `200` `ProductFamily` updated product family
	 */
	export namespace ProductFamiliesPartialUpdate {
		export type RequestParams = {
			productFamilyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			name?: string;
			description?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ProductFamily;
	}
	/**
	 * No description
	 * @tags product_families
	 * @name ProductFamiliesDelete
	 * @summary delete product family
	 * @request DELETE:/admin/product_families/{product_family_id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace ProductFamiliesDelete {
		export type RequestParams = {
			productFamilyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsProductPartialUpdate
	 * @summary update application product
	 * @request PATCH:/admin/applications/{application_id}/product/{product_id}
	 * @response `200` `void` OK
	 */
	export namespace ApplicationsProductPartialUpdate {
		export type RequestParams = {
			applicationId: string;
			productId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsProductsAvailableDetail
	 * @summary get available products for switching to
	 * @request GET:/admin/applications/{application_id}/products_available
	 * @response `200` `(AvailableProductFamily)[]` OK
	 */
	export namespace ApplicationsProductsAvailableDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AvailableProductFamily[];
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsAccountManagerUpdate
	 * @summary update application account manager id
	 * @request PUT:/admin/applications/{application_id}/account_manager
	 * @response `200` `Application` updated application
	 */
	export namespace ApplicationsAccountManagerUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** BOUserID of the account manager, send null to remove account manager. */
			account_manager_id?: string | null;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags files
	 * @name ApplicationsFilesArchiveDetail
	 * @summary download files in archive
	 * @request GET:/admin/applications/{application_id}/files_archive/{file_ids}
	 * @response `200` `File` zip archive with selected files
	 */
	export namespace ApplicationsFilesArchiveDetail {
		export type RequestParams = {
			applicationId: string;
			/** list of comma-separated fileIds from application to be downloaded */
			fileIds: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesCreate
	 * @summary Create new signatories
	 * @request POST:/admin/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` created signatory object
	 */
	export namespace ApplicationsSignatoriesCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			data?: SignatoriesRequest;
		};
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesDetail
	 * @summary Get all signatories for application
	 * @request GET:/admin/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` signatories for this application
	 */
	export namespace ApplicationsSignatoriesDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	} /**
 * No description
 * @tags loan
 * @name ApplicationsGenerateContractCreate
 * @summary generate loan contract
 * @request POST:/admin/applications/{application_id}/generate_contract
 * @response `200` `{
  url?: string,

}` generates loan contract and adds to user files
*/
	export namespace ApplicationsGenerateContractCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			url?: string;
		};
	} /**
 * No description
 * @tags audit
 * @name LoansLogsDetail
 * @summary get audit logs for specific loan
 * @request GET:/admin/loans/{loan_id}/logs
 * @response `200` `{
  pagination?: Pagination,
  logs?: (AuditLog)[],

}` audit logs list
*/
	export namespace LoansLogsDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			/** specify to enable return type `AuditLog` items list, otherwise old log will be returned */
			new_format?: boolean;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			logs?: AuditLog[];
		};
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderCreate
	 * @summary connect to an application provider
	 * @request POST:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `void` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderCreate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			type: "mangopay";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderPartialUpdate
	 * @summary change payment provider data identifiers
	 * @request PATCH:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `void` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderPartialUpdate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			type: "mangopay";
		};
		export type RequestBody = PaymentProviderInfo;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags payments
	 * @name LoansPaymentProviderDetail
	 * @summary get payment provider data identifiers
	 * @request GET:/admin/loans/{loan_id}/payment_provider
	 * @response `200` `PaymentProviderInfo` application/loan connected to payment provider
	 */
	export namespace LoansPaymentProviderDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			type: "mangopay";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PaymentProviderInfo;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsPartialUpdate
	 * @summary Update signatory requirements
	 * @request PATCH:/admin/signatories/{signatory_id}/requirements
	 * @response `200` `SignatoryRequirements` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryRequirementsAdminRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsDetail
	 * @summary Get signatory requiremens
	 * @request GET:/admin/signatories/{signatory_id}/requirements
	 * @response `200` `(SignatoryRequirements)[]` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesCreditCheckPartialUpdate
	 * @summary Check signatory credit report
	 * @request PATCH:/admin/signatories/{signatory_id}/credit_check
	 * @response `200` `void` signatory credit report done
	 */
	export namespace SignatoriesCreditCheckPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			report_type?: ReportType;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get signatory credit score
	 * @tags signatories
	 * @name SignatoriesCreditScoreDetail
	 * @request GET:/admin/signatories/{signatory_id}/credit_score
	 * @response `200` `(SignatoryCreditScore)[]` success
	 */
	export namespace SignatoriesCreditScoreDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryCreditScore[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesKycDetail
	 * @summary Get signatory kyc status
	 * @request GET:/admin/signatories/{signatory_id}/kyc
	 * @response `200` `SignatoryKycStatus` signatory kyc status
	 */
	export namespace SignatoriesKycDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryKycStatus;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesGenerateContractCreate
	 * @summary generate guarantor contract
	 * @request POST:/admin/signatories/{signatory_id}/generate_contract
	 * @response `200` `void` generates guarantor contract for signatory and adds to user files
	 */
	export namespace SignatoriesGenerateContractCreate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequestPepCheckCreate
	 * @summary initiate pep check for signatory
	 * @request POST:/admin/signatories/{signatory_id}/request_pep_check
	 * @response `200` `void` success
	 */
	export namespace SignatoriesRequestPepCheckCreate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesApprovePepCheckPartialUpdate
	 * @summary approve pep check for signatory
	 * @request PATCH:/admin/signatories/{signatory_id}/approve_pep_check/{pep_check_id}
	 * @response `200` `void` success
	 */
	export namespace SignatoriesApprovePepCheckPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
			pepCheckId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsDetail
	 * @request GET:/admin/applications/{application_id}/signatories_requirements
	 * @response `200` `(SignatoryRequirements)[]` all signatories requirments for this application
	 */
	export namespace ApplicationsSignatoriesRequirementsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesPartialUpdate
	 * @request PATCH:/admin/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` updated signatory object
	 */
	export namespace SignatoriesPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = Signatory;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDetail
	 * @request GET:/admin/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` return signatory object
	 */
	export namespace SignatoriesDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDelete
	 * @summary delete specified signatory
	 * @request DELETE:/admin/signatories/{signatory_id}
	 * @response `200` `void` 200 ok response with no data
	 */
	export namespace SignatoriesDelete {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get applicaiton loan object
	 * @tags loans
	 * @name ApplicationsLoanDetail
	 * @request GET:/admin/applications/{application_id}/loan
	 * @response `200` `Loan` loan object
	 */
	export namespace ApplicationsLoanDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description get application loan repayment plan in csv format
	 * @tags loans
	 * @name ApplicationsLoanRepaymentPlanDetail
	 * @request GET:/admin/applications/{application_id}/loan_repayment_plan
	 * @response `200` `string` loan repayment plan csv table
	 */
	export namespace ApplicationsLoanRepaymentPlanDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {
			export_type?: "csv";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = string;
	} /**
 * @description get all loans
 * @tags loans
 * @name LoansList
 * @request GET:/admin/loans
 * @response `200` `{
  pagination: Pagination,
  result: (Loan)[],

}` loans object
*/
	export namespace LoansList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			sort_order?: "ascend" | "descend";
			/** @example Some description */
			sort_by?: string;
			/** @example Some description */
			text?: string;
			/** @example a,b,c */
			status?: string | string[];
			is_eligible?: boolean;
			product_id?: string[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: Pagination;
			result: Loan[];
		};
	}
	/**
	 * @description get loan object
	 * @tags loans
	 * @name LoansDetail
	 * @request GET:/admin/loans/{loan_id}
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan object
	 * @tags loans
	 * @name LoansPartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansPartialUpdate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			external_reference?: string;
			compartment_id?: Compartment;
			is_eligible?: boolean;
			actual_swap_rate?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description get loan pricing
	 * @tags loans
	 * @name LoansPricingDetail
	 * @request GET:/admin/loans/{loan_id}/pricing
	 * @response `200` `AdminPricing` pricing object
	 */
	export namespace LoansPricingDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminPricing;
	}
	/**
	 * No description
	 * @tags loans
	 * @name LoansApplicationChecklistDetail
	 * @summary application checklist
	 * @request GET:/admin/loans/{loan_id}/application_checklist
	 * @response `200` `ApplicationChecklistResponse` application checklist for this loan
	 */
	export namespace LoansApplicationChecklistDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags loans
	 * @name LoansFourEyeChecklistDetail
	 * @summary four eye checklist
	 * @request GET:/admin/loans/{loan_id}/four_eye_checklist
	 * @response `200` `ApplicationChecklistResponse` four eye checklist for this loan
	 */
	export namespace LoansFourEyeChecklistDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags loans
	 * @name LoansPayoutChecklistDetail
	 * @summary payout checklist
	 * @request GET:/admin/loans/{loan_id}/payout_checklist
	 * @response `200` `ApplicationChecklistResponse` payout checklist for this loan
	 */
	export namespace LoansPayoutChecklistDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * @description generate payment plan document
	 * @tags loans
	 * @name LoansGeneratePaymentPlanCreate
	 * @request POST:/admin/loans/{loan_id}/generate_payment_plan
	 * @response `200` `void` ok
	 */
	export namespace LoansGeneratePaymentPlanCreate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description tag loan object with debt note
	 * @tags loans
	 * @name LoansDebtNotePartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/debt_note
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDebtNotePartialUpdate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = DebtNote;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description remove debt note from loan
	 * @tags loans
	 * @name LoansDebtNoteDelete
	 * @request DELETE:/admin/loans/{loan_id}/debt_note
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansDebtNoteDelete {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan status or payout_date
	 * @tags loans
	 * @name LoansStatusPartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/status
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansStatusPartialUpdate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			loan_status?: LoanStatus;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	}
	/**
	 * @description update loan status or payout_date
	 * @tags loans
	 * @name LoansPayoutDatePartialUpdate
	 * @request PATCH:/admin/loans/{loan_id}/payout_date
	 * @response `200` `Loan` loan object
	 */
	export namespace LoansPayoutDatePartialUpdate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/**
			 * will trigger repayment plan generation
			 * @format date
			 * @example "2021-10-31T00:00:00.000Z"
			 */
			payout_date: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Loan;
	} /**
 * @description generate  payment confirmation document
 * @tags loans
 * @name LoansGeneratePaymentConfirmationCreate
 * @request POST:/admin/loans/{loan_id}/generate_payment_confirmation
 * @response `200` `{
  url?: string,

}` ok
*/
	export namespace LoansGeneratePaymentConfirmationCreate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			url?: string;
		};
	}
	/**
	 * No description
	 * @tags loans
	 * @name LoansSignatoriesDetail
	 * @summary signatories
	 * @request GET:/admin/loans/{loan_id}/signatories
	 * @response `200` `SignatoriesResponse` signatories for this loan
	 */
	export namespace LoansSignatoriesDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * @description get loan transactions
	 * @tags loans
	 * @name LoansTransactionsDetail
	 * @request GET:/admin/loans/{loan_id}/transactions
	 * @response `200` `LoanTransactionsResponse` loan transaction response
	 */
	export namespace LoansTransactionsDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = LoanTransactionsResponse;
	}
	/**
	 * No description
	 * @tags financials
	 * @name LoansFinancialDataDetail
	 * @request GET:/admin/loans/{loan_id}/financial_data
	 * @response `200` `FinancialDataResponse` get financials
	 */
	export namespace LoansFinancialDataDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinancialDataResponse;
	}
	/**
	 * No description
	 * @tags financials
	 * @name ApplicationsFinancialDataCreate
	 * @request POST:/admin/applications/{application_id}/financial_data
	 * @response `200` `FinancialDataResponse` created financials
	 */
	export namespace ApplicationsFinancialDataCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			financials: FinancialDataRequest[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = FinancialDataResponse;
	} /**
 * No description
 * @tags financials
 * @name ApplicationsFinancialDataDetail
 * @request GET:/admin/applications/{application_id}/financial_data
 * @response `200` `{
  financials: (FinancialData)[],

}` get financials
*/
	export namespace ApplicationsFinancialDataDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			financials: FinancialData[];
		};
	}
	/**
	 * No description
	 * @tags kpis
	 * @name ApplicationsKpisDetail
	 * @request GET:/admin/applications/{application_id}/kpis
	 * @response `200` `KPIsResponse` get kpis
	 */
	export namespace ApplicationsKpisDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = KPIsResponse;
	}
	/**
	 * No description
	 * @tags kpis
	 * @name LoansKpisDetail
	 * @request GET:/admin/loans/{loan_id}/kpis
	 * @response `200` `KPIsResponse` get loan kpis
	 */
	export namespace LoansKpisDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = KPIsResponse;
	}
	/**
	 * No description
	 * @tags score
	 * @name ApplicationsScoreDetail
	 * @request GET:/admin/applications/{application_id}/score
	 * @response `200` `ScoringResult` get application score
	 */
	export namespace ApplicationsScoreDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringResult;
	}
	/**
	 * No description
	 * @tags score
	 * @name LoansScoreDetail
	 * @request GET:/admin/loans/{loan_id}/score
	 * @response `200` `ScoringResult` get loan score
	 */
	export namespace LoansScoreDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringResult;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackPartialUpdate
	 * @request PATCH:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `ScoringFeedback` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = ScoringFeedback;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringFeedback;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackDetail
	 * @request GET:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `ScoringFeedback` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringFeedback;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name ApplicationsScoringFeedbackDelete
	 * @request DELETE:/admin/applications/{application_id}/scoring_feedback
	 * @response `200` `void` created signatory object
	 */
	export namespace ApplicationsScoringFeedbackDelete {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags kpi
	 * @name LoansScoringFeedbackDetail
	 * @request GET:/admin/loans/{loan_id}/scoring_feedback
	 * @response `200` `ScoringFeedback` loan scoring feedback
	 */
	export namespace LoansScoringFeedbackDetail {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ScoringFeedback;
	} /**
 * No description
 * @tags loans
 * @name ApplicationsLoanContractCreate
 * @request POST:/admin/applications/{application_id}/loan_contract
 * @response `200` `{
  url?: string,

}` loan contract url
*/
	export namespace ApplicationsLoanContractCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			filename?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = {
			url?: string;
		};
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDetail
	 * @request GET:/admin/applications/{application_id}/loan_contract
	 * @response `200` `LoanContract` loan contract url and filename
	 */
	export namespace ApplicationsLoanContractDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = LoanContract;
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDelete
	 * @request DELETE:/admin/applications/{application_id}/loan_contract
	 * @response `200` `void` loan contract url
	 */
	export namespace ApplicationsLoanContractDelete {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags checklist_template
	 * @name ChecklistsCreate
	 * @request POST:/admin/checklists
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			type?: ChecklistType;
			checklist_template?: ChecklistTemplate;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * No description
	 * @tags checklist_template
	 * @name ChecklistsDetail
	 * @request GET:/admin/checklists/{checklist_id}
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsDetail {
		export type RequestParams = {
			checklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * @description get last ( active ) checklist for specified type
	 * @tags checklist_template
	 * @name ChecklistsLastList
	 * @request GET:/admin/checklists/last
	 * @response `200` `ChecklistTemplateResponse` checklist template object
	 */
	export namespace ChecklistsLastList {
		export type RequestParams = {};
		export type RequestQuery = {
			type: ChecklistTemplateResponse;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ChecklistTemplateResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsCreate
	 * @request POST:/admin/teylor_application_documents_checklists
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsDetail
	 * @request GET:/admin/teylor_application_documents_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsDetail {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags teylor_application_documents_checklists
	 * @name TeylorApplicationDocumentsChecklistsPartialUpdate
	 * @request PATCH:/admin/teylor_application_documents_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` teylor_application_documents_checklists checklist object
	 */
	export namespace TeylorApplicationDocumentsChecklistsPartialUpdate {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsCreate
	 * @request POST:/admin/application_checklists
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsDetail
	 * @request GET:/admin/application_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsDetail {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationChecklistsPartialUpdate
	 * @request PATCH:/admin/application_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationChecklistsPartialUpdate {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsCreate
	 * @request POST:/admin/four_eye_checklists
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			checklist_id?: number;
			application_id?: string;
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsDetail
	 * @request GET:/admin/four_eye_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsDetail {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags four_eye_checklists
	 * @name FourEyeChecklistsPartialUpdate
	 * @request PATCH:/admin/four_eye_checklists/{application_checklist_id}
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace FourEyeChecklistsPartialUpdate {
		export type RequestParams = {
			applicationChecklistId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsApplicationChecklistDetail
	 * @request GET:/admin/applications/{application_id}/application_checklist
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationsApplicationChecklistDetail {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsApplicationChecklistDelete
	 * @request DELETE:/admin/applications/{application_id}/application_checklist
	 * @response `200` `void` application checklist object
	 */
	export namespace ApplicationsApplicationChecklistDelete {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags checklists
	 * @name ApplicationsFourEyeChecklistDetail
	 * @request GET:/admin/applications/{application_id}/four_eye_checklist
	 * @response `200` `ApplicationChecklistResponse` application checklist object
	 */
	export namespace ApplicationsFourEyeChecklistDetail {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationChecklistResponse;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsReportsCreate
	 * @request POST:/admin/applications/{application_id}/reports
	 * @response `200` `File` application pdf report
	 */
	export namespace ApplicationsReportsCreate {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {
			type: "socgen" | "tin";
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsSocgenCustomInfoCreate
	 * @request POST:/admin/applications/{application_id}/socgen_custom_info
	 * @response `200` `void` ok
	 */
	export namespace ApplicationsSocgenCustomInfoCreate {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			object_info?: Record<
				string,
				{
					provision?: string;
					finanzierungsstruktur?: string;
				}
			>;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags societe-generale
	 * @name ApplicationsBankSpecificInfoDetail
	 * @request GET:/admin/applications/{application_id}/bank_specific_info
	 * @response `200` `(Record<string, string>)[]` ok
	 */
	export namespace ApplicationsBankSpecificInfoDetail {
		export type RequestParams = {
			applicationId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Record<string, string>[];
	} /**
 * No description
 * @tags signatories
 * @name SignatoriesRequirementsGuarantorContractsCreate
 * @request POST:/admin/signatories_requirements/{signatory_id}/guarantor_contracts
 * @response `200` `{
  url?: string,

}` updated signatory object
*/
	export namespace SignatoriesRequirementsGuarantorContractsCreate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			filename: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = {
			url?: string;
		};
	} /**
 * @description get signatories identification status
 * @tags identification
 * @name IdentificationStatusList
 * @request GET:/admin/identification_status
 * @response `200` `{
  idents?: (IdentificationStatus)[],
  pagination?: LegacyPagination,

}` signatory ident status
*/
	export namespace IdentificationStatusList {
		export type RequestParams = {};
		export type RequestQuery = {
			signatory_name?: string;
			company_name?: string;
			provider_status?:
				| "SUCCESS"
				| "SUCCESS_DATA_CHANGED"
				| "FRAUD_SUSPICION_CONFIRMED"
				| "IDENT_EXPIRED"
				| "ABORTED"
				| "REVIEW_PENDING"
				| "FRAUD_SUSPICION_PENDING";
			is_complete?: boolean;
			page?: number;
			size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			idents?: IdentificationStatus[];
			pagination?: LegacyPagination;
		};
	}
	/**
	 * @description require signatory to perform video identification
	 * @tags identification
	 * @name SignatoriesRequirementsVideoIdentsCreate
	 * @request POST:/admin/signatories_requirements/{signatory_id}/video_idents
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace SignatoriesRequirementsVideoIdentsCreate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsManuallyCompletedPartialUpdate
	 * @summary Mark signatory ident as manually completed
	 * @request PATCH:/admin/signatories_requirements/{signatory_id}/manually_completed
	 * @response `200` `SignatoryRequirements` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsManuallyCompletedPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryIdentManualCompletionAdminRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsRefreshPartialUpdate
	 * @summary Refresh signatory invite or loan signature
	 * @request PATCH:/admin/signatories_requirements/{signatory_id}/refresh
	 * @response `200` `SignatoryRequirements` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsRefreshPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryIdentManualCompletionAdminRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * @description get signatory video identification status
	 * @tags identification
	 * @name SignatoriesRequirementsRefreshDetail
	 * @request GET:/admin/signatories_requirements/{signatory_id}/refresh
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace SignatoriesRequirementsRefreshDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * No description
	 * @tags identification
	 * @name IdentificationStatusDetail
	 * @request GET:/admin/identification_status/{transactioin_id}
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace IdentificationStatusDetail {
		export type RequestParams = {
			transactioinId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	}
	/**
	 * No description
	 * @tags identification
	 * @name IdentificationDocumentDefinitionCreate
	 * @request POST:/admin/identification/document_definition
	 * @response `200` `void` signatory ident status
	 */
	export namespace IdentificationDocumentDefinitionCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			definition_id?: string;
			definition_name?: string;
			signature_info?: object;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersList
	 * @summary Get list of bo_users with roles
	 * @request GET:/admin/bo_users
	 * @response `200` `(BoUser)[]` success
	 */
	export namespace BoUsersList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BoUser[];
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersCreate
	 * @summary Create a bo_user (admin)
	 * @request POST:/admin/bo_users
	 * @response `200` `void` successfully created bo_user
	 */
	export namespace BoUsersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @example user@teylor.com */
			email?: string;
			/** @example Max Muster */
			name?: string;
			/** @example ["role_task_user","role_risk_analyst"] */
			roles?: string[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags bo_users
 * @name BoUsersListList
 * @summary Get list of bo_users
 * @request GET:/admin/bo_users/list
 * @response `200` `({
  \** @example bou_111 *\
  id?: string,
  \** @example testadmin@teylor.com *\
  email?: string,
  \** @example Test Admin *\
  name?: string,
  \** @example active *\
  status?: string,

})[]` success
*/
	export namespace BoUsersListList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			/** @example bou_111 */
			id?: string;
			/** @example testadmin@teylor.com */
			email?: string;
			/** @example Test Admin */
			name?: string;
			/** @example active */
			status?: string;
		}[];
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersDetail
	 * @summary Get bo_user by id
	 * @request GET:/admin/bo_users/{id}
	 * @response `200` `BoUser` success
	 */
	export namespace BoUsersDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BoUser;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersProfilePartialUpdate
	 * @summary Update bo user
	 * @request PATCH:/admin/bo_users/{id}/profile
	 * @response `204` `void` successfully updated user
	 */
	export namespace BoUsersProfilePartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			name?: string;
			email?: string;
			interface_language?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Unlock bo_user login
	 * @tags users
	 * @name BoUsersUnlockPartialUpdate
	 * @request PATCH:/admin/bo_users/{id}/unlock
	 * @response `200` `void` success
	 */
	export namespace BoUsersUnlockPartialUpdate {
		export type RequestParams = {
			/** @example usr_123 */
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersEnableCreate
	 * @summary Enable the bo_user (admin) account
	 * @request POST:/admin/bo_users/{bo_user_id}/enable
	 * @response `200` `void` successfully enabled bo_user
	 */
	export namespace BoUsersEnableCreate {
		export type RequestParams = {
			boUserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersDisableCreate
	 * @summary Disable the bo_user (admin) account
	 * @request POST:/admin/bo_users/{bo_user_id}/disable
	 * @response `200` `void` successfully disabled bo_user
	 */
	export namespace BoUsersDisableCreate {
		export type RequestParams = {
			boUserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersDelete
	 * @summary Delete the bo_user (admin) account
	 * @request DELETE:/admin/bo_users/{bo_user_id}
	 * @response `200` `void` successfully deleted the bo_user
	 */
	export namespace BoUsersDelete {
		export type RequestParams = {
			boUserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersResetCreate
	 * @summary Reset the bo_user (admin) password. User will receive an email with a link for setting a new password.
	 * @request POST:/admin/bo_users/{bo_user_id}/reset
	 * @response `200` `void` successfully reset password for bo_user
	 */
	export namespace BoUsersResetCreate {
		export type RequestParams = {
			boUserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_users
	 * @name BoUsersResendInviteCreate
	 * @summary Resend the account setup invitation for the bo_user (admin). User will receive an email with a link for setting a password.
	 * @request POST:/admin/bo_users/{bo_user_id}/resend_invite
	 * @response `200` `void` successfully sent a new invitation
	 */
	export namespace BoUsersResendInviteCreate {
		export type RequestParams = {
			boUserId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_user_role
	 * @name BoUsersRolesList
	 * @summary Get list of bo_user roles
	 * @request GET:/admin/bo_users/roles
	 * @response `200` `(BOUserRole)[]` success
	 */
	export namespace BoUsersRolesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserRole[];
	}
	/**
	 * No description
	 * @tags bo_user_role
	 * @name BoUsersRolesPartialUpdate
	 * @summary Update bo_user roles
	 * @request PATCH:/admin/bo_users/{id}/roles
	 * @response `204` `void` successfully updated bo_user
	 */
	export namespace BoUsersRolesPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			roles: string[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_user_group
	 * @name BoUserGroupsList
	 * @summary Get BoUser groups
	 * @request GET:/admin/bo_user_groups
	 * @response `200` `(BOUserGroupResponse)[]` success
	 */
	export namespace BoUserGroupsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserGroupResponse[];
	}
	/**
	 * No description
	 * @tags bo_user_group
	 * @name BoUserGroupsCreate
	 * @summary Create BoUser group
	 * @request POST:/admin/bo_user_groups
	 * @response `201` `BOUserGroupCreateResponse` successfully created
	 */
	export namespace BoUserGroupsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = BOUserGroupCreateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserGroupCreateResponse;
	}
	/**
	 * No description
	 * @tags bo_user_group
	 * @name BoUserGroupsDetail
	 * @summary Get BoUser group
	 * @request GET:/admin/bo_user_groups/{id}
	 * @response `200` `BOUserGroupResponse` success
	 */
	export namespace BoUserGroupsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = BOUserGroupResponse;
	}
	/**
	 * @description This overwrites the existing list of BoUsers in the group. BoUsers not present in the list will be removed from the group.
	 * @tags bo_user_group
	 * @name BoUserGroupsPartialUpdate
	 * @summary Update list of BoUsers in BoUser group
	 * @request PATCH:/admin/bo_user_groups/{id}
	 * @response `200` `void` success
	 */
	export namespace BoUserGroupsPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = BOUserGroupUpdateListRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags bo_user_group
	 * @name BoUserGroupsDelete
	 * @summary Delete BoUser group
	 * @request DELETE:/admin/bo_user_groups/{id}
	 * @response `200` `void` success
	 */
	export namespace BoUserGroupsDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Add list of BoUsers to the group. Existing BoUsers continue to be part of the group.
	 * @tags bo_user_group
	 * @name BoUserGroupsAddPartialUpdate
	 * @summary Add BoUsers to BoUser group
	 * @request PATCH:/admin/bo_user_groups/{id}/add
	 */
	export namespace BoUserGroupsAddPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = BOUserGroupUpdateListRequest;
		export type RequestHeaders = {};
		export type ResponseBody = any;
	}
	/**
	 * @description Remove list of BoUsers from the group.
	 * @tags bo_user_group
	 * @name BoUserGroupsRemovePartialUpdate
	 * @summary Remove BoUsers from BoUser group
	 * @request PATCH:/admin/bo_user_groups/{id}/remove
	 */
	export namespace BoUserGroupsRemovePartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = BOUserGroupUpdateListRequest;
		export type RequestHeaders = {};
		export type ResponseBody = any;
	} /**
 * No description
 * @tags debt_note
 * @name DebtNotesList
 * @summary Get debt note
 * @request GET:/admin/debt_notes
 * @response `200` `{
  page?: number,
  limit?: number,
  debt_notes?: (DebtNote)[],

}` debt notes list
*/
	export namespace DebtNotesList {
		export type RequestParams = {};
		export type RequestQuery = {
			page?: number;
			limit?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			page?: number;
			limit?: number;
			debt_notes?: DebtNote[];
		};
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesCreate
	 * @summary Create new debt note
	 * @request POST:/admin/debt_notes
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesCreate {
		export type RequestParams = {};
		export type RequestQuery = {
			page?: number;
			limit?: number;
		};
		export type RequestBody = {
			name: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesDetail
	 * @summary Get debt note by id
	 * @request GET:/admin/debt_notes/{debt_note_id}
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesDetail {
		export type RequestParams = {
			debtNoteId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesPartialUpdate
	 * @summary Update debt note name
	 * @request PATCH:/admin/debt_notes/{debt_note_id}
	 * @response `200` `DebtNote`
	 */
	export namespace DebtNotesPartialUpdate {
		export type RequestParams = {
			debtNoteId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			name: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = DebtNote;
	}
	/**
	 * No description
	 * @tags debt_note
	 * @name DebtNotesDelete
	 * @summary Delete debt note by id
	 * @request DELETE:/admin/debt_notes/{debt_note_id}
	 * @response `200` `void` delete object
	 */
	export namespace DebtNotesDelete {
		export type RequestParams = {
			debtNoteId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags debt_note
 * @name DebtNotesLoansDetail
 * @summary Get loans tagged with debt note
 * @request GET:/admin/debt_notes/{debt_note_id}/loans
 * @response `200` `{
  page?: number,
  limit?: number,
  loans?: (Loan)[],

}` loan tagged with debt note
*/
	export namespace DebtNotesLoansDetail {
		export type RequestParams = {
			debtNoteId: string;
		};
		export type RequestQuery = {
			page?: number;
			limit?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			page?: number;
			limit?: number;
			loans?: Loan[];
		};
	} /**
 * No description
 * @tags application
 * @name ApplicationsStatusPartialUpdate
 * @summary Update application status
 * @request PATCH:/admin/applications/{application_id}/status
 * @response `200` `Application` ok
 * @response `400` `{
  errors: ({
  error: {
  code: ErrorCode,
  message: string,

},

})[],

}` status change impossible
*/
	export namespace ApplicationsStatusPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/**
			 * new application status
			 * @example Awaiting_Payout
			 */
			status: ApplicationStatuses;
			/** reason for rejecting the application */
			rejection_reason?: RejectionReason;
			/** reason description */
			reject_description?: string;
			/** additional data when changing to Awaiting_Payout status */
			status_awaiting_payout?: {
				fronting_bank_iban?: string;
			};
		};
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsNextStatusValidateDetail
	 * @summary Query if status can be changed, get all failed requirements for status change
	 * @request GET:/admin/applications/{application_id}/next_status_validate
	 * @response `200` `StatusPendingActions` status change is possible if no errors returned
	 */
	export namespace ApplicationsNextStatusValidateDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusPendingActions;
	}
	/**
	 * @description get application pricing
	 * @tags application
	 * @name ApplicationsPricingDetail
	 * @request GET:/admin/applications/{application_id}/pricing
	 * @response `200` `AdminPricing` pricing object
	 */
	export namespace ApplicationsPricingDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminPricing;
	}
	/**
	 * @description set application pricing
	 * @tags application
	 * @name ApplicationsPricingCreate
	 * @request POST:/admin/applications/{application_id}/pricing
	 * @response `200` `Pricing` set pricing object success
	 */
	export namespace ApplicationsPricingCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = IncomingApplicationPricing;
		export type RequestHeaders = {};
		export type ResponseBody = Pricing;
	}
	/**
	 * @description Manually add a prepayment for a loan
	 * @tags payments
	 * @name LoansPrepaymentsCreate
	 * @request POST:/admin/loans/{loan_id}/prepayments
	 * @response `200` `PrepaymentResp` Prepayment added successfully
	 */
	export namespace LoansPrepaymentsCreate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = Prepayment;
		export type RequestHeaders = {};
		export type ResponseBody = PrepaymentResp;
	}
	/**
	 * @description get payments for the installment
	 * @tags installment
	 * @name InstallmentsPaymentsDetail
	 * @request GET:/admin/installments/{installment_id}/payments
	 * @response `200` `InstallmentPaymentsResponse` installment payments
	 */
	export namespace InstallmentsPaymentsDetail {
		export type RequestParams = {
			installmentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = InstallmentPaymentsResponse;
	}
	/**
	 * @description Requst payment for an installment
	 * @tags installment
	 * @name InstallmentsPaymentsCreate
	 * @request POST:/admin/installments/{installment_id}/payments
	 * @response `200` `void` request payment success
	 */
	export namespace InstallmentsPaymentsCreate {
		export type RequestParams = {
			installmentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Manually add a payment transaction to an installment
	 * @tags installment
	 * @name InstallmentsPaymentsManualCreateCreate
	 * @request POST:/admin/installments/{installment_id}/payments/manual_create
	 * @response `200` `void` request payment success
	 */
	export namespace InstallmentsPaymentsManualCreateCreate {
		export type RequestParams = {
			installmentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = BankTransactionCreateData;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Requst that payments for an instsallment are reconciled against posted bank transactions
	 * @tags installment
	 * @name InstallmentsReconcilePaymentsCreate
	 * @request POST:/admin/installments/{installment_id}/reconcile_payments
	 * @response `200` `void` request reconcile success
	 */
	export namespace InstallmentsReconcilePaymentsCreate {
		export type RequestParams = {
			installmentId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags settings
	 * @name SettingsStatusOwnersList
	 * @summary Get application status owner settings
	 * @request GET:/admin/settings/status_owners
	 * @response `200` `(StatusOwnerSettingsItem)[]` success
	 */
	export namespace SettingsStatusOwnersList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusOwnerSettingsItem[];
	}
	/**
	 * No description
	 * @tags settings
	 * @name SettingsStatusOwnersUpdate
	 * @summary Update application status owner settings
	 * @request PUT:/admin/settings/status_owners
	 * @response `200` `void` success
	 */
	export namespace SettingsStatusOwnersUpdate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			settings?: StatusOwnerSettingsItem[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags tasks
 * @name TasksList
 * @summary Get list of Tasks with optional filters
 * @request GET:/admin/tasks
 * @response `200` `{
  pagination?: Pagination,
  result?: (TaskResponse)[],

}` success
*/
	export namespace TasksList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			sort_order?: "ascend" | "descend";
			sort_by?: TaskSortBy;
			/** @example bou_111,bou_222 */
			reporter_id?: string | string[];
			/** @example bouser */
			reporter_type?: string;
			/** @example bou_111,bou_222 */
			assignee_id?: string | string[];
			assignee_type?: TaskAssigneeType;
			/** @example todo,done */
			status?: string | string[];
			/** @example low,medium */
			priority?: string | string[];
			/**
			 * @format date
			 * @example 2020-12-31
			 */
			due_from?: string;
			/**
			 * @format date
			 * @example 2020-12-31
			 */
			due_to?: string;
			/** @example Some description */
			text?: string;
			/** @example app_111,app_222 */
			related_entity_id?: string | string[];
			related_entity_type?: TaskRelatedEntityType;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: TaskResponse[];
		};
	}
	/**
	 * @description Create new Task
	 * @tags tasks
	 * @name TasksCreate
	 * @request POST:/admin/tasks
	 * @response `201` `CreationIDResponse` created
	 */
	export namespace TasksCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateTaskRequest;
		export type RequestHeaders = {};
		export type ResponseBody = CreationIDResponse;
	}
	/**
	 * @description Get Task by ID
	 * @tags tasks
	 * @name TasksDetail
	 * @request GET:/admin/tasks/{id}
	 * @response `200` `TaskResponse` success
	 */
	export namespace TasksDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = TaskResponse;
	}
	/**
	 * @description Update Task details
	 * @tags tasks
	 * @name TasksPartialUpdate
	 * @request PATCH:/admin/tasks/{id}
	 * @response `200` `void` success
	 */
	export namespace TasksPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateTaskRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Delete Task
	 * @tags tasks
	 * @name TasksDelete
	 * @request DELETE:/admin/tasks/{id}
	 * @response `200` `void` success
	 */
	export namespace TasksDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description Get users
 * @tags users
 * @name UsersList
 * @request GET:/admin/users
 * @response `200` `{
  pagination: Pagination,
  result: (UserResponse)[],

}` success
*/
	export namespace UsersList {
		export type RequestParams = {};
		export type RequestQuery = {
			text?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: Pagination;
			result: UserResponse[];
		};
	}
	/**
	 * @description Get user data
	 * @tags users
	 * @name UsersDetail
	 * @request GET:/admin/users/{id}
	 * @response `200` `UserResponse` success
	 */
	export namespace UsersDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserResponse;
	}
	/**
	 * @description Unlock user login
	 * @tags users
	 * @name UsersUnlockPartialUpdate
	 * @request PATCH:/admin/users/{id}/unlock
	 * @response `200` `void` success
	 */
	export namespace UsersUnlockPartialUpdate {
		export type RequestParams = {
			/** @example usr_123 */
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description Get user companies
 * @tags users
 * @name UsersCompaniesDetail
 * @request GET:/admin/users/{id}/companies
 * @response `200` `{
  pagination: Pagination,
  result: (CompanySearchResult)[],

}` success
*/
	export namespace UsersCompaniesDetail {
		export type RequestParams = {
			/** @example d6c7d9b8-25d0-49d4-be37-ec4f67d40859 */
			id: string;
		};
		export type RequestQuery = {
			/** exact name */
			name?: string;
			/** text search */
			text?: string;
			post_code?: string;
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: Pagination;
			result: CompanySearchResult[];
		};
	}
	/**
	 * @description Get user applications
	 * @tags users
	 * @name UsersApplicationsDetail
	 * @request GET:/admin/users/{id}/applications
	 * @response `200` `ApplicationsList` success
	 */
	export namespace UsersApplicationsDetail {
		export type RequestParams = {
			/** @example d6c7d9b8-25d0-49d4-be37-ec4f67d40859 */
			id: string;
		};
		export type RequestQuery = {
			/** text search */
			text?: string;
			status?: string;
			provider?: string;
			status_owner_id?: string;
			account_manager_id?: string;
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationsList;
	}
	/**
	 * @description Resend confirmation email
	 * @tags users
	 * @name UsersResendConfEmailCreate
	 * @request POST:/admin/users/{id}/resend_conf_email
	 * @response `200` `void` success
	 */
	export namespace UsersResendConfEmailCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Reset user
	 * @tags users
	 * @name UsersResetCreate
	 * @request POST:/admin/users/{id}/reset
	 * @response `200` `void` success
	 */
	export namespace UsersResetCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description Get companies
 * @tags companies
 * @name CompaniesList
 * @request GET:/admin/companies
 * @response `200` `{
  pagination: Pagination,
  result: (CompanySearchResult)[],

}` success
*/
	export namespace CompaniesList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			/** exact name */
			name?: string;
			/** text search */
			text?: string;
			post_code?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: Pagination;
			result: CompanySearchResult[];
		};
	}
	/**
	 * @description Get company by id
	 * @tags companies
	 * @name CompaniesDetail
	 * @request GET:/admin/companies/{companyId}
	 * @response `200` `AdminCompanyResponse` success
	 */
	export namespace CompaniesDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminCompanyResponse;
	} /**
 * @description Update company by id
 * @tags companies
 * @name CompaniesPartialUpdate
 * @request PATCH:/admin/companies/{companyId}
 * @response `200` `{
  companyId: string,
  taxIdNumber?: string,
  businessPurpose?: string,
  crm_id?: string,

}` success
*/
	export namespace CompaniesPartialUpdate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			taxIdNumber?: string;
			businessPurpose?: string;
			crm_id?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = {
			companyId: string;
			taxIdNumber?: string;
			businessPurpose?: string;
			crm_id?: string;
		};
	}
	/**
	 * No description
	 * @tags partner
	 * @name CompaniesApplicationsDetail
	 * @summary Get applications for a specific company
	 * @request GET:/admin/companies/{companyId}/applications
	 * @response `200` `ApplicationsList` applications list
	 */
	export namespace CompaniesApplicationsDetail {
		export type RequestParams = {
			id: string;
			companyId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationsList;
	}
	/**
	 * @description Add industry codes to company
	 * @tags companies
	 * @name CompaniesIndustryCodesPartialUpdate
	 * @request PATCH:/admin/companies/{companyId}/industry_codes
	 * @response `200` `void` success
	 */
	export namespace CompaniesIndustryCodesPartialUpdate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = IndustryCodesCreateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Remove latest industry codes from company
	 * @tags companies
	 * @name CompaniesIndustryCodesDelete
	 * @request DELETE:/admin/companies/{companyId}/industry_codes
	 * @response `200` `void` success
	 */
	export namespace CompaniesIndustryCodesDelete {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description Get companies
 * @tags companies
 * @name CompaniesUsersDetail
 * @request GET:/admin/companies/{company_name}/{company_postcode}/users
 * @response `200` `{
  pagination: LegacyPagination,
  companyUsers: (CompanyUserResponse)[],

}` success
*/
	export namespace CompaniesUsersDetail {
		export type RequestParams = {
			/** exact name */
			companyName: string;
			/** text search */
			companyPostcode: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: LegacyPagination;
			companyUsers: CompanyUserResponse[];
		};
	} /**
 * @description Check company credit check
 * @tags companies
 * @name CompaniesCreditCheckPartialUpdate
 * @request PATCH:/admin/companies/{companyId}/credit_check
 * @response `200` `void` success
 * @response `400` `{
  errors?: ({
  error?: {
  code?: ErrorCode,

},

})[],

}` status change impossible
*/
	export namespace CompaniesCreditCheckPartialUpdate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get company credit scores
	 * @tags companies
	 * @name CompaniesCreditScoreDetail
	 * @request GET:/admin/companies/{companyId}/credit_score
	 * @response `200` `(CompanyCreditScore)[]` success
	 */
	export namespace CompaniesCreditScoreDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {
			pageNum?: number;
			pageSize?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyCreditScore[];
	}
	/**
	 * No description
	 * @tags financials
	 * @name CompaniesFinancialDataCreate
	 * @request POST:/admin/companies/{company_id}/financial_data
	 * @response `200` `CompanyFinancialDataResponse` created financials
	 */
	export namespace CompaniesFinancialDataCreate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			financials: CompanyFinancialDataRequest[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = CompanyFinancialDataResponse;
	}
	/**
	 * No description
	 * @tags financials
	 * @name CompaniesFinancialDataDetail
	 * @request GET:/admin/companies/{company_id}/financial_data
	 * @response `200` `CompanyFinancialDataResponse` get financials
	 */
	export namespace CompaniesFinancialDataDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyFinancialDataResponse;
	}
	/**
	 * No description
	 * @tags kpis
	 * @name CompaniesKpisDetail
	 * @request GET:/admin/companies/{company_id}/kpis
	 * @response `200` `CompanyKPIsResponse` get company kpis
	 */
	export namespace CompaniesKpisDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyKPIsResponse;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresCreate
	 * @request POST:/admin/companies/{company_id}/scores
	 * @response `200` `CompanyScore` created company score
	 */
	export namespace CompaniesScoresCreate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = CompanyScoreRequest;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyScore;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresDetail
	 * @request GET:/admin/companies/{company_id}/scores
	 * @response `200` `CompanyScoresResponse` get company scores
	 */
	export namespace CompaniesScoresDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			/** @example scfg_123,scfg_456 */
			scoring_config_id?: string;
			/** @example bou_123,bou_456 */
			created_by_entity_id?: string;
			is_approved?: boolean;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyScoresResponse;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresDetail2
	 * @summary Get company score
	 * @request GET:/admin/companies/{company_id}/scores/{score_id}
	 * @originalName companiesScoresDetail
	 * @duplicate
	 * @response `200` `CompanyScore` company score
	 */
	export namespace CompaniesScoresDetail2 {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyScore;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresDelete
	 * @summary Delete company score
	 * @request DELETE:/admin/companies/{company_id}/scores/{score_id}
	 * @response `200` `void` deleted company score
	 */
	export namespace CompaniesScoresDelete {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresFinancialDataDetail
	 * @summary Get company score financial data
	 * @request GET:/admin/companies/{company_id}/scores/{score_id}/financial_data
	 * @response `200` `CompanyFinancialDataResponse` company score financial data
	 */
	export namespace CompaniesScoresFinancialDataDetail {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyFinancialDataResponse;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresKpisDetail
	 * @summary Get company score KPIs
	 * @request GET:/admin/companies/{company_id}/scores/{score_id}/kpis
	 * @response `200` `CompanyKPIsResponse` company score KPIs
	 */
	export namespace CompaniesScoresKpisDetail {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanyKPIsResponse;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresApprovePartialUpdate
	 * @summary Approve company score
	 * @request PATCH:/admin/companies/{company_id}/scores/{score_id}/approve
	 * @response `200` `void` approved company score
	 */
	export namespace CompaniesScoresApprovePartialUpdate {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags scores
	 * @name CompaniesScoresUnapprovePartialUpdate
	 * @summary Unapprove company score
	 * @request PATCH:/admin/companies/{company_id}/scores/{score_id}/unapprove
	 * @response `200` `void` unapproved company score
	 */
	export namespace CompaniesScoresUnapprovePartialUpdate {
		export type RequestParams = {
			companyId: string;
			scoreId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get list of documents for the company
	 * @tags documents
	 * @name CompaniesDocumentsDetail
	 * @request GET:/admin/companies/{company_id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the company
	 */
	export namespace CompaniesDocumentsDetail {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			related_entity_id?: string;
			related_entity_kind?: RelatedEntityKind;
			/** comma-separated list of DocumentCategoryName */
			document_category?: DocumentCategoryName | DocumentCategoryName[];
			/** comma-separated list of DocumentTypeName */
			document_type?: DocumentTypeName | DocumentTypeName[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description Upload a document for the company
	 * @tags documents
	 * @name CompaniesDocumentsCreate
	 * @request POST:/admin/companies/{company_id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace CompaniesDocumentsCreate {
		export type RequestParams = {
			companyId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name CompaniesDocumentsDownloadUrlDetail
	 * @request GET:/admin/companies/{company_id}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace CompaniesDocumentsDownloadUrlDetail {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
	/**
	 * @description Set successful upload flag after the document has been uploaded
	 * @tags documents
	 * @name CompaniesDocumentsUploadSuccessPartialUpdate
	 * @request PATCH:/admin/companies/{company_id}/documents/{docId}/upload_success
	 * @response `200` `void` Ok
	 */
	export namespace CompaniesDocumentsUploadSuccessPartialUpdate {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadSuccessRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update document
	 * @tags documents
	 * @name CompaniesDocumentsUpdate
	 * @request PUT:/admin/companies/{company_id}/documents{docId}
	 * @response `200` `void` updated finance object
	 */
	export namespace CompaniesDocumentsUpdate {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags documents
	 * @name CompaniesDocumentsDelete
	 * @summary Delete document
	 * @request DELETE:/admin/companies/{company_id}/documents{docId}
	 * @response `200` `void` deleted document
	 */
	export namespace CompaniesDocumentsDelete {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update document
	 * @tags documents
	 * @name CompaniesDocumentsRenamePartialUpdate
	 * @request PATCH:/admin/companies/{company_id}/documents{docId}/rename
	 * @response `200` `void` successful change
	 */
	export namespace CompaniesDocumentsRenamePartialUpdate {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			document_name: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update document
	 * @tags documents
	 * @name CompaniesDocumentsClientVisiblePartialUpdate
	 * @request PATCH:/admin/companies/{company_id}/documents{docId}/client_visible
	 * @response `200` `void` successful change
	 */
	export namespace CompaniesDocumentsClientVisiblePartialUpdate {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			is_client_visible: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update document
	 * @tags documents
	 * @name CompaniesDocumentsTypePartialUpdate
	 * @request PATCH:/admin/companies/{company_id}/documents{docId}/type
	 * @response `200` `void` successful change
	 */
	export namespace CompaniesDocumentsTypePartialUpdate {
		export type RequestParams = {
			companyId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			document_type: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags files
	 * @name CompaniesFilesArchiveDetail
	 * @summary download files in archive
	 * @request GET:/admin/companies/{company_id}/files_archive/{file_ids}
	 * @response `200` `File` zip archive with selected files
	 */
	export namespace CompaniesFilesArchiveDetail {
		export type RequestParams = {
			companyId: string;
			/** list of comma-separated fileIds from company to be downloaded */
			fileIds: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = File;
	}
	/**
	 * @description set all idents as completed ( testing tool ). Only works on dev/local/test environment for user with dev_only_bypass_checks permission
	 * @tags dev-hacks
	 * @name DevHacksApplicationsCompleteIdentsCreate
	 * @request POST:/admin/dev-hacks/applications/{application_id}/complete_idents
	 * @response `200` `void` success
	 */
	export namespace DevHacksApplicationsCompleteIdentsCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description set idents for specific signatory as completed ( testing tool ). Only works on dev/local/test environment for user with dev_only_bypass_checks permission
	 * @tags dev-hacks
	 * @name DevHacksApplicationsCompleteIdentsCreate2
	 * @request POST:/admin/dev-hacks/applications/{application_id}/complete_idents/{signatory_id}
	 * @originalName devHacksApplicationsCompleteIdentsCreate
	 * @duplicate
	 * @response `200` `void` success
	 */
	export namespace DevHacksApplicationsCompleteIdentsCreate2 {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description List notes
 * @tags notes
 * @name NotesList
 * @request GET:/admin/notes
 * @response `200` `{
  pagination?: Pagination,
  result?: (NoteResponse)[],

}` success
*/
	export namespace NotesList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			subject_kind?: NoteSubjectKind;
			subject_id?: string;
			created_by?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: NoteResponse[];
		};
	}
	/**
	 * @description Create Note
	 * @tags notes
	 * @name NotesCreate
	 * @request POST:/admin/notes
	 * @response `200` `NoteResponse` success
	 */
	export namespace NotesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = CreateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Get single Note
	 * @tags notes
	 * @name NotesDetail
	 * @request GET:/admin/notes/{id}
	 * @response `200` `NoteResponse` success
	 */
	export namespace NotesDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Update Note
	 * @tags notes
	 * @name NotesPartialUpdate
	 * @request PATCH:/admin/notes/{id}
	 * @response `200` `NoteResponse` success
	 * @response `403` `void` forbidden
	 */
	export namespace NotesPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Delete Note
	 * @tags notes
	 * @name NotesDelete
	 * @request DELETE:/admin/notes/{id}
	 * @response `200` `void` success
	 * @response `403` `void` forbidden
	 */
	export namespace NotesDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description List loan notes
 * @tags notes
 * @name LoansLoanIdNotesList
 * @request GET:/admin/loans/:loan_id/notes
 * @response `200` `{
  pagination?: Pagination,
  result?: (NoteResponse)[],

}` list of notes for a loan
*/
	export namespace LoansLoanIdNotesList {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			loan_id: string;
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: NoteResponse[];
		};
	}
	/**
	 * @description Create Note for a loan
	 * @tags notes
	 * @name LoansLoanIdNotesCreate
	 * @request POST:/admin/loans/:loan_id/notes
	 * @response `200` `NoteResponse` success
	 */
	export namespace LoansLoanIdNotesCreate {
		export type RequestParams = {
			loanId: string;
		};
		export type RequestQuery = {
			loan_id: string;
		};
		export type RequestBody = CreateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description get specific note
	 * @tags notes
	 * @name LoansLoanIdNotesNoteIdList
	 * @request GET:/admin/loans/:loan_id/notes/:note_id
	 * @response `200` `NoteResponse` list of notes for a loan
	 */
	export namespace LoansLoanIdNotesNoteIdList {
		export type RequestParams = {
			loanId: string;
			noteId: string;
		};
		export type RequestQuery = {
			loan_id: string;
			note_id: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Update note
	 * @tags notes
	 * @name LoansLoanIdNotesNoteIdPartialUpdate
	 * @request PATCH:/admin/loans/:loan_id/notes/:note_id
	 * @response `200` `NoteResponse` success
	 */
	export namespace LoansLoanIdNotesNoteIdPartialUpdate {
		export type RequestParams = {
			loanId: string;
			noteId: string;
		};
		export type RequestQuery = {
			loan_id: string;
			note_id: string;
		};
		export type RequestBody = UpdateNoteRequest;
		export type RequestHeaders = {};
		export type ResponseBody = NoteResponse;
	}
	/**
	 * @description Delete Note
	 * @tags notes
	 * @name LoansLoanIdNotesNoteIdDelete
	 * @request DELETE:/admin/loans/:loan_id/notes/:note_id
	 * @response `200` `void` success
	 * @response `403` `void` forbidden
	 */
	export namespace LoansLoanIdNotesNoteIdDelete {
		export type RequestParams = {
			loanId: string;
			noteId: string;
		};
		export type RequestQuery = {
			loan_id: string;
			note_id: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags tenants
	 * @name TenantsList
	 * @summary List tenants
	 * @request GET:/admin/tenants
	 * @response `200` `(Tenant)[]` tenants
	 */
	export namespace TenantsList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant[];
	}
	/**
	 * @description Create new tenant
	 * @tags tenants
	 * @name TenantsCreate
	 * @request POST:/admin/tenants
	 * @response `200` `Tenant` created object
	 */
	export namespace TenantsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = TenantCreateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	}
	/**
	 * @description update tenant
	 * @tags tenants
	 * @name TenantsPartialUpdate
	 * @request PATCH:/admin/tenants/{id}
	 * @response `200` `Tenant` updated object
	 */
	export namespace TenantsPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = Tenant;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	}
	/**
	 * No description
	 * @tags tenants
	 * @name TenantsDelete
	 * @summary delete tenants
	 * @request DELETE:/admin/tenants/{id}
	 * @response `200` `void` 200 ok deleted, response with no data
	 */
	export namespace TenantsDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get tenant
	 * @tags tenants
	 * @name TenantsDetail
	 * @request GET:/admin/tenants/{id}
	 * @response `200` `Tenant` tenant object
	 */
	export namespace TenantsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Tenant;
	} /**
 * @description create partner
 * @tags partner
 * @name PartnersCreate
 * @request POST:/admin/partners
 * @response `200` `{
  partner_id: string,

}` newly created partner id
*/
	export namespace PartnersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = PartnerCreateData;
		export type RequestHeaders = {};
		export type ResponseBody = {
			partner_id: string;
		};
	} /**
 * @description get partners
 * @tags partner
 * @name PartnersList
 * @request GET:/admin/partners
 * @response `200` `{
  pagination: {
  total_rows?: number,
  current_page?: number,
  items_per_page?: number,

},
  result: (PartnerListResponseItem)[],

}` partners
*/
	export namespace PartnersList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			text?: string;
			status: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: {
				total_rows?: number;
				current_page?: number;
				items_per_page?: number;
			};
			result: PartnerListResponseItem[];
		};
	}
	/**
	 * @description update partner
	 * @tags partner
	 * @name PartnersPartialUpdate
	 * @request PATCH:/admin/partners/{id}
	 * @response `200` `void` updated partner
	 */
	export namespace PartnersPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerUpdateData;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description delete partner
	 * @tags partner
	 * @name PartnersDelete
	 * @request DELETE:/admin/partners/{id}
	 * @response `200` `void` Deleted partner
	 */
	export namespace PartnersDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get partner
	 * @tags partner
	 * @name PartnersDetail
	 * @request GET:/admin/partners/{id}
	 * @response `200` `PartnerResponse` partner
	 */
	export namespace PartnersDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerResponse;
	}
	/**
	 * @description add new bank info to partner
	 * @tags partner
	 * @name PartnersCrmDataPartialUpdate
	 * @request PATCH:/admin/partners/{id}/crm_data
	 * @response `200` `void` success
	 */
	export namespace PartnersCrmDataPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			crm_id?: string;
			crm_link?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description add new contact person to partner
	 * @tags partner
	 * @name PartnersContactPersonsCreate
	 * @request POST:/admin/partners/{id}/contact_persons
	 * @response `200` `void` success
	 */
	export namespace PartnersContactPersonsCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerContactPersonUpsertData;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description update partner contact person
	 * @tags partner
	 * @name PartnersContactPersonsPartialUpdate
	 * @request PATCH:/admin/partners/{id}/contact_persons/{cp_id}
	 * @response `200` `void` success
	 */
	export namespace PartnersContactPersonsPartialUpdate {
		export type RequestParams = {
			/** partner id */
			id: string;
			/** contact person id */
			cpId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerContactPersonUpsertData;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description delete partner contact person
	 * @tags partner
	 * @name PartnersContactPersonsDelete
	 * @request DELETE:/admin/partners/{id}/contact_persons/{cp_id}
	 * @response `200` `void` success
	 */
	export namespace PartnersContactPersonsDelete {
		export type RequestParams = {
			/** partner id */
			id: string;
			/** contact person id */
			cpId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @tags partner
 * @name PartnersPusersDetail
 * @summary Get partner users
 * @request GET:/admin/partners/{id}/pusers
 * @response `200` `{
  pagination?: Pagination,
  result?: (PartnerUser)[],

}` tenants
*/
	export namespace PartnersPusersDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: PartnerUser[];
		};
	}
	/**
	 * No description
	 * @tags partner
	 * @name PartnersPusersCreate
	 * @summary Create new user(s)
	 * @request POST:/admin/partners/{id}/pusers
	 * @response `200` `void` successfully created user(s)
	 */
	export namespace PartnersPusersCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			pusers: {
				email: string;
				name: string;
				mobile_number: string;
				role_id: string;
			}[];
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags partner
	 * @name PartnersEnablePartialUpdate
	 * @summary Enable the partner
	 * @request PATCH:/admin/partners/{id}/enable
	 * @response `200` `void` successfully enabled partner
	 */
	export namespace PartnersEnablePartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			is_enabled?: boolean;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description update partner config
	 * @tags partner
	 * @name PartnersConfigPartialUpdate
	 * @request PATCH:/admin/partners/{id}/config
	 * @response `200` `PartnerConfig` updated config
	 */
	export namespace PartnersConfigPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerConfigRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfig;
	}
	/**
	 * @description get partner config
	 * @tags partner
	 * @name PartnersConfigDetail
	 * @request GET:/admin/partners/{id}/config
	 * @response `200` `PartnerConfigWithSelectors` partner config
	 */
	export namespace PartnersConfigDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerConfigWithSelectors;
	}
	/**
	 * @description create partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionCreate
	 * @request POST:/admin/partners/{id}/default_product_selection
	 */
	export namespace PartnersDefaultProductSelectionCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerDefaultProductSelectorRequest;
		export type RequestHeaders = {};
		export type ResponseBody = any;
	}
	/**
	 * @description get partner default products selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionDetail
	 * @request GET:/admin/partners/{id}/default_product_selection
	 * @response `200` `(PartnerDefaultProductSelector)[]` Get partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerDefaultProductSelector[];
	}
	/**
	 * No description
	 * @tags partner
	 * @name PartnersApplicationsDetail
	 * @summary Get applications for a specific partner
	 * @request GET:/admin/partners/{id}/applications
	 * @response `200` `ApplicationsList` applications list
	 */
	export namespace PartnersApplicationsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ApplicationsList;
	}
	/**
	 * @description update partner user
	 * @tags pusers
	 * @name PusersPartialUpdate
	 * @request PATCH:/admin/pusers/{id}
	 * @response `200` `void` updated partner user
	 */
	export namespace PusersPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			email: string;
			name: string;
			mobile_number: string;
			role_id: string;
			interface_language?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get partner user
	 * @tags pusers
	 * @name PusersDetail
	 * @request GET:/admin/pusers/{id}
	 * @response `200` `PartnerUser` Get partner user
	 */
	export namespace PusersDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerUser;
	}
	/**
	 * No description
	 * @tags pusers
	 * @name PusersDelete
	 * @summary Delete the puser (partner user) account
	 * @request DELETE:/admin/pusers/{id}
	 * @response `200` `void` successfully deleted the puser
	 */
	export namespace PusersDelete {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Unlock puser login
	 * @tags users
	 * @name PusersUnlockPartialUpdate
	 * @request PATCH:/admin/pusers/{id}/unlock
	 * @response `200` `void` success
	 */
	export namespace PusersUnlockPartialUpdate {
		export type RequestParams = {
			/** @example usr_123 */
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags pusers
	 * @name PusersEnableCreate
	 * @summary Enable the puser (partner user) account
	 * @request POST:/admin/pusers/{id}/enable
	 * @response `200` `void` successfully enabled puser
	 */
	export namespace PusersEnableCreate {
		export type RequestParams = {
			puserId: string;
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags pusers
	 * @name PusersDisableCreate
	 * @summary Disable the puser (partner user) account
	 * @request POST:/admin/pusers/{id}/disable
	 * @response `200` `void` successfully disabled puser
	 */
	export namespace PusersDisableCreate {
		export type RequestParams = {
			puserId: string;
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags pusers
	 * @name PusersResendInviteCreate
	 * @summary Resend the account setup invitation for the partner user. User will receive an email with a link for setting a password.
	 * @request POST:/admin/pusers/{id}/resend_invite
	 * @response `200` `void` successful
	 * @response `404` `void` not found
	 * @response `409` `void` conflict, puser already accepted invite
	 */
	export namespace PusersResendInviteCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * @description Check company credit check
 * @tags applications
 * @name ApplicationsCreditCheckPartialUpdate
 * @request PATCH:/admin/applications/{applicationId}/credit_check
 * @response `200` `void` success
 * @response `400` `{
  errors?: ({
  error?: {
  code?: ErrorCode,

},

})[],

}` status change impossible
*/
	export namespace ApplicationsCreditCheckPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	} /**
 * No description
 * @name ApplicationsSignatoriesInviteCreate
 * @summary Invite signatory as a user to application
 * @request POST:/admin/applications/{application_id}/signatories/{signatory_id}/invite
 * @response `200` `{
  email_sent?: boolean,
  invite_status?: InviteStatus,

}` invitation and email status
*/
	export namespace ApplicationsSignatoriesInviteCreate {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @format email */
			email?: string;
			/** @example DE */
			region?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = {
			email_sent?: boolean;
			invite_status?: InviteStatus;
		};
	}
	/**
	 * No description
	 * @name ApplicationsSignatoriesInviteDelete
	 * @summary Delete signatory invite, unlink user from application
	 * @request DELETE:/admin/applications/{application_id}/signatories/{signatory_id}/invite
	 * @response `200` `void` deleted
	 */
	export namespace ApplicationsSignatoriesInviteDelete {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description update partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionPartialUpdate
	 * @request PATCH:/admin/partners/{id}/default_product_selection/{default_product_selection_id}
	 * @response `200` `PartnerDefaultProductSelector` updated partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionPartialUpdate {
		export type RequestParams = {
			id: string;
			defaultProductSelectionId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerDefaultProductSelectorRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerDefaultProductSelector;
	}
	/**
	 * @description delete partner default product selector
	 * @tags partner
	 * @name PartnersDefaultProductSelectionDelete
	 * @request DELETE:/admin/partners/{id}/default_product_selection/{default_product_selection_id}
	 * @response `200` `void` Deleted partner default product selector
	 */
	export namespace PartnersDefaultProductSelectionDelete {
		export type RequestParams = {
			id: string;
			defaultProductSelectionId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description get admin public instance config
	 * @tags config
	 * @name PublicConfigList
	 * @request GET:/admin/public_config
	 * @response `200` `AdminConfigResponse` public instance config
	 */
	export namespace PublicConfigList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = AdminConfigResponse;
	}
	/**
	 * @description Get list of document categories
	 * @tags document_categories
	 * @name DocumentConfigurationsDocumentCategoriesList
	 * @request GET:/admin/document_configurations/document_categories
	 * @response `200` `DocumentCategoriesResponse` List of document categories
	 */
	export namespace DocumentConfigurationsDocumentCategoriesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentCategoriesResponse;
	}
	/**
	 * @description Creates document category
	 * @tags document_categories
	 * @name DocumentConfigurationsDocumentCategoriesCreate
	 * @request POST:/admin/document_configurations/document_categories
	 * @response `200` `DocumentConfigCategory` Created document category
	 */
	export namespace DocumentConfigurationsDocumentCategoriesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = DocumentConfigCategory;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfigCategory;
	}
	/**
	 * @description Get document category
	 * @tags document_categories
	 * @name DocumentConfigurationsDocumentCategoriesDetail
	 * @request GET:/admin/document_configurations/document_categories/{id}
	 * @response `200` `DocumentConfigCategory` Get a document category
	 */
	export namespace DocumentConfigurationsDocumentCategoriesDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfigCategory;
	}
	/**
	 * @description Update document category
	 * @tags document_categories
	 * @name DocumentConfigurationsDocumentCategoriesPartialUpdate
	 * @request PATCH:/admin/document_configurations/document_categories/{id}
	 * @response `200` `DocumentConfigCategory` Updated document category
	 */
	export namespace DocumentConfigurationsDocumentCategoriesPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentCategoryRequest;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfigCategory;
	}
	/**
	 * @description Get list of document types
	 * @tags document_types
	 * @name DocumentConfigurationsDocumentTypesList
	 * @request GET:/admin/document_configurations/document_types
	 * @response `200` `DocumentTypesResponse` List of document types
	 */
	export namespace DocumentConfigurationsDocumentTypesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTypesResponse;
	}
	/**
	 * @description Creates document type
	 * @tags document_types
	 * @name DocumentConfigurationsDocumentTypesCreate
	 * @request POST:/admin/document_configurations/document_types
	 * @response `200` `DocumentTypeConfig` Created document type
	 */
	export namespace DocumentConfigurationsDocumentTypesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = DocumentTypeRequest;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTypeConfig;
	}
	/**
	 * @description Get document type
	 * @tags document_types
	 * @name DocumentConfigurationsDocumentTypesDetail
	 * @request GET:/admin/document_configurations/document_types/{id}
	 * @response `200` `DocumentTypeConfig` Get a document type
	 */
	export namespace DocumentConfigurationsDocumentTypesDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTypeConfig;
	}
	/**
	 * @description Update document type
	 * @tags document_types
	 * @name DocumentConfigurationsDocumentTypesPartialUpdate
	 * @request PATCH:/admin/document_configurations/document_types/{id}
	 * @response `200` `DocumentTypeConfig` Updated document type
	 */
	export namespace DocumentConfigurationsDocumentTypesPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentTypeRequest;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentTypeConfig;
	}
	/**
	 * @description Get list of document configurations
	 * @tags document_configurations
	 * @name DocumentConfigurationsList
	 * @request GET:/admin/document_configurations
	 * @response `200` `DocumentConfigurationsResponse` List of document configurations
	 */
	export namespace DocumentConfigurationsList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfigurationsResponse;
	}
	/**
	 * @description Creates document configuration
	 * @tags document_configurations
	 * @name DocumentConfigurationsCreate
	 * @request POST:/admin/document_configurations
	 * @response `200` `DocumentConfiguration` Created document configuration
	 */
	export namespace DocumentConfigurationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentConfigurationRequest;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfiguration;
	}
	/**
	 * @description Get document configuration
	 * @tags document_configurations
	 * @name DocumentConfigurationsDetail
	 * @request GET:/admin/document_configurations/{id}
	 * @response `200` `DocumentConfiguration` Get a document configuration
	 */
	export namespace DocumentConfigurationsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfiguration;
	}
	/**
	 * @description Update document configuration
	 * @tags document_configurations
	 * @name DocumentConfigurationsPartialUpdate
	 * @request PATCH:/admin/document_configurations/{id}
	 * @response `200` `DocumentConfiguration` Updated document configuration
	 */
	export namespace DocumentConfigurationsPartialUpdate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentConfigurationRequest;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentConfiguration;
	}
	/**
	 * @description Get list of documents for the application
	 * @tags documents
	 * @name ApplicationsDocumentsDetail
	 * @request GET:/admin/applications/{id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the application
	 */
	export namespace ApplicationsDocumentsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			related_entity_id?: string;
			related_entity_kind?: RelatedEntityKind;
			/** comma-separated list of DocumentCategoryName */
			document_category?: DocumentCategoryName | DocumentCategoryName[];
			/** comma-separated list of DocumentTypeName */
			document_type?: DocumentTypeName | DocumentTypeName[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description Upload a document for the application
	 * @tags documents
	 * @name ApplicationsDocumentsCreate
	 * @request POST:/admin/applications/{id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace ApplicationsDocumentsCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Upload a document for the application
	 * @tags documents
	 * @name LoansDocumentsCreate
	 * @request POST:/admin/loans/{id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace LoansDocumentsCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Get list of documents for the application and loan
	 * @tags documents
	 * @name LoansDocumentsWithApplicationDetail
	 * @request GET:/admin/loans/{id}/documents_with_application
	 * @response `200` `CombinedDocumentsResponse` Pagination and list of documents for the application
	 */
	export namespace LoansDocumentsWithApplicationDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CombinedDocumentsResponse;
	}
	/**
	 * @description Update document type
	 * @tags documents
	 * @name ApplicationsDocumentsTypePartialUpdate
	 * @request PATCH:/admin/applications/{appId}/documents/{docId}/type
	 * @response `200` `void` Updated document type
	 */
	export namespace ApplicationsDocumentsTypePartialUpdate {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentTypePayload;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Update document type
	 * @tags documents
	 * @name LoansDocumentsTypePartialUpdate
	 * @request PATCH:/admin/loans/{loanId}/documents/{docId}/type
	 * @response `200` `void` Updated document type
	 */
	export namespace LoansDocumentsTypePartialUpdate {
		export type RequestParams = {
			loanId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateDocumentTypePayload;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description update document visibility for client
	 * @tags documents
	 * @name ApplicationsDocumentsClientVisiblePartialUpdate
	 * @request PATCH:/admin/applications/{appId}/documents/{docId}/client_visible
	 * @response `200` `void` ok
	 */
	export namespace ApplicationsDocumentsClientVisiblePartialUpdate {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = DocumentVisibility;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name LoansDocumentsDownloadUrlDetail
	 * @request GET:/admin/loans/{appId}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace LoansDocumentsDownloadUrlDetail {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name ApplicationsDocumentsDownloadUrlDetail
	 * @request GET:/admin/applications/{appId}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace ApplicationsDocumentsDownloadUrlDetail {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
	/**
	 * @description Set successful upload flag after the document has been uploaded
	 * @tags documents
	 * @name ApplicationsDocumentsUploadSuccessPartialUpdate
	 * @request PATCH:/admin/applications/{id}/documents/{docId}/upload_success
	 * @response `200` `void` Ok
	 */
	export namespace ApplicationsDocumentsUploadSuccessPartialUpdate {
		export type RequestParams = {
			id: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadSuccessRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Set successful upload flag after the document has been uploaded
	 * @tags documents
	 * @name LoansDocumentsUploadSuccessPartialUpdate
	 * @request PATCH:/admin/loans/{id}/documents/{docId}/upload_success
	 * @response `200` `void` Ok
	 */
	export namespace LoansDocumentsUploadSuccessPartialUpdate {
		export type RequestParams = {
			id: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadSuccessRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Creates global rate
	 * @tags global_rates
	 * @name GlobalRatesCreate
	 * @request POST:/admin/global_rates
	 * @response `200` `GlobalRate` Created global rate
	 */
	export namespace GlobalRatesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = GlobalRateRequest;
		export type RequestHeaders = {};
		export type ResponseBody = GlobalRate;
	} /**
 * @description List global rates
 * @tags global_rates
 * @name GlobalRatesList
 * @request GET:/admin/global_rates
 * @response `200` `{
  pagination: Pagination,
  result: (GlobalRate)[],

}` list of global rates
*/
	export namespace GlobalRatesList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination: Pagination;
			result: GlobalRate[];
		};
	}
	/**
	 * @description List latest global rates per type (name and period)
	 * @tags global_rates
	 * @name GlobalRatesLatestList
	 * @request GET:/admin/global_rates/latest
	 * @response `200` `(GlobalRate)[]` list of latest global rates
	 */
	export namespace GlobalRatesLatestList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = GlobalRate[];
	} /**
 * No description
 * @name PartnersFilterValuesDetail
 * @summary get possible statuses, types for a partner
 * @request GET:/admin/partners/{partner_id}/filter_values
 * @response `200` `{
  all_statuses?: (ApplicationStatuses)[],
  existing_statuses?: (ApplicationStatuses)[],
  compartments?: (Compartment)[],
  application_types?: ("general_liquidity" | "object_financing" | "real_estate" | "factoring" | "other")[],

}` statuses
*/
	export namespace PartnersFilterValuesDetail {
		export type RequestParams = {
			partnerId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			all_statuses?: ApplicationStatuses[];
			existing_statuses?: ApplicationStatuses[];
			compartments?: Compartment[];
			application_types?: (
				| "general_liquidity"
				| "object_financing"
				| "real_estate"
				| "factoring"
				| "other"
			)[];
		};
	} /**
 * No description
 * @name FilterValuesList
 * @summary get possible statuses and compartments
 * @request GET:/admin/filter_values
 * @response `200` `{
  all_statuses?: (ApplicationStatuses)[],
  compartments?: (string)[],
  partners?: (FilterValuesPartners)[],
  products?: (FilterValuesProducts)[],

}` possible values to filter on
*/
	export namespace FilterValuesList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			all_statuses?: ApplicationStatuses[];
			compartments?: string[];
			partners?: FilterValuesPartners[];
			products?: FilterValuesProducts[];
		};
	}
	/**
	 * No description
	 * @name ApplicationsPayoutChecklistDetail
	 * @summary get payout checklist items
	 * @request GET:/admin/applications/{application_id}/payout_checklist
	 * @response `200` `Checklist` possible values to filter on
	 */
	export namespace ApplicationsPayoutChecklistDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = Checklist;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPayoutChecklistPartialUpdate
	 * @summary update payout checklist
	 * @request PATCH:/admin/applications/{application_id}/payout_checklist/{checklist_id}
	 * @response `200` `Checklist` updated payout checklist
	 */
	export namespace ApplicationsPayoutChecklistPartialUpdate {
		export type RequestParams = {
			applicationId: string;
			checklistId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			/** map where key is id of the item and value is selected state or text */
			checklist_data?: ChecklistUpdateMap;
		};
		export type RequestHeaders = {};
		export type ResponseBody = Checklist;
	} /**
 * No description
 * @tags tasks
 * @name BankAccountsBalancesList
 * @summary Get list of bank account balances for all bank accounts
 * @request GET:/admin/bank_accounts/balances
 * @response `200` `{
  pagination?: Pagination,
  result?: (BankAccountBalance)[],

}` success
*/
	export namespace BankAccountsBalancesList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: BankAccountBalance[];
		};
	}
	/**
	 * No description
	 * @name BankAccountsRefreshBalancesCreate
	 * @summary Request that balances for all bank accounts are refreshed
	 * @request POST:/admin/bank_accounts/refresh_balances
	 * @response `200` `void` successful refresh request
	 */
	export namespace BankAccountsRefreshBalancesCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags external_offers
	 * @name ApplicationsExternalOffersDetail
	 * @summary Get external offers
	 * @request GET:/admin/applications/{application_id}/external_offers
	 * @response `200` `(ExternalOffer)[]` external offers list
	 */
	export namespace ApplicationsExternalOffersDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ExternalOffer[];
	}
}

export namespace User {
	/**
	 * No description
	 * @tags account
	 * @name ProfileList
	 * @summary Get the user's profile
	 * @request GET:/user/profile
	 * @response `200` `UserProfile` return user profile
	 */
	export namespace ProfileList {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name ProfilePartialUpdate
	 * @summary Update user's profile
	 * @request PATCH:/user/profile/{profile_id}
	 * @response `200` `UserProfile` update user profile
	 */
	export namespace ProfilePartialUpdate {
		export type RequestParams = {
			profileId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UserProfileUpdate;
		export type RequestHeaders = {};
		export type ResponseBody = UserProfile;
	}
	/**
	 * No description
	 * @tags account
	 * @name UsersCreate
	 * @summary create new user
	 * @request POST:/user/users
	 * @response `200` `void` user created
	 */
	export namespace UsersCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRegisterCreate
	 * @summary register new user
	 * @request POST:/user/account/register
	 * @response `200` `void` account created
	 */
	export namespace AccountRegisterCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountRecoverCreate
	 * @summary reset password for existing user, confirm email
	 * @request POST:/user/account/recover
	 * @response `200` `void` account created
	 */
	export namespace AccountRecoverCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountInviteAcceptCreate
	 * @summary reset password, confirm email, set T&C flags, interface and communication language and region for invited signatory
	 * @request POST:/user/account/invite_accept
	 * @response `200` `void` account created
	 */
	export namespace AccountInviteAcceptCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = InvitedUserRegister;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags account
	 * @name AccountLoginCreate
	 * @summary login user
	 * @request POST:/user/account/login
	 * @response `200` `void` logged in
	 */
	export namespace AccountLoginCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = UserLogin;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsList
	 * @summary get user's applications
	 * @request GET:/user/applications
	 * @response `200` `UserApplicationsResponse` application
	 */
	export namespace ApplicationsList {
		export type RequestParams = {};
		export type RequestQuery = {
			open_filter?: boolean;
			archive_filter?: boolean;
			page?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserApplicationsResponse;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new application
	 * @request POST:/user/applications
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsDetail
	 * @request GET:/user/applications/{application_id}
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsPartialUpdate
	 * @request PATCH:/user/applications/{application_id}
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			confirmAllSignatoriesAdded?: boolean;
			sepaMandatAccepted?: boolean;
			taxIdNumber?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsBankinfoPartialUpdate
	 * @request PATCH:/user/applications/{application_id}/bankinfo
	 * @response `200` `UserApplication` application
	 */
	export namespace ApplicationsBankinfoPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = {
			bankName?: string;
			iban?: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = UserApplication;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsPartialUpdate
	 * @summary Update signatory accept flags
	 * @request PATCH:/user/applications/{application_id}/signatories/{signatory_id}/requirements
	 * @response `200` `SignatoryRequirements` signatory requirements
	 */
	export namespace ApplicationsSignatoriesRequirementsPartialUpdate {
		export type RequestParams = {
			applicationId: string;
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryRequirementsUserRequest;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesKycDetail
	 * @summary Get signatory kyc status
	 * @request GET:/user/signatories/{signatory_id}/kyc
	 * @response `200` `SignatoryKycStatus` signatory kyc status
	 */
	export namespace SignatoriesKycDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryKycStatus;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesRequirementsDetail
	 * @summary get signatories requirements
	 * @request GET:/user/signatories/{signatory_id}/requirements
	 * @response `200` `SignatoryRequirements` signatory requirments for this application
	 */
	export namespace SignatoriesRequirementsDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesRequirementsDetail
	 * @request GET:/user/applications/{application_id}/signatories_requirements
	 * @response `200` `(SignatoryRequirements)[]` all signatories requirments for this application
	 */
	export namespace ApplicationsSignatoriesRequirementsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryRequirements[];
	}
	/**
	 * No description
	 * @tags signatories
	 * @name ApplicationsSignatoriesDetail
	 * @request GET:/user/applications/{application_id}/signatories
	 * @response `200` `SignatoriesResponse` all signatories for this application
	 */
	export namespace ApplicationsSignatoriesDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoriesResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesPartialUpdate
	 * @request PATCH:/user/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` updated signatory object
	 */
	export namespace SignatoriesPartialUpdate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = SignatoryResponse;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDetail
	 * @request GET:/user/signatories/{signatory_id}
	 * @response `200` `SignatoryResponse` return signatory object
	 */
	export namespace SignatoriesDetail {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryResponse;
	}
	/**
	 * No description
	 * @tags signatories
	 * @name SignatoriesDelete
	 * @summary delete specified signatory
	 * @request DELETE:/user/signatories/{signatory_id}
	 * @response `200` `void` 200 ok response with no data
	 */
	export namespace SignatoriesDelete {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsCreate
	 * @summary Create finance object
	 * @request POST:/user/applications/{application_id}/finance_objects
	 * @response `201` `FinanceObjectCreateResponse` created finance object
	 */
	export namespace ApplicationsFinanceObjectsCreate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectCreateResponse;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDetail
	 * @summary Get application finance objects
	 * @request GET:/user/applications/{application_id}/finance_objects
	 * @response `200` `(FinanceObjectGetResponse)[]` list of finance objects
	 */
	export namespace ApplicationsFinanceObjectsDetail {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = FinanceObjectGetResponse[];
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsUpdate
	 * @summary Update finance object
	 * @request PUT:/user/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` updated finance object
	 */
	export namespace ApplicationsFinanceObjectsUpdate {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = FinanceObjectRequest;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags finance object
	 * @name ApplicationsFinanceObjectsDelete
	 * @summary Delete finance object
	 * @request DELETE:/user/applications/{application_id}/finance_objects/{finance_object_id}
	 * @response `200` `void` deleted finance object
	 */
	export namespace ApplicationsFinanceObjectsDelete {
		export type RequestParams = {
			/** @example app_2DsqzVMVKQUhRjCFPY3xT9sgQlm */
			applicationId: string;
			/** @example fo_2DsqxJDdHbYelXMIZv720VGZwB2 */
			financeObjectId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * No description
	 * @tags loans
	 * @name ApplicationsLoanContractDetail
	 * @request GET:/user/applications/{application_id}/loan_contract
	 * @response `200` `LoanContract` loan contract url and filename
	 */
	export namespace ApplicationsLoanContractDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = LoanContract;
	}
	/**
	 * @description require signatory to perform video identification. Only allowed to apply for signatories that accepted invitation
	 * @tags identification
	 * @name SignatoriesRequirementsVideoIdentsCreate
	 * @request POST:/user/signatories_requirements/{signatory_id}/video_idents
	 * @response `200` `SignatoryIdentStatus` signatory ident status
	 */
	export namespace SignatoriesRequirementsVideoIdentsCreate {
		export type RequestParams = {
			signatoryId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = SignatoryIdentStatus;
	} /**
 * No description
 * @tags application
 * @name ApplicationsStatusPartialUpdate
 * @summary Update application status
 * @request PATCH:/user/applications/{application_id}/status
 * @response `200` `Application` ok
 * @response `400` `{
  errors?: ({
  error?: {
  code?: ErrorCode,

},

})[],

}` status change impossible
*/
	export namespace ApplicationsStatusPartialUpdate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UpdateApplicationStatusRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsNextStatusValidateDetail
	 * @summary Query if status can be changed, get all failed requirents for status change
	 * @request GET:/user/applications/{application_id}/next_status_validate
	 * @response `200` `StatusPendingActions` status change is possible if no errors retunred
	 */
	export namespace ApplicationsNextStatusValidateDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = StatusPendingActions;
	}
	/**
	 * @description Resend confirmation email
	 * @tags users
	 * @name AccountResendConfEmailCreate
	 * @request POST:/user/account/resend_conf_email
	 * @response `200` `void` success
	 */
	export namespace AccountResendConfEmailCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
	/**
	 * @description Get list of documents for the application
	 * @tags documents
	 * @name ApplicationsDocumentsDetail
	 * @request GET:/user/applications/{application_id}/documents
	 * @response `200` `DocumentsResponse` Pagination and list of documents for the application
	 */
	export namespace ApplicationsDocumentsDetail {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
			related_entity_id?: string;
			related_entity_kind?: RelatedEntityKind;
			/** comma-separated list of DocumentCategoryName */
			document_category?: DocumentCategoryName | DocumentCategoryName[];
			/** comma-separated list of DocumentTypeName */
			document_type?: DocumentTypeName | DocumentTypeName[];
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentsResponse;
	}
	/**
	 * @description Upload a document for the application
	 * @tags documents
	 * @name ApplicationsDocumentsCreate
	 * @request POST:/user/applications/{application_id}/documents
	 * @response `200` `UploadDocumentResponse` URL to upload the data to S3
	 */
	export namespace ApplicationsDocumentsCreate {
		export type RequestParams = {
			applicationId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadDocumentPayload;
		export type RequestHeaders = {};
		export type ResponseBody = UploadDocumentResponse;
	}
	/**
	 * @description Get download URL for the document
	 * @tags documents
	 * @name ApplicationsDocumentsDownloadUrlDetail
	 * @request GET:/user/applications/{appId}/documents/{docId}/download_url
	 * @response `200` `DocumentUrlResponse` Download URL for the document
	 */
	export namespace ApplicationsDocumentsDownloadUrlDetail {
		export type RequestParams = {
			appId: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = DocumentUrlResponse;
	}
	/**
	 * @description Set successful upload flag after the document has been uploaded
	 * @tags documents
	 * @name ApplicationsDocumentsUploadSuccessPartialUpdate
	 * @request PATCH:/user/applications/{id}/documents/{docId}/upload_success
	 * @response `200` `void` Ok
	 */
	export namespace ApplicationsDocumentsUploadSuccessPartialUpdate {
		export type RequestParams = {
			id: string;
			docId: string;
		};
		export type RequestQuery = {};
		export type RequestBody = UploadSuccessRequestBody;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Applications {
	/**
	 * No description
	 * @tags application
	 * @name ApplicationsCreate
	 * @summary create new user and application
	 * @request POST:/applications/
	 * @response `200` `Application` application
	 */
	export namespace ApplicationsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = ApplicationAndUserCreate;
		export type RequestHeaders = {};
		export type ResponseBody = Application;
	}
}

export namespace Entitlements {
	/**
 * No description
 * @tags entitlements
 * @name EntitlementsList
 * @summary List entitlements
 * @request GET:/entitlements
 * @response `200` `{
  pagination?: Pagination,
  result?: (Entitlement)[],

}` entitlements
*/
	export namespace EntitlementsList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** @min 1 */
			page?: number;
			/** @min 1 */
			page_size?: number;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			pagination?: Pagination;
			result?: Entitlement[];
		};
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsCreate
	 * @summary Create entitlement
	 * @request POST:/entitlements
	 * @response `201` `Entitlement` created entitlement
	 */
	export namespace EntitlementsCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = EntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Entitlement;
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsPartialUpdate
	 * @summary Update entitlement
	 * @request PATCH:/entitlements/{entitlement_id}
	 * @response `200` `Entitlement` updated entitlement
	 */
	export namespace EntitlementsPartialUpdate {
		export type RequestParams = {
			entitlementId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = EntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = Entitlement;
	}
	/**
	 * No description
	 * @tags entitlements
	 * @name EntitlementsDelete
	 * @summary Delete entitlement
	 * @request DELETE:/entitlements/{entitlement_id}
	 * @response `200` `void` deleted entitlement
	 */
	export namespace EntitlementsDelete {
		export type RequestParams = {
			entitlementId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Partners {
	/**
 * No description
 * @tags partner_entitlements
 * @name EntitlementsDetail
 * @summary List partner entitlements
 * @request GET:/partners/{id}/entitlements
 * @response `200` `{
  result?: (PartnerEntitlement)[],

}` entitlements
*/
	export namespace EntitlementsDetail {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			result?: PartnerEntitlement[];
		};
	}
	/**
	 * No description
	 * @tags partner_entitlements
	 * @name EntitlementsCreate
	 * @summary Create partner entitlement
	 * @request POST:/partners/{id}/entitlements
	 * @response `201` `PartnerEntitlement` created partner entitlement
	 */
	export namespace EntitlementsCreate {
		export type RequestParams = {
			id: string;
		};
		export type RequestQuery = {};
		export type RequestBody = PartnerEntitlementRequest;
		export type RequestHeaders = {};
		export type ResponseBody = PartnerEntitlement;
	}
	/**
	 * No description
	 * @tags partner_entitlements
	 * @name EntitlementsDelete
	 * @summary Delete partner entitlement
	 * @request DELETE:/partners/{id}/entitlements/{entitlement_id}
	 * @response `200` `void` deleted partner entitlement
	 */
	export namespace EntitlementsDelete {
		export type RequestParams = {
			id: string;
			entitlementId: number;
		};
		export type RequestQuery = {};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Config {
	/**
	 * @description get public features and partner theme config for FE
	 * @tags config, partner
	 * @name ConfigList
	 * @request GET:/config
	 * @response `200` `ConfigResponse` public features and partner theme config
	 */
	export namespace ConfigList {
		export type RequestParams = {};
		export type RequestQuery = {
			/** `partner_key` column value from `partners` table */
			partner_key?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = ConfigResponse;
	}
}

export namespace SupportRequest {
	/**
	 * @description send support request
	 * @tags support
	 * @name SupportRequestCreate
	 * @request POST:/support_request
	 * @response `200` `void` OK
	 */
	export namespace SupportRequestCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @example 3611234567 */
			phone: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace Contact {
	/**
	 * @description send contact request
	 * @tags support
	 * @name ContactCreate
	 * @request POST:/contact
	 * @response `200` `void` OK
	 */
	export namespace ContactCreate {
		export type RequestParams = {};
		export type RequestQuery = {};
		export type RequestBody = {
			/** @example https://app-dev.teylor.com/loan-info */
			page: string;
			/** @example mrborrower@email.com */
			email: string;
			/** @example Mr. Max Borrower */
			name: string;
			/** @example Hello, my problem is this and that... */
			message: string;
		};
		export type RequestHeaders = {};
		export type ResponseBody = void;
	}
}

export namespace CompanySearch {
	/**
	 * @description Get list of companies matching either the `name` or `country`
	 * @tags company search
	 * @name CompanySearchList
	 * @request GET:/company_search
	 * @response `200` `CompanySearchResponse` List of companies
	 */
	export namespace CompanySearchList {
		export type RequestParams = {};
		export type RequestQuery = {
			name?: string;
			country?: string;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = CompanySearchResponse;
	}
}

export namespace Api {
	/**
 * @description Get application logs list, use old format by default
 * @tags application
 * @name AdminApplicationsLogsDetail
 * @request GET:/api/admin/applications/{appId}/logs
 * @response `200` `{
  logs: ({
  applicationId?: string,
  created?: string,
  data?: string,
  user_id?: string,
  whoCreated?: string,

})[],

}` List of logs
*/
	export namespace AdminApplicationsLogsDetail {
		export type RequestParams = {
			appId: string;
		};
		export type RequestQuery = {
			/** specify to enable return type `AuditLog` items list, otherwise old log will be returned */
			new_format?: boolean;
		};
		export type RequestBody = never;
		export type RequestHeaders = {};
		export type ResponseBody = {
			logs: {
				applicationId?: string;
				created?: string;
				data?: string;
				user_id?: string;
				whoCreated?: string;
			}[];
		};
	}
}

import { Form, Select, SelectProps } from "antd";
import { FormItemProps } from "antd/lib/form/FormItem";

import { CountriesList } from "@teylor-tools/utils/getCountries";

interface Props extends FormItemProps {
	label: string;
	countries: CountriesList[];
	select?: SelectProps;
}

const CountrySelectFormItem = ({ name, countries, select, ...props }: Props) => {
	const formName = name || "country";

	return (
		<Form.Item name={formName} {...props}>
			<Select
				showSearch
				filterOption={(inputValue, options) =>
					String(options?.children)
						.toLowerCase()
						.includes(inputValue.toLowerCase())
				}
				{...select}
			>
				{countries.map(({ code, countryLabel }) => (
					<Select.Option key={code} value={code} data-cy={`country-list-${formName}-option`}>
						{countryLabel}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default CountrySelectFormItem;
